import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Col, Popover, Row, Tag, Tooltip } from 'antd';
import { CensusBadgeDto, PatientTypeDto } from '@medone/medonehp-api-client';

import { renderHtml, renderPatientTypes } from '../../../../shared/common/helpers/renderers';
import { useAppSelector } from '../../../../shared/hooks';
import { selectPatientTypes } from '../admin/slice.patient-types';

import HospitalImg from '../../../../shared/images/hospital.svg';

import SpecialtiesBadges from './specialties-badges';
import { isNullOrEmpty } from '../../../../shared/common/helpers';

type Props = {
    censusBadge: CensusBadgeDto;
    patientTypeIds: string;
    canReturnFromED: boolean;
};

const Badges = ({ censusBadge, patientTypeIds, canReturnFromED }: Props) => {
    const patientTypes = useAppSelector<PatientTypeDto[]>(selectPatientTypes);
    const { patientIntakeId, additionalEmailBadges } = censusBadge || {};
    const typeIds = patientTypeIds || censusBadge?.patientTypeIds;

    // const renderMountCarmelBadge = () => {
    //     return censusBadge?.isMountCarmelPatient && <Tag color="#ababab">Mount Carmel</Tag>;
    // };

    const renderCanReturnFromEd = () => {
        return (
            canReturnFromED && (
                <Tooltip key={`${patientIntakeId}-is-emergency-dept`} title="Can return from ED" destroyTooltipOnHide>
                    <img src={HospitalImg} alt="Can return from ED" style={{ height: '24px' }} />
                </Tooltip>
            )
        );
    };

    const renderOsuJamesBadge = () => {
        return (
            censusBadge.isOsuJamesPatient && (
                <Tag color="#ababab">
                    <Popover
                        overlayClassName="note-preview-popover"
                        content={() => (
                            <div className="note-body">
                                You can refer this OSU James patient to the James ICC clinic, which is open 24/7 for uncontrolled N/V or malignancy-related pain, and to the
                                Infusion Center for urgent blood transfusions for acute anemia. Contact information for both clinics is available in your cell phone directory.
                            </div>
                        )}
                        placement="bottom"
                        title="OSU James"
                    >
                        OSU James
                    </Popover>
                </Tag>
            )
        );
    };

    const renderAbandonedTag = () => {
        if (!censusBadge.abandoned) {
            return null;
        }

        const title = censusBadge.abandonedReason ? `Abandoned: ${censusBadge.abandonedReason}` : 'Abandoned';

        return (
            <Tooltip key={patientIntakeId} title={title} destroyTooltipOnHide>
                <ExclamationCircleTwoTone twoToneColor="#e74c3c" />
            </Tooltip>
        );
    };

    const renderAdditionalBadges = () => {
        return [...(additionalEmailBadges || [])].map((badge, index) => {
            const { displayName, tagColor, shouldRenderOnClient } = badge;

            return (
                shouldRenderOnClient && (
                    <Tag key={index} color={tagColor}>
                        {!isNullOrEmpty(badge.tooltipContent) ? (
                            <Popover
                                overlayClassName="note-preview-popover"
                                content={() => <div className="note-body">{renderHtml(badge.tooltipContent)}</div>}
                                placement="bottom"
                                title={displayName}
                            >
                                {displayName}
                            </Popover>
                        ) : (
                            <>{displayName}</>
                        )}
                    </Tag>
                )
            );
        });
    };

    return (
        <>
            {censusBadge && (
                <Row gutter={0}>
                    <Col span={24} className="badges">
                        <SpecialtiesBadges patientIntakeId={patientIntakeId} showSignOff disableAutoLoad />

                        {renderCanReturnFromEd()}
                        {renderAbandonedTag()}
                        {renderPatientTypes(patientTypes, typeIds)}
                        {renderAdditionalBadges()}
                        {/* {renderMountCarmelBadge()} */}
                        {renderOsuJamesBadge()}
                    </Col>
                </Row>
            )}
        </>
    );
};

export default Badges;
