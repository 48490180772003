import { PayloadAction } from '@reduxjs/toolkit';
import { RegionsClient, RegionDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { AdminState } from './models';
import { adminSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';

export const reducers = {
    setRegions: (state: AdminState, action: PayloadAction<RegionDto[]>) => {
        state.regions = action.payload;
        state.errorMessage = null;
    },

    setRegion: (state: AdminState, action: PayloadAction<RegionDto>) => {
        state.currentRegion = action.payload;
        state.errorMessage = null;
    },

    syncRegion: (state: AdminState, action: PayloadAction<RegionDto>) => {
        let newItems = [...state.regions];
        const index = state.regions.findIndex((x) => x.id === action.payload.id);

        if (index !== -1) {
            newItems = newItems.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload } as RegionDto;
                }

                return item;
            });
        } else {
            newItems.push(action.payload);
        }

        state.regions = newItems;

        if (state.currentRegion != null && state.currentRegion.id === action.payload.id) {
            state.currentRegion = action.payload;
        }
    },
};

export function fetchRegions(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { regions } = getState().admin;

        if (regions != null && regions.length > 0) {
            // Prevent double calls
            return;
        }

        const client = new RegionsClient(null, Axios);

        try {
            const response = await client.getAll();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setRegions(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchRegion(regionId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new RegionsClient(null, Axios);

        try {
            const response = await client.getById(regionId);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setRegion(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function updateRegion(region: RegionDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new RegionsClient(null, Axios);

        try {
            const response = await client.post(region);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setRegion(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function syncRegion(dto: RegionDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(adminSlice.actions.syncRegion(dto));
    };
}

export const selectRegions = (state: RootState) => state.admin.regions;
export const selectRegion = (state: RootState) => state.admin.currentRegion;
export const selectActiveRegions = (state: RootState) => (state.admin.regions ?? []).filter((x) => x.isActive);
