import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Skeleton, Table, Button, Space } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { QuickNoteDto } from '@medone/medonehp-api-client';

import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { fetchQuickNotes, selectQuickNotes } from '../slice.notes';
import { formatDateTime, formatDateTimeLocal } from '../../../../../shared/common/helpers';
import FaxForm from './fax-form';
import { CensusPaths } from '../index.routes';
import QuickNoteSignButtons from '../../components/quicknote-sign-buttons';

type Props = {
    facilityId: number;
    patientId: number;
    patientIntakeId: number;
};

const QuickNoteEyecon = ({ facilityId, patientId, patientIntakeId }: Props) => {
    const dispatch = useAppDispatch();
    const quickNotes = useAppSelector<QuickNoteDto[]>(selectQuickNotes);
    const [processing, setProcessing] = useState<boolean>(false);
    const [patientQuickNoteVisible, setPatientQuickNoteVisible] = useState<boolean>(false);

    const initData = async () => {
        setProcessing(true);

        await dispatch(fetchQuickNotes(patientId));

        setProcessing(false);
    };

    const renderButtons = (value: any, record: QuickNoteDto, ix: number) => {
        return (
            <Space align="center">
                <QuickNoteSignButtons quicknoteDto={record} facilityId={facilityId} patientIntakeId={patientIntakeId} />

                <Link to={`${CensusPaths.QuickNotes.Print}/${record.id}/${facilityId}`} target="_blank" rel="noreferrer">
                    <Button type="primary" className="print-note-btn">
                        Print
                    </Button>
                </Link>

                <FaxForm facilityId={facilityId} quickNoteId={record.id} />
            </Space>
        );
    };

    useEffect(() => {
        if (patientQuickNoteVisible) {
            initData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientQuickNoteVisible]);

    return (
        <>
            <span className="quicknote-container" onClick={() => setPatientQuickNoteVisible(true)}>
                <EyeOutlined />
            </span>

            <Modal title="Quick Notes" open={patientQuickNoteVisible} onCancel={() => setPatientQuickNoteVisible(false)} width={1000} footer={null}>
                <Skeleton active loading={processing}>
                    <Table bordered rowKey="id" dataSource={(quickNotes || []).filter((x) => x.isNotAcknowledged)} loading={quickNotes == null} pagination={false}>
                        <Table.Column<QuickNoteDto>
                            title="Created Date"
                            dataIndex="createdDate"
                            width={50}
                            sorter={(a, b) => a.createdDate.diff(b.createdDate)}
                            render={(value) => formatDateTimeLocal(value, '', 'L LT')}
                        />

                        <Table.Column<QuickNoteDto>
                            title="Date"
                            dataIndex="reminderDate"
                            width={50}
                            sorter={(a, b) => a.reminderDate.diff(b.reminderDate)}
                            render={(value) => formatDateTime(value, '', 'L')}
                        />

                        <Table.Column<QuickNoteDto> title="Note" dataIndex="value" width={250} />

                        <Table.Column<QuickNoteDto>
                            title="Needs Followup"
                            dataIndex="needsFollowUp"
                            className="text-center"
                            width={50}
                            render={(value) => (value ? 'Yes' : 'No')}
                        />

                        <Table.Column<QuickNoteDto>
                            title="Acknowledge"
                            dataIndex="isNotAcknowledged"
                            className="text-center"
                            width={150}
                            render={(v, r, i) => renderButtons(v, r, i)}
                        />
                    </Table>
                </Skeleton>
            </Modal>
        </>
    );
};

export default QuickNoteEyecon;
