import { Card, Row, Col } from 'antd';
import { ScheduleDto } from '@medone/medonehp-api-client';

import { formatDateTime, isMobileView, isTabletView } from '../../../../../shared/common/helpers';
import ActionButtons from '../../census/components/action-buttons';
import SpecialtiesBadges from '../../components/specialties-badges';

type Props = {
    schedule: ScheduleDto;
};

const ScheduleCard = ({ schedule }: Props) => {
    return (
        <Card className="mt-2">
            <h4>{schedule.census.name}</h4>

            <Row>
                <Col span={24}>
                    <strong>DOB:</strong> {formatDateTime(schedule.census.dateOfBirth, '', 'L', schedule.census.age)}
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <strong>Room #:</strong> {schedule.census.roomNumber}
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <SpecialtiesBadges patientIntakeId={schedule.patientIntakeId} showSignOff disableAutoLoad />
                </Col>
            </Row>

            <Row className="mt-2 status-buttons" justify="center">
                <Col>
                    <ActionButtons census={schedule.census} patientIntakeVisits={schedule.patientIntakeVisits} isMobile={isMobileView() || isTabletView()} />
                </Col>
            </Row>
        </Card>
    );
};

export default ScheduleCard;
