import { useEffect } from 'react';
import { Col, Row } from 'antd';
import { ProviderSchedulesDto } from '@medone/medonehp-api-client';

import { useAppSelector } from '../../../../../shared/hooks';
import { formatDateTime } from '../../../../../shared/common/helpers';
import { selectSchedules } from '../slice.schedules';

import ScheduleTable from './schedule-table';
import logo from '../../../../../shared/images/print-logo.jpg';

const SchedulePrint = () => {
    const schedules = useAppSelector<ProviderSchedulesDto[]>(selectSchedules);

    const printSchedule = () => {
        setTimeout(() => {
            window.print();
        }, 100);
    };

    useEffect(() => {
        printSchedule();
    }, []);

    return (
        <div className="p-2 print-schedule-container">
            <img src={logo} alt="Med One Logo" />

            {schedules.map((scheduleGroup) => (
                <div className="region-pagebreak mt-2" key={scheduleGroup.regionId}>
                    <Row align="middle" justify="center">
                        <Col span={8}>
                            <h3>{scheduleGroup.regionName}</h3>
                        </Col>
                        <Col span={8}>
                            <h3>{scheduleGroup.providerName}</h3>
                        </Col>
                        <Col span={8}>
                            <h3>{formatDateTime(scheduleGroup.scheduleDate, '', 'L')}</h3>
                        </Col>
                    </Row>

                    {scheduleGroup.facilities.map((facility) => (
                        <ScheduleTable key={facility.facilityId} facilitySchedule={facility} isPrint />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default SchedulePrint;
