import { PatientIntakeDto, ReturnToHospitalDto } from '@medone/medonehp-api-client';

export interface ReturnToHospitalState {
    eligibleIntakes: PatientIntakeDto[];
    returnToHospital: ReturnToHospitalDto;
}

export const initialState: ReturnToHospitalState = {
    eligibleIntakes: [],
    returnToHospital: null,
};
