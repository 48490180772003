import { ReactElement, KeyboardEvent, useState, useRef } from 'react';
import { SelectProps, Select } from 'antd';
import { EnumSelectListDto } from '@medone/medonehp-api-client';
import { ValueLabelOption } from '../helpers';

interface Props extends SelectProps {
    valueType?: string;
}

export const getCustomSelectListOptions = (
    options: any[],
    labelField: string,
    valueField = 'id',
    includeMeta = false,
    labelFieldCallback?: (obj: any) => void
): ValueLabelOption[] => {
    return (options || [])
        .filter((x) => x != null)
        .map((x) => {
            const label = labelFieldCallback != null ? labelFieldCallback(x) : x[labelField];

            if (includeMeta) {
                return {
                    label,
                    value: x[valueField],
                    name: `${label}_${x[valueField]}`,
                    meta: {
                        ...x,
                    },
                } as ValueLabelOption;
            }

            return {
                label,
                value: x[valueField],
                name: `${label}_${x[valueField]}`,
            } as ValueLabelOption;
        });
};

export const getEnumCustomSelectListOptions = (options: EnumSelectListDto[]): any[] => {
    return (options || [])
        .filter((x) => x != null)
        .map((x) => {
            return {
                label: x.name,
                value: x.id,
                name: `${x.name}_${x.id}`,
            };
        });
};

export const getEnumCustomOptions = (options: string[]): ValueLabelOption[] => {
    return (options || []).map((item) => {
        return {
            value: item,
            label: item,
            name: item,
        } as ValueLabelOption;
    });
};

const CustomSelect = ({ popupClassName, valueType = 'number', ...rest }: Props): ReactElement => {
    const [dropdownSelected, setDropdownSelected] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const customSelectRef = useRef<any>();

    const handleTabHit = (evt: KeyboardEvent) => {
        const key = evt.key;
        const activeResult = document.querySelector('.focused-dropdown .ant-select-item-option-active');

        if (key === 'Tab' && activeResult) {
            const name = activeResult.getAttribute('name');
            const nameSplit = (name ?? '').split('_');

            let value: string | number = nameSplit.length === 1 ? nameSplit[0] : nameSplit[1];

            if (valueType === 'number') {
                const temp = parseInt(value);

                value = isNaN(temp) ? null : temp;
            }

            const option = {
                label: activeResult.getAttribute('label'),
                name,
                value,
            };

            rest.onChange(value, option);
        }
    };

    const patchedProps = {
        ...rest,
        // Force autocomplete to disable, since chrome ignores the "off" value by default
        autoComplete: new Date().getTime().toString(),
    };

    return (
        <Select
            {...patchedProps}
            onFocus={() => {
                setOpen(true);
                setDropdownSelected(true);
            }}
            onBlur={() => {
                setOpen(false);
                setDropdownSelected(false);
            }}
            onInputKeyDown={handleTabHit}
            popupClassName={`${popupClassName} ${dropdownSelected && 'focused-dropdown'}`}
            open={open}
            dropdownMatchSelectWidth={false}
            onChange={(value, option) => {
                rest.onChange(value, option); // Crucial to keep so the original onChange propogates up to the form.item

                customSelectRef.current.blur();

                setDropdownSelected(false);
            }}
            ref={customSelectRef}
        />
    );
};

export default CustomSelect;
