import { NewAdmitFiltersDto, HPNeededItem } from '@medone/medonehp-api-client';

export interface NewAdmitsState {
    errorMessage: string;
    filters: NewAdmitFiltersDto;
    newAdmits: HPNeededItem[];
}

export const initialState = {
    errorMessage: null,
    filters: null,
    newAdmits: [],
};
