import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  PatientClient, PatientDto, PatientIntakeDto, PatientIntakesClient } from '@medone/medonehp-api-client';

import { handleError } from '../../../../shared/common/HandleErrors';
import { Axios } from '../../../../shared/common/http';
import { AppDispatch, AppThunk, RootState } from '../../../../shared/store';
import { fetchPatientNotes } from '../census/slice.patient-notes';
import { initialState, ViewerState } from './models';

export const viewerSlice = createSlice({
    name: 'viewer',
    initialState,
    reducers: {
        setLoading: (state: ViewerState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        setError: (state: ViewerState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.loading = false;
        },

        setPatient: (state: ViewerState, action: PayloadAction<PatientDto>) => {
            state.patient = action.payload;
        },

        setPatientIntake: (state: ViewerState, action: PayloadAction<PatientIntakeDto>) => {
            state.patientIntake = action.payload;
        },
    },
});

export function fetchViewerData(intakeId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        

        dispatch(viewerSlice.actions.setLoading(true));

        const patientClient = new PatientClient(null, Axios);
        const intakeClient = new PatientIntakesClient(null, Axios);

        try {
            const intakeResponse = await intakeClient.getById(intakeId);

            if (intakeResponse.result.succeeded) {
                const patientResponse = await patientClient.getById(intakeResponse.result.entity.patientId);

                if (patientResponse.result.succeeded) {
                    dispatch(viewerSlice.actions.setPatientIntake(intakeResponse.result.entity));
                    dispatch(viewerSlice.actions.setPatient(patientResponse.result.entity));

                    await dispatch(fetchPatientNotes(patientResponse.result.entity.id));
                } else {
                    handleError(intakeResponse, () => dispatch(viewerSlice.actions.setError(null)));
                }
            } else {
                handleError(intakeResponse, () => dispatch(viewerSlice.actions.setError(null)));
            }

            dispatch(viewerSlice.actions.setLoading(false));
        } catch (error) {
            handleError(error, () => dispatch(viewerSlice.actions.setError(error.toString())));
        }
    };
}

export const selectPatient = (state: RootState) => state.viewer.patient;
export const selectPatientIntake = (state: RootState) => state.viewer.patientIntake;
export const selectLoading = (state: RootState) => state.viewer.loading;

export default viewerSlice.reducer;
