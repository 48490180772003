import { selectVisitTypes } from '../../../../../../shared/common/data/slice';
import { useAppSelector } from '../../../../../../shared/hooks';
import { visitDueSelectors } from '../../slice';

type Props = {
    patientIntakeId: number;
};

const VisitTypeBadge = ({ patientIntakeId }: Props) => {
    const visitDue = useAppSelector((state) => visitDueSelectors.selectById(state.census.visitDues, patientIntakeId));
    const visitTypes = useAppSelector(selectVisitTypes);

    const renderVisitType = (value): string => {
        return visitTypes?.find((x) => x.id === value)?.name;
    };

    const renderDetails = () => {
        const visitType = renderVisitType(visitDue.visitType);
        const annualHpSep = visitType != null ? ', ' : '';

        return visitDue.isAnnualHpDue ? (
            <>
                {visitType}
                {annualHpSep} Annual H&P
            </>
        ) : (
            visitType
        );
    };

    return visitDue ? <>{renderDetails()}</> : <></>;
};

export default VisitTypeBadge;
