import {  BedBoardHospitalDto, BedBoardHospitalsClient, BedBoardIntakeDto } from '@medone/medonehp-bedboard-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Axios } from '../../../shared/common/http';
import { AppDispatch, AppThunk, RootState } from '../../../shared/store';

import { initialState, BedBoardIntakeFormState } from './models';

export const bedBoardIntakeFormSlice = createSlice({
    name: 'bedBoardIntakeForm',
    initialState,
    reducers: {
        setError: (state: BedBoardIntakeFormState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        setIntake: (state: BedBoardIntakeFormState, action: PayloadAction<BedBoardIntakeDto>) => {
            state.intake = action.payload;
        },

        setHospital: (state: BedBoardIntakeFormState, action: PayloadAction<BedBoardHospitalDto>) => {
            state.hospital = action.payload;
        },
    },
});

export function fetchHospitalByKey(key: string): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardHospitalsClient( null, Axios);

        try {
            const response = await client.getByKey(key);

            if (response.result.succeeded) {
                dispatch(bedBoardIntakeFormSlice.actions.setHospital(response.result.entity));
            } else {
                dispatch(bedBoardIntakeFormSlice.actions.setHospital(null));
                dispatch(bedBoardIntakeFormSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardIntakeFormSlice.actions.setHospital(null));
            dispatch(bedBoardIntakeFormSlice.actions.setError(error.toString()));
        }
    };
}

export const selectError = (state: RootState) => state.bedBoardIntakeForm.errorMessage;
export const selectBedBoardIntakeFormIntake = (state: RootState) => state.bedBoardIntakeForm.intake;
export const selectHospital = (state: RootState) => state.bedBoardIntakeForm.hospital;

export default bedBoardIntakeFormSlice.reducer;
