import { useEffect, useState } from 'react';
import { PageHeader, Skeleton } from 'antd';
import { CensusFiltersDto } from '@medone/medonehp-api-client';

import { fetchCensus, selectCensusFilters } from '../../slice';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';

import CreatePatientInake from '../patient-intakes/create';
import Filters from '../filters';
import Results from '../results';

const Index = () => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const censusFilters = useAppSelector<CensusFiltersDto>(selectCensusFilters);

    useEffect(() => {
        async function getCensus() {
            setLoading(true);

            await dispatch(fetchCensus(censusFilters));

            setLoading(false);
        }

        if (censusFilters == null) {
            getCensus();
        }
    }, [dispatch, censusFilters]);

    return (
        <>
            <PageHeader title="Census" extra={[<CreatePatientInake key="create-patient-intake" />]} />

            <Filters />

            <Skeleton active loading={loading}>
                <Results />
            </Skeleton>
        </>
    );
};

export default Index;
