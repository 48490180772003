import { useEffect, useState } from 'react';
import { Checkbox, Col, Collapse, Form, Row, Select } from 'antd';
import { CensusFiltersDto, EnumSelectListDto, FacilityDto } from '@medone/medonehp-api-client';

import { selectActiveFacilities } from '../../admin/slice.facilities';
import { selectCensusFilters } from '../slice';
import { filterOptions, filterOptionsStartsWith, getSelectListOptions } from '../../../../../shared/common/helpers';
import { selectRegions } from '../../admin/slice.regions';
import { useAppSelector } from '../../../../../shared/hooks';
import { selectSpecialties } from '../../../../../shared/common/data/slice';

const { Panel } = Collapse;

type Props = {
    handleValuesChange: (changedValues: any, allValues: any) => Promise<void>;
    handleRegionChange: (values: any) => void;
};

const FiltersMobile = ({ handleValuesChange, handleRegionChange }: Props) => {
    const [form] = Form.useForm();
    const censusFilters = useAppSelector<CensusFiltersDto>(selectCensusFilters);
    const facilities = useAppSelector<FacilityDto[]>(selectActiveFacilities);
    const specialties = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const regions = useAppSelector<FacilityDto[]>(selectRegions);
    const [locations, setLocations] = useState<any[]>(getSelectListOptions(facilities, 'name'));

    useEffect(() => {
        setLocations(getSelectListOptions(facilities, 'name'));
    }, [facilities]);

    useEffect(() => {
        if (censusFilters != null && censusFilters.specialties == null) {
            censusFilters.specialties = [];
        }

        form.setFieldsValue(censusFilters);
    }, [censusFilters, form]);

    return (
        <Form form={form} layout="vertical" name="census-filters" initialValues={censusFilters} onValuesChange={handleValuesChange}>
            <div className="show-small">
                <Collapse expandIconPosition="end" className="filter-collapse">
                    <Panel header="Filters" key="1">
                        <Row gutter={48} justify="center" align="middle">
                            <Col span={24}>
                                <div className="ant-form-item-label">
                                    <label>Patient Type</label>
                                </div>

                                <Row>
                                    <Col span={12}>
                                        <Form.Item name="isActive" valuePropName="checked">
                                            <Checkbox>Active</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="isDischarged" valuePropName="checked">
                                            <Checkbox>Discharged</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="Specialties" name="specialties" style={{ marginBottom: '0' }}>
                                            <Select mode="multiple" showSearch filterOption={filterOptionsStartsWith} options={getSelectListOptions(specialties, 'name')} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="isNew" valuePropName="checked">
                                            <Checkbox>New</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Regions" name="regions">
                                    <Select
                                        mode="multiple"
                                        options={getSelectListOptions(regions, 'regionName')}
                                        showSearch
                                        filterOption={filterOptionsStartsWith}
                                        onChange={handleRegionChange}
                                    />
                                </Form.Item>

                                <Form.Item label="Locations" name="locations">
                                    <Select mode="multiple" options={locations} showSearch filterOption={filterOptions} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
            </div>
        </Form>
    );
};

export default FiltersMobile;
