import { memo } from 'react';
import { Col, Collapse, Row, Popover } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { MedicationDisplayStatusType, MedicationStatusType, PccMedicationDto } from '@medone/medonehp-api-client';
import moment, { Moment } from 'moment';
import { startCase } from 'lodash';

import { getEnumValue } from '../../../../../../shared/common/helpers';
import { customExpandIcon } from '../../../../../../shared/common/helpers/collapse';
import { BedBoardMedicationDisplayStatusType, BedBoardPccMedicationDto } from '@medone/medonehp-bedboard-client';

type Props = {
    medications?: PccMedicationDto[] | BedBoardPccMedicationDto[];
    snapshotDate?: Moment;
};

const { Panel } = Collapse;

/**
 * Process and format the start and end dates for administration of a specific medication
 * @param startDate - starting date for administration
 * @param endDate - ending date for administration
 */
const processMedicationDates = (startDate: moment.Moment, endDate: moment.Moment) => {
    if (startDate == null) {
        return null;
    } else {
        let dateString = startDate.utc().format('L');

        if (endDate != null) {
            dateString += ` - ${endDate.utc().format('L')}`;
        }

        return (
            <p className="font-14 mb-0">
                <strong>Medication Date(s):</strong> {dateString}
            </p>
        );
    }
};

const renderDate = (title: string, lastAdminDate: moment.Moment) => {
    return (
        <p className="font-14 mb-0">
            <strong>{title}:</strong> {lastAdminDate && lastAdminDate.isValid() ? lastAdminDate.utc().format('L LT') : 'N/A'}
        </p>
    );
};

const renderString = (title: string, lastAdminAmount: string) => {
    return (
        <p className="font-14 mb-0">
            <strong>{title}:</strong> {lastAdminAmount || 'N/A'}
        </p>
    );
};

const renderPopoverContent = (med: PccMedicationDto | BedBoardPccMedicationDto) => {
    const unableToAdministerYesNo = med.unableToAdminister ? 'Yes' : 'No';

    return (
        <>
            {processMedicationDates(med.startDateTime, med.discontinueDate)}
            {renderDate('Start Date', med.startDateTime)}
            {renderDate('End Date', med.endDateTime)}
            {renderDate('Last Administration Date', med.lastAdministrationDateTime)}
            {renderString('Last Administration Amount', med.lastAdministrationAmount)}
            {renderString('Administration Desc', med.administrationDescription)}
            {renderDate('Discontinue Date', med.discontinueDate)}
            {renderString('Unable to Administer', med.unableToAdminister ? unableToAdministerYesNo : 'N/A')}
            {renderString('Status', startCase(getEnumValue(MedicationStatusType, med.status)))}
        </>
    );
};

const renderPanelTitle = (med: PccMedicationDto | BedBoardPccMedicationDto) => {
    return (
        <Row className="w-100">
            <Col span={24}>
                <Row justify="space-between" className="pl-0">
                    <strong className="mb-0 font-16 title">{med.description}</strong>

                    <Popover content={renderPopoverContent(med)} title="Medication Details" placement="left" trigger={['hover', 'click']}>
                        <InfoCircleTwoTone />
                    </Popover>
                </Row>
            </Col>
        </Row>
    );
};

/**
 * Render a block for each medication
 * @param medications - collection of medications for this patient
 */
const renderMedications = (medications: PccMedicationDto[] | BedBoardPccMedicationDto[]) => {
    return (
        medications &&
        medications.length &&
        medications.map((med: PccMedicationDto | BedBoardPccMedicationDto) => (
            <Collapse.Panel key={med.id} className="medication-record mt-1" header={renderPanelTitle(med)}>
                <p className="mb-0 font-14">{med.directions}</p>
            </Collapse.Panel>
        ))
    );
};

const filterMedsPostAcute = (medications: PccMedicationDto[], status: MedicationDisplayStatusType) => {
    return medications?.filter((x) => x.medicationDisplayStatusType === status);
};

const filterMedsBedBoard = (medications: BedBoardPccMedicationDto[], status: BedBoardMedicationDisplayStatusType) => {
    return medications?.filter((x) => x.medicationDisplayStatusType === status);
};

function isPccMedicationDto(item: Array<PccMedicationDto> | Array<BedBoardPccMedicationDto>): item is Array<PccMedicationDto> {
    return (item as Array<PccMedicationDto>) !== undefined;
}

function isBedBoardPccMedicationDto(item: Array<PccMedicationDto> | Array<BedBoardPccMedicationDto>): item is Array<BedBoardPccMedicationDto> {
    return (item as Array<BedBoardPccMedicationDto>) !== undefined;
}

const MedicationList = ({ medications, snapshotDate }: Props) => {
    let activeMeds = [];
    let inactiveMeds = [];

    if (isPccMedicationDto(medications)) {
        activeMeds = filterMedsPostAcute(medications, MedicationDisplayStatusType.Current);
        inactiveMeds = filterMedsPostAcute(medications, MedicationDisplayStatusType.Recent);
    }

    if (isBedBoardPccMedicationDto(medications)) {
        activeMeds = filterMedsBedBoard(medications, BedBoardMedicationDisplayStatusType.Current);
        inactiveMeds = filterMedsBedBoard(medications, BedBoardMedicationDisplayStatusType.Recent);
    }

    const renderActivePanelHeader = () => {
        if (snapshotDate) {
            const title = `Active - PCC data pulled on ${snapshotDate.format('MM/DD/YYYY')}`;
            return snapshotDate < moment().add(-1, 'M')
                ? <>{title} - <span style={{ color: 'red' }}>Warning: Over 30 days old</span></>
                : title;
        }

        return 'Active';
    };

    return (
        <>
            <Collapse accordion defaultActiveKey="ActiveMedications" expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="ActiveMedications" header={renderActivePanelHeader()}>
                    <div className="p-1">
                        <Collapse className="medication-records-container" collapsible="disabled" activeKey={activeMeds.map((med, idx) => med.id.toString())}>
                            {renderMedications(activeMeds)}
                        </Collapse>
                    </div>
                </Panel>
            </Collapse>

            {inactiveMeds && inactiveMeds.length > 0 && (
                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="InactiveMedications" header="Inactive">
                        <div className="p-1">
                            <Collapse className="medication-records-container" collapsible="disabled" activeKey={inactiveMeds.map((med, idx) => med.id.toString())}>
                                {renderMedications(inactiveMeds)}
                            </Collapse>
                        </div>
                    </Panel>
                </Collapse>
            )}
        </>
    );
};

export default memo(MedicationList);
