import { PayloadAction } from '@reduxjs/toolkit';
import { FacilitiesClient, FacilityDto, ProviderTypes, UsersClient, UsersRegionProviderFiltersDto, UserDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { AdminState } from './models';
import { adminSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { fetchRegions } from './slice.regions';
import { fetchTotalAbandoned } from '../abandoned/slice';

export const reducers = {
    setFacilities: (state: AdminState, action: PayloadAction<FacilityDto[]>) => {
        state.facilities = action.payload;
        state.errorMessage = null;
    },

    setFacility: (state: AdminState, action: PayloadAction<FacilityDto>) => {
        state.currentFacility = action.payload;
        state.errorMessage = null;
    },

    syncFacility: (state: AdminState, action: PayloadAction<FacilityDto>) => {
        let newItems = [...state.facilities];
        const index = state.facilities.findIndex((x) => x.id === action.payload.id);

        if (index !== -1) {
            newItems = newItems.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload } as FacilityDto;
                }

                return item;
            });
        } else {
            newItems.push(action.payload);
        }

        state.facilities = newItems;

        if (state.currentFacility != null && state.currentFacility.id === action.payload.id) {
            state.currentFacility = action.payload;
        }
    },

    setUsers: (state: AdminState, action: PayloadAction<UserDto[]>) => {
        state.users = action.payload;
    },

    setPhysicianUsers: (state: AdminState, action: PayloadAction<UserDto[]>) => {
        state.physicianUsers = action.payload;
    },

    setAppUsers: (state: AdminState, action: PayloadAction<UserDto[]>) => {
        state.appUsers = action.payload;
    },

    setWoundProviders: (state: AdminState, action: PayloadAction<UserDto[]>) => {
        state.woundProviders = action.payload;
    },
};

export function fetchFacilities(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new FacilitiesClient(null, Axios);

        try {
            const response = await client.getAll();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setFacilities(response.result.entity));

                // if (!tokenOverride) {
                //     dispatch(adminSlice.actions.setFacility(null));
                // }
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchUsersByRegion(regionId: number, providerTypes?: ProviderTypes[]): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new UsersClient(null, Axios);

        try {
            const data = UsersRegionProviderFiltersDto.fromJS({ regionId, providerTypes });
            const response = regionId != null ? await client.getAllByRegion(data) : await client.getAll();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setUsers(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchUsersForFacilityMaintenance(regionId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new UsersClient(null, Axios);

        try {
            const physicianData = UsersRegionProviderFiltersDto.fromJS({ regionId, providerTypes: [ProviderTypes.Physician] });
            const appData = UsersRegionProviderFiltersDto.fromJS({ regionId, providerTypes: [ProviderTypes.AdvancedPracticePractitioner] });
            const physicianResponse = await client.getAllByRegion(physicianData);
            const appResponse = await client.getAllByRegion(appData);

            if (physicianResponse.result.succeeded) {
                dispatch(adminSlice.actions.setPhysicianUsers(physicianResponse.result.entity));
            } else {
                handleError(physicianResponse, () => dispatch(adminSlice.actions.setError(null)));
            }

            if (appResponse.result.succeeded) {
                dispatch(adminSlice.actions.setAppUsers(appResponse.result.entity));
            } else {
                handleError(appResponse, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchFacility(facilityId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new FacilitiesClient(null, Axios);

        try {
            const response = await client.getById(facilityId);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setFacility(response.result.entity));

                await dispatch(fetchRegions());
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function updateFacility(facility: FacilityDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new FacilitiesClient(null, Axios);

        try {
            const response = await client.post(facility);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setFacility(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function syncFacility(dto: FacilityDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        await dispatch(fetchFacilities());
        await dispatch(fetchTotalAbandoned());

        dispatch(adminSlice.actions.syncFacility(dto));
    };
}

export function fetchWoundProviders(regionId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new UsersClient(null, Axios);

        try {
            const response = await client.getAllWoundProviders(regionId);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setWoundProviders(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export const selectFacilities = (state: RootState) => state.admin.facilities;
export const selectFacility = (state: RootState) => state.admin.currentFacility;
export const selectActiveFacilities = (state: RootState) => (state.admin.facilities ?? []).filter((x) => x.isActive);
export const selectUsers = (state: RootState) => state.admin.users;
export const selectPhysicianUsers = (state: RootState) => state.admin.physicianUsers;
export const selectAppUsers = (state: RootState) => state.admin.appUsers;
export const selectWoundProviders = (state: RootState) => state.admin.woundProviders;
