import React from 'react';
import { Table } from 'antd';
import { PccAdvancedDirectiveDto } from '@medone/medonehp-api-client';

const { Column } = Table;

type Props = {
    advancedDirectives: PccAdvancedDirectiveDto[];
};

const AdvancedDirectivesTable = ({ advancedDirectives }: Props) => {
    return (
        <Table rowKey={(x) => x.description} pagination={false} dataSource={advancedDirectives}>
            <Column<PccAdvancedDirectiveDto> title="Description" dataIndex="description" key="description" />
            <Column<PccAdvancedDirectiveDto> title="Consent Status" dataIndex="consentStatus" key="consentStatus" />
        </Table>
    );
};

export default AdvancedDirectivesTable;
