import { useState } from 'react';
import { Radio, Button } from 'antd';
import { CensusDto, NoteTypes, UnsignedNoteCensusDto, VisitDto } from '@medone/medonehp-api-client';
import { AccountInfo } from '@azure/msal-browser';

import { postAdHocVisit } from '../../schedule/slice.schedules';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { isInRole, noteShortTypes, shouldShowVisitButton } from '../../../../../shared/common/helpers';
import { selectAccount, selectPermissions } from '../../../../../shared/common/auth/slice';
import { selectCensusItemVisit, selectVisitButtons, visitDueSelectors } from '../../census/slice';
import { RootState } from '../../../../../shared/store';
import { AnyProvider, Role } from '../../../../../shared/common/auth/RoleAuth';
import { setCurrentNoteType, toggleDischargeModal } from '../../census/slice.notes';
import { VisitButtonDict } from '../../census/models';
import { selectCurrentUnsignedNote, setCurrentUnsignedNoteType } from '../../unsigned-notes/slice';

import SkippedForm from './skipped-form';

type Props = {
    census?: CensusDto;
    isMobile?: boolean;
    setLoading?: (loading: boolean) => void;
    loading?: boolean;
};

const StatusButtons = ({ census, isMobile = false, setLoading, loading }: Props) => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector<AccountInfo>(selectAccount);
    const userRoles = useAppSelector<string[]>(selectPermissions);
    const visitDue = useAppSelector((state) => visitDueSelectors.selectById(state.census.visitDues, census?.patientIntakeId));
    const censusVisit = useAppSelector<VisitDto>((state: RootState) => selectCensusItemVisit(state, census));
    const censusVisitButton = useAppSelector<VisitButtonDict>((state: RootState) => selectVisitButtons(state, census));
    const currentUnsignedNote = useAppSelector<UnsignedNoteCensusDto>(selectCurrentUnsignedNote);
    const [showSkipReason, setShowSkipReason] = useState<boolean>(false);
    const isVisitSkipped = censusVisit?.isSkipped != null && censusVisit?.isSkipped === true;

    const handleSkipped = async () => {
        setLoading(true);

        const isSkipped = currentUnsignedNote != null ? !currentUnsignedNote.visit?.isSkipped : !censusVisit?.isSkipped;

        if (isSkipped) {
            setShowSkipReason(true);
        } else {
            const patchedValues = {
                id: censusVisit?.id,
                facilityId: census?.admittedToId,
                facilityType: census?.facilityType,
                patientIntakeId: census?.patientIntakeId,
                providerId: auth?.localAccountId,
                noteType: null,
                skippedReason: null,
                isSkipped: null,
            } as VisitDto;

            await dispatch(postAdHocVisit(patchedValues));
        }

        setLoading(false);
    };

    const handleVisitTypeChange = async (evt) => {
        setLoading(true);

        const { value } = evt.target;
        const type = noteShortTypes.find((x) => parseInt(value) === x.value);

        if (type.value === NoteTypes.DischargeSummary) {
            dispatch(setCurrentNoteType(NoteTypes.DischargeSummary));

            if (currentUnsignedNote != null) {
                await dispatch(toggleDischargeModal(true, currentUnsignedNote.visit, census));
            } else {
                await dispatch(toggleDischargeModal(true, censusVisit, census));
            }
        } else {
            const patchedValues = {
                id: censusVisit?.id,
                facilityId: census?.admittedToId,
                facilityType: census?.facilityType,
                patientIntakeId: census?.patientIntakeId,
                providerId: auth?.localAccountId,
                noteType: type.value,
            } as VisitDto;

            await dispatch(postAdHocVisit(patchedValues));

            dispatch(setCurrentNoteType(patchedValues.noteType));

            if (currentUnsignedNote != null) {
                dispatch(setCurrentUnsignedNoteType(patchedValues.noteType));
            }
        }

        setLoading(false);
    };

    const mobileClass = isMobile ? 'mobile' : '';
    const selectedNoteType =
        currentUnsignedNote != null ? noteShortTypes.find((x) => x.value === currentUnsignedNote.visit?.noteType) : noteShortTypes.find((x) => x.value === censusVisit?.noteType);

    return (
        isInRole(userRoles, [...AnyProvider, Role.POST_ACUTE_ADMIN]) && (
            <>
                <Button
                    type="default"
                    onClick={handleSkipped}
                    className={isVisitSkipped ? `skipped-button complete ${mobileClass}` : `skipped-button ${mobileClass}`}
                    disabled={loading}
                >
                    Skipped
                </Button>

                <Radio.Group className={`visit-types-menu${mobileClass}`}>
                    {noteShortTypes
                        .filter((type) => shouldShowVisitButton(type, censusVisitButton, visitDue))
                        .map((type) => {
                            const styling = selectedNoteType?.value === type.value ? 'complete' : '';

                            return (
                                <Radio.Button
                                    key={type.value}
                                    value={type.value}
                                    onClick={handleVisitTypeChange}
                                    className={selectedNoteType != null ? styling : ''}
                                    disabled={loading}
                                >
                                    {type.name}
                                </Radio.Button>
                            );
                        })}
                </Radio.Group>

                <SkippedForm
                    census={census}
                    visit={censusVisit}
                    providerId={auth?.localAccountId}
                    showSkipReason={showSkipReason}
                    onCancel={() => setShowSkipReason(false)}
                    onFinish={() => setShowSkipReason(false)}
                />
            </>
        )
    );
};

export default StatusButtons;
