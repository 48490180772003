import { HubConnection } from '@microsoft/signalr';
import { PatientIntakeDto, ScheduledPatientIntakeStatsDto, ScheduleDto } from '@medone/medonehp-api-client';

import { store } from '../../../../../shared/store';
import { PostAcutePaths } from '../../index.routes';
import { scheduleSlice } from '../slice';
import { fetchScheduleStats, getScheduleForProvider } from '../slice.schedules';
import { updateCensusGroupToRefresh } from '../../census/hubs';

const handleScheduleRefresh = async (data: PatientIntakeDto | ScheduleDto) => {
    // // Only reload if the user is currently on the census page
    // if (window.location.pathname === CensusPaths.Home.Index) {
    //     await store.dispatch(refreshCensus(patientIntakeId));
    // }

    const { localAccountId } = store.getState().auth?.account;

    // Only reload if the user is currently on the schedule page
    if (window.location.pathname === PostAcutePaths.Schedule.Index) {
        if (localAccountId) {
            await store.dispatch(getScheduleForProvider(localAccountId));
        }
    }

    if (localAccountId) {
        await store.dispatch(fetchScheduleStats(localAccountId));
    }

    await updateCensusGroupToRefresh(data);
};

export const initScheduleHubConnection = (connection: HubConnection) => {
    connection.on('SchedulePublished', async (dto: ScheduleDto) => {
        await handleScheduleRefresh(dto);
    });

    connection.on('SendScheduledPatientIntakeStats', (dto: ScheduledPatientIntakeStatsDto) => {
        const { localAccountId } = store.getState().auth?.account;

        if (localAccountId === dto.providerId) {
            store.dispatch(scheduleSlice.actions.setScheduleStats(dto));
        }
    });

    connection.on('PatientIntakeUpdated', async (dto: PatientIntakeDto) => {
        await handleScheduleRefresh(dto);
    });
};
