import { Switch } from 'react-router';
import { Alert } from 'antd';

import { selectError } from './slice';
import { useAppSelector } from '../../../../shared/hooks';
import { Role } from '../../../../shared/common/auth/RoleAuth';

import AuthorizedRoute from '../../../../shared/common/auth/AuthorizedRoute';

import Home from './components/results';
import SchedulePrint from './components/schedule-print';

export const SchedulePaths = {
    Home: {
        Index: '/postacute/schedule',
        Print: '/postacute/schedule/print',
    },
};

const Routes = () => {
    const errorMessage = useAppSelector<string>(selectError);

    return (
        <>
            <Switch>
                <AuthorizedRoute
                    exact
                    path={SchedulePaths.Home.Index}
                    component={Home}
                    roles={[Role.POST_ACUTE_ADMIN, Role.POST_ACUTE_PHYSICIAN, Role.POST_ACUTE_ADVANCED_PRACTICE_PRACTITIONER]}
                />

                <AuthorizedRoute
                    exact
                    path={SchedulePaths.Home.Print}
                    component={SchedulePrint}
                    roles={[Role.POST_ACUTE_ADMIN, Role.POST_ACUTE_PHYSICIAN, Role.POST_ACUTE_ADVANCED_PRACTICE_PRACTITIONER]}
                />
            </Switch>

            {errorMessage != null && errorMessage !== '' && <Alert message={null} description={errorMessage} type="error" showIcon />}
        </>
    );
};

export default Routes;
