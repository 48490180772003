import { Select } from 'antd';
import { EnumSelectListDto, RouteBuilderGroupDto } from '@medone/medonehp-api-client';

import { selectVisitTypes } from '../../../../../shared/common/data/slice';
import { filterOptionsStartsWith, getEnumSelectListOptions, getSelectListOptions } from '../../../../../shared/common/helpers';
import { useAppSelector } from '../../../../../shared/hooks';

const searchStyle = { minWidth: '150px', maxWidth: '395px' };

type Props = {
    lsLocationsFilteredKey: string;
    lsVisitTypesFilteredKey: string;
    groups: RouteBuilderGroupDto[];
    filteredLocations: number[];
    filteredVisitTypes: number[];
    setFilteredLocations: (value) => void;
    setFilteredVisitTypes: (value) => void;
};

const VisitsFilters = ({ lsLocationsFilteredKey, lsVisitTypesFilteredKey, groups, filteredLocations, filteredVisitTypes, setFilteredLocations, setFilteredVisitTypes }: Props) => {
    const visitTypes = useAppSelector<EnumSelectListDto[]>(selectVisitTypes);

    const handleFilterLocations = (values) => {
        setFilteredLocations(values);

        localStorage.setItem(lsLocationsFilteredKey, values);
    };

    const handleFilterVisitTypes = (values) => {
        setFilteredVisitTypes(values);

        localStorage.setItem(lsVisitTypesFilteredKey, values);
    };

    return (
        <>
            <Select
                allowClear
                showSearch
                className="ml-3"
                mode="multiple"
                filterOption={filterOptionsStartsWith}
                placeholder="Filter Locations..."
                dropdownMatchSelectWidth={false}
                options={getSelectListOptions(groups, 'admittedTo', 'admittedToId')}
                style={searchStyle}
                onChange={handleFilterLocations}
                defaultValue={filteredLocations}
            />

            <Select
                allowClear
                showSearch
                className="ml-2"
                mode="multiple"
                filterOption={filterOptionsStartsWith}
                placeholder="Filter Visit Types..."
                dropdownMatchSelectWidth={false}
                options={getEnumSelectListOptions(visitTypes)}
                style={searchStyle}
                onChange={handleFilterVisitTypes}
                defaultValue={filteredVisitTypes}
            />
        </>
    );
};

export default VisitsFilters;
