import {
    EnumSelectListDto,
    NoteTypes,
    UserDto,
    MedicalSummaryDto,
    MedicalSummaryLockStatusDto,
    PatientIntakeDto,
    PatientDto,
    ProblemListItemDto,
    AuthorizedApiBase,
    PccVitalDto,
    PccPatientDataDto,
    DocumentTypes,
    AdmissionDocGroupDto,
    RequiredVisitResultDto,
    NoteDto,
} from '@medone/medonehp-api-client';

import { BedBoardAuthorizedApiBase, BedBoardIntakeDto, BedBoardNoteTypes } from '@medone/medonehp-bedboard-client';

import { CheckboxOptionType } from 'antd';
import moment, { Moment } from 'moment';
import { camelCase } from 'lodash';
import { SyntheticEvent } from 'react';
import { NamePath } from 'antd/lib/form/interface';

import { ApiPaths, BuildInfo } from '../../../Config';
import { VisitButtonDict } from '../../../post-acute/features/post-acute/census/models';

export type EnumValuesReturnType = {
    value: number;
    title: string;
};

export type ValueLabelOption = {
    value: string | number;
    label: string;
    meta?: any;
    disabled?: boolean;
};

export type ConvertFilesToBlobsProps = {
    data: Blob;
    fileName: string;
};

export type NoteTypeNameValue = {
    name: string;
    value: number;
};

export const noteTypes = [
    { name: 'History and Physical', value: NoteTypes.HistoryAndPhysical },
    { name: 'Progress Note', value: NoteTypes.ProgressNote },
    { name: 'Discharge Summary', value: NoteTypes.DischargeSummary },
    { name: 'Wound Care', value: NoteTypes.WoundCare },
    { name: 'Annual History and Physical', value: NoteTypes.AnnualHistoryAndPhysical },
    { name: 'Initial PM&R', value: NoteTypes.InitialPMR },
    { name: 'Follow-Up PM&R', value: NoteTypes.FollowUpPMR },
    { name: 'Annual Wellness Visit', value: NoteTypes.AnnualWellnessVisit },
] as NoteTypeNameValue[];

export const noteShortTypes = [
    { name: 'H&P', value: NoteTypes.HistoryAndPhysical },
    { name: 'Progress Note', value: NoteTypes.ProgressNote },
    { name: 'Discharge', value: NoteTypes.DischargeSummary },
    { name: 'Wound', value: NoteTypes.WoundCare },
    { name: 'Annual H&P', value: NoteTypes.AnnualHistoryAndPhysical },
    { name: 'Initial PM&R', value: NoteTypes.InitialPMR },
    { name: 'Follow-Up PM&R', value: NoteTypes.FollowUpPMR },
    { name: 'AWV', value: NoteTypes.AnnualWellnessVisit },
] as NoteTypeNameValue[];

const portalApiBase = new AuthorizedApiBase();
const bedBoardApiBase = new BedBoardAuthorizedApiBase();

export const getEnvBaseUrl = () => {
    if (BuildInfo.IsBedBoard) {
        return bedBoardApiBase.getBaseUrl('');
    }

    return portalApiBase.getBaseUrl('');
};

export const getVersionEndpoint = () => {
    const baseUrl = getEnvBaseUrl();

    if (ApiPaths.Url.includes('localhost')) {
        return `${ApiPaths.Url}/version?code=D1YDSYXyxgNu6Buy8DNSxrRPNEvCLHCUa62JV8fDszKkunENbdyJqtc`;
    }

    if (BuildInfo.IsBedBoard) {
        if (baseUrl.includes('-dev')) {
            return 'https://bedboard-api-dev.medonehp.com/version?code=4Q23b1dqHFAZApfDUgKyrxLQvWSegQHNSSJrv3iz9GUFPXomY9rwZiK';
        }

        if (baseUrl.includes('-demo')) {
            return 'https://bedboard-api-demo.medonehp.com/version?code=DJRKtk3a55LMuhm2pJ8sMFrkxEbTM47YL2F4Jf3JpbjZvdEdWCiTGwu';
        }

        if (baseUrl.includes('-beta')) {
            return 'https://bedboard-api-beta.medonehp.com/version?code=6g4iXNP4VdoqHqz2VW5bs8yF91VaQLYzkFrdN4h2wGvAz94pt2uUQiw';
        }

        return 'https://bedboard-api.medonehp.com/version?code=6g4iXNP4VdoqHqz2VW5bs8yF91VaQLYzkFrdN4h2wGvAz94pt2uUQiw';
    }

    if (baseUrl.includes('-dev')) {
        return 'https://api-dev.medonehp.com/version?code=4Q23b1dqHFAZApfDUgKyrxLQvWSegQHNSSJrv3iz9GUFPXomY9rwZiK';
    }

    if (baseUrl.includes('-demo')) {
        return 'https://api-demo.medonehp.com/version?code=DJRKtk3a55LMuhm2pJ8sMFrkxEbTM47YL2F4Jf3JpbjZvdEdWCiTGwu';
    }

    if (baseUrl.includes('-cert')) {
        return 'https://api-cert.medonehp.com/version?code=D1YDSYXyxgNu6Buy8DNSxrRPNEvCLHCUa62JV8fDszKkunENbdyJqt';
    }

    if (baseUrl.includes('-beta')) {
        return 'https://api-beta.medonehp.com/version?code=6g4iXNP4VdoqHqz2VW5bs8yF91VaQLYzkFrdN4h2wGvAz94pt2uUQiw';
    }

    return 'https://api.medonehp.com/version?code=6g4iXNP4VdoqHqz2VW5bs8yF91VaQLYzkFrdN4h2wGvAz94pt2uUQiw';
};

export const getBaseUrl = (forPostAcute = false, forBedBoard = false) => {
    if (!forPostAcute && (BuildInfo.IsBedBoard || forBedBoard)) {
        if (window.location.hostname.includes('localhost')) {
            return 'https://localhost:6002';
        }

        if (window.location.hostname.includes('-dev') || window.location.hostname.includes('-cert')) {
            return 'https://bedboard-portal-dev.medonehp.com';
        }

        if (window.location.hostname.includes('-demo')) {
            return 'https://bedboard-portal-demo.medonehp.com';
        }

        return 'https://bedboard-portal.medonehp.com';
    }

    if (window.location.hostname.includes('localhost')) {
        return 'https://localhost:6001';
    }

    if (window.location.hostname.includes('-dev')) {
        return 'https://portal-dev.medonehp.com';
    }

    if (window.location.hostname.includes('-demo')) {
        return 'https://portal-demo.medonehp.com';
    }

    if (window.location.hostname.includes('-cert')) {
        return 'https://portal-cert.medonehp.com';
    }

    if (window.location.hostname.includes('-beta')) {
        return 'https://portal-beta.medonehp.com';
    }

    return 'https://portal.medonehp.com';
};

export const isProduction = () => {
    const url = getVersionEndpoint();

    if (BuildInfo.IsBedBoard) {
        return url.includes('https://bedboard-api.medonehp.com');
    }

    return url.includes('https://api.medonehp.com');
};

export const isBeta = () => {
    const url = getVersionEndpoint();

    return url.includes('https://api-beta.medonehp.com');
};

export const getEnumOptions = (options: string[]) => {
    return (options || []).map((item) => {
        return {
            value: item,
            label: item,
        };
    });
};

export const getEnumValue = (enumType: any, value: number): string => {
    return Object.entries(enumType)
        .map((item) => {
            if (parseInt(item[1] as string) >= 0) {
                if (item[1] === value) {
                    return item[0];
                }
            }

            return null;
        })
        .filter((x) => x != null)[0];
};

export const getEnumValues = (enumType: any): EnumValuesReturnType[] => {
    return Object.entries(enumType)
        .map((item) => {
            if (parseInt(item[1] as string) >= 0) {
                return {
                    title: item[0],
                    value: item[1],
                } as EnumValuesReturnType;
            }

            return null;
        })
        .filter((x) => x != null);
};

export const getEnumSelectListOptions = (options: EnumSelectListDto[]): ValueLabelOption[] => {
    return (options || [])
        .filter((x) => x != null)
        .map((x) => {
            return {
                label: x.name,
                value: x.id,
            } as ValueLabelOption;
        });
};

export const getSelectListOptions = (
    options: any[],
    labelField: string,
    valueField = 'id',
    includeMeta = false,
    labelFieldCallback?: (obj: any) => void,
    disabledSelector?: (obj: any, arr: any[]) => boolean
): ValueLabelOption[] => {
    return (options || [])
        .filter((x) => x != null)
        .map((x, ix, arr) => {
            const opt = {
                label: labelFieldCallback != null ? labelFieldCallback(x) : x[labelField],
                value: x[valueField],
            } as ValueLabelOption;

            if (includeMeta) {
                opt.meta = { ...x };
            }

            if (disabledSelector != null) {
                opt.disabled = disabledSelector(x, arr);
            }

            return opt;
        });
};

export const getCheckboxOptionTypes = (
    options: any[],
    labelField: string,
    style?: React.CSSProperties,
    valueField = 'id',
    includeMeta = false,
    labelFieldCallback?: (obj: any) => void
): CheckboxOptionType[] => {
    return (options || [])
        .filter((x) => x != null)
        .map((x) => {
            if (includeMeta) {
                return {
                    label: labelFieldCallback != null ? labelFieldCallback(x) : x[labelField],
                    value: x[valueField],
                    style: style,
                    meta: {
                        ...x,
                    },
                } as CheckboxOptionType;
            }

            return {
                label: labelFieldCallback != null ? labelFieldCallback(x) : x[labelField],
                value: x[valueField],
                style: style,
            } as CheckboxOptionType;
        });
};

export const getEnumDisplayValue = (options: EnumSelectListDto[], value: number) => {
    return (options || []).find((x) => x.id === value)?.name ?? '';
};

export const filterOptions = (input: string, option: any): boolean => {
    return (option.label as string)?.toLowerCase().indexOf((input ?? '')?.toLowerCase()) >= 0;
};

export const filterOptionsStartsWith = (input: string, option: any): boolean => {
    return (option.label as string)?.toLowerCase().startsWith((input ?? '')?.toLowerCase());
};

export const convertFileToBlobs = (file): Promise<ConvertFilesToBlobsProps> => {
    return new Promise<ConvertFilesToBlobsProps>((resolve, reject) => {
        if (file != null) {
            const reader = new FileReader();

            reader.onload = function (e) {
                const blob = new Blob([new Uint8Array(e.target.result as ArrayBuffer)], { type: file.type });

                resolve({
                    data: blob,
                    fileName: file.name,
                });
            };

            reader.readAsArrayBuffer(file);
        } else {
            resolve(null);
        }
    });
};

export const convertFilesToBlobs = (files): Promise<ConvertFilesToBlobsProps[]> => {
    return new Promise<ConvertFilesToBlobsProps[]>((resolve, reject) => {
        const results = [];

        if (files != null) {
            files.forEach((file) => {
                results.push(convertFileToBlobs(file));
            });
        }

        resolve(results);
    });
};

export const isNullOrEmpty = (value: any) => {
    return value == null || value === '';
};

/**
 * Evaluate an attribute we are trying to print.
 * If the value is null or NaN, return a replacement value for missing data.
 * @param value
 * @param replacement
 */
export const valueOrDefault = (value: any, replacement = 'N/A') => {
    return isNullOrEmpty(value) ? replacement : value;
};

const minDate = moment('0001-01-01T00:00:00');

export const formatDateTime = (date: moment.Moment, defaultValue = '', overrideFormat = 'L LT', extra = null) => {
    if (date != null && date.isValid() && date.utc().isAfter(minDate)) {
        const newDate = date.hour() === 0 && date.minute() === 0 ? date.format('L') : date.format(overrideFormat);
        const extraData = extra != null ? ` (${extra})` : '';

        return newDate + extraData;
    }

    return defaultValue;
};

export const formatDateTimeLocal = (date: moment.Moment, defaultValue = '', overrideFormat = 'L LT') => {
    if (date != null && date.isValid() && date.utc().isAfter(minDate)) {
        return date.hour() === 0 && date.minute() === 0 ? date.local().format('L') : date.local().format(overrideFormat);
    }

    return defaultValue;
};

export const daysTill = (to: Moment) => {
    const today = moment();
    return moment.duration(to.diff(today)).asDays();
};

export const daysFrom = (to: Moment) => {
    const today = moment();
    return moment.duration(today.diff(to)).asDays();
};

export const daysElapsed = (from: Moment, to: Moment) => {
    return moment.duration(to.diff(from)).asDays();
};

export const formatPccGender = (value: number) => {
    if (value === 0) {
        return 'Male';
    }

    if (value === 1) {
        return 'Female';
    }

    return 'Unknown';
};

export const isMobileView = () => {
    return window.innerWidth <= 844;
};

export const isTabletView = () => {
    return window.innerWidth > 844 && window.innerWidth < 1025;
};

export const getCollapsedDefault = (collapsedKey: string, defaultValue: boolean = false): boolean => {
    const value = localStorage.getItem(collapsedKey);

    // Default to true for mobile
    if (isMobileView() && value == null) {
        return true;
    }

    if (value == null) return defaultValue;
    else return value === 'true';
};

export const getNoteTypeName = (type?: NoteTypes | BedBoardNoteTypes): string => {
    return type ? noteTypes.find((x) => type === x.value)?.name : 'Invalid Note Type';
};

export const getNoteTypeShortName = (type?: NoteTypes | BedBoardNoteTypes): string => {
    return type ? noteShortTypes.find((x) => type === x.value)?.name : 'Invalid Note Type';
};

export const toCamel = (obj) => {
    const newObj = {};

    Object.keys(obj).forEach((key) => (newObj[camelCase(key)] = obj[key]));

    return newObj;
};

export const getProviderFullName = (provider: UserDto, userSuffixes?: EnumSelectListDto[]) => {
    if (userSuffixes != null) {
        const suffix = userSuffixes.find((x) => x.id === provider.suffix);
        if (suffix != null) {
            return `${provider.firstName} ${provider.lastName}, ${suffix.name}`;
        }
    }

    return `${provider.firstName} ${provider.lastName}`;
};

export const isInRole = (userRoles: string[], roles: string[]) => (roles || []).some((r) => (userRoles || []).includes(r));

export const getMedicalSummaryFields = (): string[] => {
    const empty = MedicalSummaryDto.fromJS({});

    return Object.keys(empty).filter((x) => x !== 'patientIntakeId' && x !== 'rowVersion');
};

export const allMedicalSummaryFields = getMedicalSummaryFields();

export const isMedicalSummaryField = (field: string): boolean => {
    return allMedicalSummaryFields.includes(field);
};

export const isFieldLocked = (isLocked: boolean, field: string): boolean => {
    return isLocked && isMedicalSummaryField(field);
};

export const getCleanMedicalSummaryFields = (data: any): MedicalSummaryDto => {
    const value = MedicalSummaryDto.fromJS(data);

    delete value.lastEditedByUserId;
    delete value.lastEditedByUserName;

    return value;
};

type LockExpirationDataProps = {
    expired: boolean;
    refreshAtMs: number;
};

export const getLockExpirationData = (dto: MedicalSummaryLockStatusDto): LockExpirationDataProps => {
    const current = moment().utc();
    const expirationDateTime = moment(dto.lockExpirationDateTime).utc();
    const expired = !expirationDateTime.isValid() || current.isAfter(expirationDateTime);
    const refreshAtMs = expirationDateTime.diff(current);

    return {
        expired,
        refreshAtMs,
    };
};

export const renderUserFullName = (user: UserDto, lastNameFirst = false): string => {
    const firstName = user.preferredName ? `${user.firstName} "${user.preferredName}"` : user.firstName;

    return lastNameFirst ? `${user.lastName}, ${firstName}` : `${firstName} ${user.lastName}`;
};

export const renderPatientFullName = (patient: PatientDto, lastNameFirst = false): string => {
    const firstName = patient.preferredName ? `${patient.firstName} "${patient.preferredName}"` : patient.firstName;

    return lastNameFirst ? `${patient.lastName}, ${firstName}` : `${firstName} ${patient.lastName}`;
};

export const renderPatientIntakeFullName = (patientIntake: PatientIntakeDto, lastNameFirst = false): string => {
    if (!patientIntake) {
        return '';
    }

    const firstName = patientIntake.preferredName ? `${patientIntake.firstName} "${patientIntake.preferredName}"` : patientIntake.firstName;

    return lastNameFirst ? `${patientIntake.lastName}, ${firstName}` : `${firstName} ${patientIntake.lastName}`;
};

export const renderBedBoardIntakeFullName = (bedBoardIntake: BedBoardIntakeDto, lastNameFirst = false): string => {
    if (!bedBoardIntake) {
        return '';
    }

    return lastNameFirst ? `${bedBoardIntake.lastName}, ${bedBoardIntake.firstName}` : `${bedBoardIntake.firstName} ${bedBoardIntake.lastName}`;
};

export const cleanProblemList = (items?: ProblemListItemDto[]): ProblemListItemDto[] => {
    return items?.filter((x) => x?.snomedConceptId != null && x?.preferredTerm != null);
};

export const getEnumFilters = (list: EnumSelectListDto[]) => (list ?? []).map((item) => ({ value: item.id, text: item.name }));

export const handleTemplateReplaceText = (e: SyntheticEvent, replaceText = '??') => {
    const { key, shiftKey, target } = e.nativeEvent as KeyboardEvent;
    const inputTarget: any = target;

    if (key === 'Tab') {
        // tab was pressed
        const isShift = !!shiftKey;
        let nextInputIndex;

        if (isShift) {
            // Search backwards, Get caret position
            const start = inputTarget.selectionStart - 1;

            // Find next input location
            nextInputIndex = inputTarget.value.lastIndexOf(replaceText, start);
        } else {
            // Search forward, Get caret position
            const end = inputTarget.selectionEnd;

            // Find next input location
            nextInputIndex = inputTarget.value.indexOf(replaceText, end);
        }

        const preventBlur = nextInputIndex >= 0;
        if (preventBlur) {
            // Highlight the next input
            inputTarget.selectionStart = nextInputIndex;
            inputTarget.selectionEnd = nextInputIndex + 2;

            // Keep focus in the textfield
            e.preventDefault();
        }
    }
};

export const calculateAge = (birthDate?: Moment): number => {
    return moment().diff(birthDate, 'years');
};

export function trimSpacesFromStrings<T>(obj: T): T {
    const newObj = { ...obj };

    Object.entries(newObj).forEach(([key, value]) => {
        if (typeof value === 'string') {
            newObj[key] = value.trim();
        }
    });

    return newObj;
}

export const getFormFieldNamePath = (fieldName: NamePath, formList?: string): NamePath => {
    if (formList) {
        if (typeof fieldName === 'string') {
            return [formList, fieldName] as NamePath;
        }

        return [formList, ...(fieldName as any[])] as NamePath;
    }

    return fieldName;
};

export const getLatestBloodPressureFromVitals = (pccPatient: PccPatientDataDto, cutoffDate: Moment): { observationTime?: Moment; systolic?: string; diastolic?: string } => {
    if (pccPatient != null) {
        const vitals = pccPatient.pccPatientData.vitals;
        if (vitals != null) {
            const latestBloodPressure = vitals.bloodPressure.find((x) => x.observationTime <= cutoffDate);
            let observationTime = latestBloodPressure?.observationTime;

            if (observationTime != null) {
                observationTime = moment(latestBloodPressure.observationTime.toISOString().substring(0, 10), 'YYYY-MM-DD');
            }

            return {
                observationTime: observationTime,
                systolic: latestBloodPressure?.value,
                diastolic: latestBloodPressure?.diastolic,
            };
        }
    }

    return null;
};

export const isDateAfter = (value1: Moment, value2: Moment) => {
    const date1 = moment(value1.toISOString().substring(0, 10), 'YYYY-MM-DD');
    const date2 = moment(value2.toISOString().substring(0, 10), 'YYYY-MM-DD');

    return date1.isAfter(date2);
};

export const getPccVitals = (
    pccPatient: PccPatientDataDto,
    cutoffDate: Moment
): {
    height?: PccVitalDto;
    weight?: PccVitalDto;
    pulseOx?: PccVitalDto;
    bloodPressure?: PccVitalDto;
    heartRate?: PccVitalDto;
} => {
    if (pccPatient != null) {
        const vitals = pccPatient.pccPatientData.vitals;
        if (vitals != null) {
            const height = vitals.height.find((x) => x.observationTime <= cutoffDate);
            const weight = vitals.weight.find((x) => x.observationTime <= cutoffDate);
            const pulseOx = vitals.oxygenSaturation.find((x) => x.observationTime <= cutoffDate);
            const bloodPressure = vitals.bloodPressure.find((x) => x.observationTime <= cutoffDate);
            const heartRate = vitals.heartRate.find((x) => x.observationTime <= cutoffDate);

            return {
                height,
                weight,
                pulseOx,
                bloodPressure,
                heartRate,
            };
        }
    }

    return null;
};

export const calcBmi = (heightInInches?: number, weightInPounds?: number) => {
    return heightInInches && weightInPounds ? Math.round(((703 * weightInPounds) / (heightInInches * heightInInches)) * 10) / 10 : null;
};

export const isBedBoardKiosk = () => {
    const pathname = window.location.pathname;

    return pathname.startsWith('/kiosk');
};

export const isBedBoardIntakeForm = () => {
    const pathname = window.location.pathname;

    // BedBoardIntakeFormPaths.Index
    return pathname.startsWith('/intake-form') || isBedBoardKiosk();
};

export const getLatestFileByType = (documentGroups: AdmissionDocGroupDto[], documentType: DocumentTypes) => {
    return documentGroups.find((x) => x.documentType === documentType)?.documents?.find(() => true);
};

export const shouldShowVisitButton = (type: NoteTypeNameValue, censusVisitButton: VisitButtonDict, visitDue: RequiredVisitResultDto) => {
    const userIsPMRProvider = censusVisitButton?.userIsPMRProvider;
    const isInitialPMRButtonVisible = censusVisitButton?.isInitialPMRButtonVisible;

    if (userIsPMRProvider) {
        // Hide IntialPMR button if there is already an Initial or Follow-Up PM&R note
        if (type.value === NoteTypes.InitialPMR && !isInitialPMRButtonVisible) {
            return false;
        }

        // Otherwise hide all other note types for PM&R providers
        if (type.value !== NoteTypes.InitialPMR && type.value !== NoteTypes.FollowUpPMR) {
            return false;
        }
    } else {
        // For non-PM&R providers, follow this logic for visit button display

        if (type.value === NoteTypes.AnnualWellnessVisit && !censusVisitButton?.isAwvButtonVisible) {
            return false;
        }

        if (type.value === NoteTypes.ProgressNote && !censusVisitButton?.isProgressButtonVisible) {
            return false;
        }

        if (type.value === NoteTypes.DischargeSummary && !censusVisitButton?.isDischargeButtonVisible) {
            return false;
        }

        // If the visit type is annual hp, then show, otherwise don't show this button
        if (type.value === NoteTypes.AnnualHistoryAndPhysical && !visitDue?.isAnnualHpDue) {
            return false;
        }

        if (type.value === NoteTypes.HistoryAndPhysical && !censusVisitButton?.isHistoryAndPhysicalButtonVisible) {
            return false;
        }

        // Hide wound care for non-wound providers
        if (type.value === NoteTypes.WoundCare && !censusVisitButton?.isWoundButtonVisible) {
            return false;
        }

        // Hide PM&R buttons if the user is not a PM&R Provider
        if (type.value === NoteTypes.InitialPMR || type.value === NoteTypes.FollowUpPMR) {
            return false;
        }
    }

    return true;
};

export const formatAmount = (value: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formatter.format(value);
};

export const isNoteEditPage = () => {
    return window.location.pathname.includes('note/edit');
};

export const allowSignalrConnection = (currentNote?: NoteDto) => {
    if (isNoteEditPage() || window.location.pathname.includes('postacute/unsigned-notes') || (currentNote?.id != null && currentNote.id > 0)) {
        return false;
    }

    return true;
};
