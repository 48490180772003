import { PatientAddress, PccCensusDto } from '@medone/medonehp-api-client';
import { Collapse, Descriptions } from 'antd';
import { startCase } from 'lodash';
import moment from 'moment';

import { customExpandIcon } from '../../../../../../../shared/common/helpers/collapse';
import { formatDateTime, formatPccGender, valueOrDefault } from '../../../../../../../shared/common/helpers';

type Params = {
    census: PccCensusDto;
};

const { Panel } = Collapse;
const ignoreFields = ['id', 'orgUuid', 'admissionDateTime', 'patientIntakeId', 'isPatientPccLinked'];

const renderPccPatientAddress = (value?: PatientAddress) => {
    return (
        value && (
            <>
                {value.addressLine1}
                <br />
                {value.addressLine2 != null && (
                    <>
                        {value.addressLine2}
                        <br />
                    </>
                )}
                {value.city}, {value.state} {value.postalCode}
            </>
        )
    );
};

const PccCensuDetails = ({ census }: Params) => {
    const renderValue = (item: any) => {
        const label = item[0];
        const value = item[1];

        if (value != null) {
            if (moment.isMoment(value)) {
                return formatDateTime(value, '');
            } else if (value instanceof PatientAddress) {
                return renderPccPatientAddress(value);
            } else {
                if (label === 'gender') {
                    return formatPccGender(value);
                }

                if (typeof value === 'boolean') {
                    return value ? 'Yes' : 'No';
                }

                return valueOrDefault(value, '');
            }
        }

        return '';
    };

    return (
        <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon} activeKey="Patient Details">
            <Panel key="Patient Details" header="Patient Details">
                <Descriptions title="" bordered column={{ xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
                    {census &&
                        Object.entries(census)
                            .filter((item) => !ignoreFields.includes(item[0]))
                            .map((item) => (
                                <Descriptions.Item key={item[0]} label={`${startCase(item[0])}`}>
                                    {renderValue(item)}
                                </Descriptions.Item>
                            ))}
                </Descriptions>
            </Panel>
        </Collapse>
    );
};

export default PccCensuDetails;
