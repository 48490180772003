import { lazy } from 'react';
import { Route, Switch } from 'react-router';

import { Role } from '../../../../shared/common/auth/RoleAuth';

import AuthorizedRoute from '../../../../shared/common/auth/AuthorizedRoute';

const Dashboard = lazy(() => import('./components/dashboard'));
const MedicalSummaryList = lazy(() => import('./components/medical-summary-list'));
const MedicalSummary = lazy(() => import('./components/medical-summary'));
const FaceSheets = lazy(() => import('./components/face-sheets'));
const AnnualWellnessVisit = lazy(() => import('./components/annual-wellness'));
const AwvForm = lazy(() => import('./components/awv-form'));

export const ClinicalCoordinatorPaths = {
    Index: '/postacute/clinical-coordinator',
    MedicalSummaryList: '/postacute/clinical-coordinator/medical-summary-list',
    MedicalSummary: '/postacute/clinical-coordinator/medical-summary',
    FaceSheets: '/postacute/clinical-coordinator/face-sheets',
    AnnualWellnessVisit: '/postacute/clinical-coordinator/annual-wellness',
    AwvForm: '/postacute/clinical-coordinator/awv-form',
};

const Routes = () => {
    return (
        <Switch>
            <Route exact path={ClinicalCoordinatorPaths.Index} component={Dashboard} />

            <AuthorizedRoute
                path={`${ClinicalCoordinatorPaths.MedicalSummaryList}/:type`}
                component={MedicalSummaryList}
                roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR]}
            />

            <AuthorizedRoute path={`${ClinicalCoordinatorPaths.MedicalSummary}/:intakeId`} component={MedicalSummary} roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR]} />

            <AuthorizedRoute path={ClinicalCoordinatorPaths.FaceSheets} component={FaceSheets} roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR]} />

            <AuthorizedRoute path={ClinicalCoordinatorPaths.AnnualWellnessVisit} component={AnnualWellnessVisit} roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR]} />

            <AuthorizedRoute path={`${ClinicalCoordinatorPaths.AwvForm}/:id`} component={AwvForm} roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR]} />
        </Switch>
    );
};

export default Routes;
