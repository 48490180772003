import { FileImportClient, FileResponse, PatientIntakeDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../shared/common/http';
import { handleError } from '../../../shared/common/HandleErrors';
import { usersSlice } from './slice';
import { AppThunk, AppDispatch, RootState } from '../../../shared/store';

export const reducers = {};

export function uploadSnomed(file: any): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(usersSlice.actions.setError(null));

        
        const client = new FileImportClient(null, Axios);

        try {
            await client.uploadFileToImport(file);

            return true;
        } catch (error) {
            handleError(error, () => {
                console.log('Check server logs for upload error');
            });
        }

        return false;
    };
}

export function uploadWoundIcd(file: any): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(usersSlice.actions.setError(null));

        
        const client = new FileImportClient(null, Axios);

        try {
            await client.uploadWoundIcd10FileToImport(file);

            return true;
        } catch (error) {
            handleError(error, () => {
                console.log('Check server logs for upload error');
            });
        }

        return false;
    };
}

export function downloadPatientIntakesTemplate(facilityId: number): AppThunk<Promise<FileResponse>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(usersSlice.actions.setError(null));

        
        const client = new FileImportClient(null, Axios);

        try {
            const response = await client.bulkIntakeTemplate(facilityId);

            if (response.status === 200) {
                return response.result;
            }

            handleError(response);
        } catch (error) {
            handleError(error, () => {
                console.log('Check server logs for upload error');
            });
        }

        return null;
    };
}

export function uploadPatientIntakes(file: any): AppThunk<Promise<PatientIntakeDto[]>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(usersSlice.actions.setError(null));

        
        const client = new FileImportClient(null, Axios);

        try {
            const response = await client.bulkImportIntakes(file);
            if (response.result.succeeded) {
                return response.result.entity;
            }

            dispatch(usersSlice.actions.setError(response.result.message));

            handleError(response);
        } catch (error) {
            handleError(error, () => {
                console.log('Check server logs for upload error');
            });
        }

        return null;
    };
}
