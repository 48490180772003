import { Alert, Card, Col, Row } from 'antd';
import { PccCensusGroupDto } from '@medone/medonehp-api-client';

import { selectPccCensus } from '../../slice';
import { useAppSelector } from '../../../../../../shared/hooks';

import DetailsModal from '../details';
import PatientTable from './patient-table';
import PccErrors from '../../../components/PccErrors';

const Results = () => {
    const census = useAppSelector<PccCensusGroupDto>(selectPccCensus);

    return (
        <>
            {census && <DetailsModal />}

            <Row gutter={48} className="content">
                <Col span={24}>
                    {census != null ? (
                        <Card title={census.admittedTo}>
                            <PccErrors errorMessages={census.errorMessages} />

                            {(!census.errorMessages || census.errorMessages.length === 0) && <PatientTable census={census.items} />}
                        </Card>
                    ) : (
                        <Alert message="No results found" type="info" showIcon />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default Results;
