import { Route, Switch } from 'react-router';
import { Alert } from 'antd';

import { selectPccError } from './slice';
import { useAppSelector } from '../../../../shared/hooks';

import Home from './components/home/index';

export const PccCensusPaths = {
    Home: {
        Index: '/postacute/pcc-census',
    },
};

const Routes = () => {
    const errorMessage = useAppSelector<string>(selectPccError);

    return (
        <>
            <Switch>
                <Route exact path={`${PccCensusPaths.Home.Index}/:facilityId/:orgUuid`} component={Home} />
            </Switch>

            {errorMessage != null && errorMessage !== '' && <Alert message={null} description={errorMessage} type="error" showIcon />}
        </>
    );
};

export default Routes;
