import { useHistory, useLocation } from 'react-router-dom';
import { Row, Alert, Button } from 'antd';

const rowStyle = { width: '100%', marginTop: '5%' };

const NoAccess = () => {
    const location = useLocation();
    const history = useHistory();
    const { search } = location;
    const path = search.replace('?page=', '');
    const pageMessage = path ? ` (${path})` : '';

    return (
        <Row align="middle" justify="center" style={rowStyle}>
            <Alert
                message="No Access"
                description={
                    <>
                        <p>
                            It appears you may not have permission to view this page{pageMessage}. Please verify you're signed in. If you believe you're getting this message in
                            error try to logout and login again.
                        </p>

                        <p className="text-center">
                            <Button type="primary" onClick={() => history.push('/')}>
                                Back to Home
                            </Button>
                        </p>
                    </>
                }
                type="warning"
                showIcon
            />
        </Row>
    );
};

export default NoAccess;
