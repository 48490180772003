import { AbandonedBadgeDto, CensusDto } from '@medone/medonehp-api-client';

export interface AbandonedState {
    errorMessage: string;
    patients: CensusDto[];
    totalAbandoned: AbandonedBadgeDto;
}

export const initialState: AbandonedState = {
    errorMessage: null,
    patients: [],
    totalAbandoned: null,
};
