import { PayloadAction } from '@reduxjs/toolkit';
import { UsersClient,  UserDto, UsersRegionProviderFiltersDto, ProviderTypes } from '@medone/medonehp-api-client';

import { RouteBuilderState } from './models';
import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { handleError } from '../../../../shared/common/HandleErrors';
import { routeBuilderSlice } from './slice';

export const reducers = {
    setUsers: (state: RouteBuilderState, action: PayloadAction<UserDto[]>) => {
        state.users = action.payload;
    },
};

export function fetchUsersByRegion(regionId: number, providerTypes?: ProviderTypes[], isEdit = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        if (!isEdit) {
            dispatch(routeBuilderSlice.actions.setLoading(true));
        }

        
        const client = new UsersClient(null, Axios);

        try {
            const data = UsersRegionProviderFiltersDto.fromJS({ regionId, providerTypes });
            const response = regionId != null ? await client.getAllByRegion(data) : await client.getAll();

            if (response.result.succeeded) {
                dispatch(routeBuilderSlice.actions.setUsers(response.result.entity));
            } else {
                handleError(response, () => dispatch(routeBuilderSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(routeBuilderSlice.actions.setError(error.toString())));
        }
    };
}

export const selectUsers = (state: RootState) => state.routeBuilder.users;
