import { Table } from 'antd';
import { PccVitalDto, VitalType } from '@medone/medonehp-api-client';

const { Column } = Table;

type Props = {
    pccVitals: PccVitalDto[];
};

const PccVitalsTable = ({ pccVitals }: Props) => {
    return (
        <Table rowKey={(x) => `${x.vitalType}-${x.value}`} pagination={false} dataSource={pccVitals}>
            <Column<PccVitalDto>
                title="Value"
                dataIndex="value"
                key="value"
                render={(text, record: PccVitalDto) => {
                    if (record.vitalType === VitalType.BloodPressure) {
                        return (
                            <div>
                                {record.value}/{record.diastolic} {record.unit}
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                {record.value} {record.unit}
                            </div>
                        );
                    }
                }}
            />
            <Column<PccVitalDto> title="Observation Time" dataIndex="observationTime" key="observationTime" render={(observationTime) => <>{observationTime?.format('L LT')}</>} />
            <Column<PccVitalDto> title="Method" dataIndex="method" key="method" />
        </Table>
    );
};

export default PccVitalsTable;
