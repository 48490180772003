import { UserDto } from '@medone/medonehp-api-client';
import { BedBoardAssignableUserDto } from '@medone/medonehp-bedboard-client';

export interface UsersState {
    loading: boolean;
    processing: boolean;
    errorMessage: string | null;
    users: UserDto[];
    currentUser: UserDto;
    additionalRoleUsers: UserDto[];
    bedboardAssignableUsers: BedBoardAssignableUserDto[];
}

export const initialState: UsersState = {
    loading: true,
    processing: false,
    errorMessage: null,
    users: [],
    currentUser: null,
    additionalRoleUsers: [],
    bedboardAssignableUsers: [],
};
