import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState, AbandonedState } from './models';
import { Axios } from '../../../../shared/common/http';
import { AbandonedBadgeDto,  CensusDto, MedicalSummaryCompleteByProviderRequestDto, PatientIntakesClient, RequeueAbandonRequestDto } from '@medone/medonehp-api-client';

import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { handleError } from '../../../../shared/common/HandleErrors';
import { AnyProvider, Role } from '../../../../shared/common/auth/RoleAuth';
import { isInRole } from '../../../../shared/common/helpers';

export const abandonedSlice = createSlice({
    name: 'abandonedSlice',
    initialState,
    reducers: {
        setError: (state: AbandonedState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        setPatients: (state: AbandonedState, action: PayloadAction<CensusDto[]>) => {
            state.patients = action.payload;
        },

        setTotalAbandoned: (state: AbandonedState, action: PayloadAction<AbandonedBadgeDto>) => {
            state.totalAbandoned = action.payload;
        },
    },
});

export function fetchAbandonedPatients(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;

        if (!isInRole(permissions, [Role.POST_ACUTE_ADMIN, ...AnyProvider]) || window.location.pathname !== '/postacute/abandoned') {
            return;
        }

        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getAbandoned();

            if (response.result.succeeded) {
                dispatch(abandonedSlice.actions.setPatients(response.result.entity));
            } else {
                handleError(response, () => dispatch(abandonedSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(abandonedSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchTotalAbandoned(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;

        if (
            !isInRole(permissions, [Role.POST_ACUTE_ADMIN, ...AnyProvider]) ||
            (window.location.pathname !== '/postacute/census' && window.location.pathname !== '/postacute/abandoned')
        ) {
            return;
        }

        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getAbandonedBadge();

            // We rely on signalr to set the badge count instead of the return value of this call

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(abandonedSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(abandonedSlice.actions.setError(error.toString())));
        }
    };
}

export function requeueAbandon(patientIntakeId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;

        if (!isInRole(permissions, [Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, ...AnyProvider])) {
            return;
        }

        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.requeueAbandon(RequeueAbandonRequestDto.fromJS({ patientIntakeId }));

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(abandonedSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(abandonedSlice.actions.setError(error.toString())));
        }
    };
}

export function completeByProvider(patientIntakeId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;

        if (!isInRole(permissions, [Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, ...AnyProvider])) {
            return;
        }

        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.completeByProvider(MedicalSummaryCompleteByProviderRequestDto.fromJS({ patientIntakeId }));

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(abandonedSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(abandonedSlice.actions.setError(error.toString())));
        }
    };
}

export const selectAbandonedPatients = (state: RootState) => state.abandoned.patients;
export const selectTotalAbandoned = (state: RootState) => state.abandoned.totalAbandoned;

export default abandonedSlice.reducer;
