import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../shared/store';

import { initialState, ScheduleState } from './models';
import { reducers as scheduleReducers } from './slice.schedules';

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        setError: (state: ScheduleState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        ...scheduleReducers,
    },
});

export const selectError = (state: RootState) => state.schedule.errorMessage;

export default scheduleSlice.reducer;
