import { FilterValue, TablePaginationConfig } from 'antd/lib/table/interface';

const keyPrefix = 'medone:';

export class LocalStoragePageKeys {
    public static readonly census: string = 'census';
    public static readonly note: string = 'note';
    public static readonly routebuilder: string = 'routebuilder';
    public static readonly unsignedNotes: string = 'unsigned-notes';
}

export class LocalStorageComponentKeys {
    public static readonly patientTable: string = 'patient-table';
}

export class UnsignedNotesRadioValues {
    public static readonly mine: string = 'mine';
    public static readonly all: string = 'all';
    public static readonly staged: string = 'staged';
    public static readonly scribe: string = 'scribe';
}

export type TableState = {
    filters: Record<string, FilterValue | null>;
    pagination: TablePaginationConfig;

    // Can't use the typed value here because the original template file has an incompatible type union.
    sorter: any;
};

export const getStoredChartState = (keys: string[]) => {
    const censusDto = localStorage.getItem(`${keyPrefix}open-chart`);

    return censusDto ? JSON.parse(censusDto) : null;
};

export const storeChartState = (censusDto: any) => {
    const value = censusDto ? JSON.stringify(censusDto) : null;

    localStorage.setItem(`${keyPrefix}open-chart`, value);
};

export const getStoredCollapsedState = (keys: string[]) => {
    return localStorage.getItem(`${buildKey(...keys)}:open-collapse`);
};

export const storeCollapsedState = (keys: string[], collapseKey: string) => {
    localStorage.setItem(`${buildKey(...keys)}:open-collapse`, collapseKey);
};

export const getStoredExpandedState = (keys: string[]) => {
    return localStorage.getItem(`${buildKey(...keys)}:open-table-expander`);
};

export const storeExpandedState = (keys: string[], rowKey: string) => {
    localStorage.setItem(`${buildKey(...keys)}:open-table-expander`, rowKey);
};

export const getStoredFilters = (keys: string[]) => {
    const filters = localStorage.getItem(`${buildKey(...keys)}:filters`);

    return filters ? JSON.parse(filters) : null;
};

export const storeFilters = (keys: string[], filters) => {
    const value = filters ? JSON.stringify(filters) : null;

    localStorage.setItem(`${buildKey(...keys)}:filters`, value);
};

export const getStoredRadioButtonValue = (keys: string[]) => {
    return localStorage.getItem(`${buildKey(...keys)}:radio-value`);
};

export const storeRadioButtonValue = (keys: string[], radioValue: string) => {
    localStorage.setItem(`${buildKey(...keys)}:radio-value`, radioValue);
};

export const getStoredTableState = (keys: string[]) => {
    const tableState = localStorage.getItem(`${buildKey(...keys)}:table-state`);

    return tableState ? (JSON.parse(tableState) as TableState) : null;
};

export const storeTableState = (keys: string[], tableState: TableState) => {
    localStorage.setItem(`${buildKey(...keys)}:table-state`, JSON.stringify(tableState));
};

const buildKey = (...keys: string[]) => {
    return `${keyPrefix}${keys.join(':')}`;
};
