import { useAppSelector } from '../../../../../../shared/hooks';
import { censusBadgeSelectors } from '../../slice';

import Badges from '../../../components/badges';

type Props = {
    patientIntakeId: number;
    patientTypeIds: string;
    canReturnFromED: boolean;
};

const CensusBadges = ({ patientIntakeId, patientTypeIds, canReturnFromED }: Props) => {
    const censusBadge = useAppSelector((state) => censusBadgeSelectors.selectById(state.census.badges, patientIntakeId));

    return <Badges censusBadge={censusBadge} patientTypeIds={patientTypeIds} canReturnFromED={canReturnFromED} />;
};

export default CensusBadges;
