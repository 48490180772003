import { Alert } from 'antd';
import { Switch } from 'react-router';

import { selectError } from './slice';
import { useAppSelector } from '../../../../shared/hooks';
import { Role } from '../../../../shared/common/auth/RoleAuth';

import AuthorizedRoute from '../../../../shared/common/auth/AuthorizedRoute';

import Home from './index';

export const RouteBuilderPaths = {
    Home: {
        Index: '/postacute/route-builder',
    },
};

const Routes = () => {
    const errorMessage = useAppSelector<string>(selectError);

    return (
        <>
            <Switch>
                <AuthorizedRoute exact path={RouteBuilderPaths.Home.Index} component={Home} roles={[Role.ROUTE_BUILDER]} />
            </Switch>

            {errorMessage != null && errorMessage !== '' && <Alert message={null} description={errorMessage} type="error" showIcon />}
        </>
    );
};

export default Routes;
