import { PccCensusDto, PccCensusFilterDto, PccCensusGroupDto } from '@medone/medonehp-api-client';

export interface PccCensusState {
    loading: boolean;
    processing: boolean;
    errorMessage: string;
    census: PccCensusGroupDto;
    currentCensus?: PccCensusDto;
    censusFilters: PccCensusFilterDto;
}

export const initialState: PccCensusState = {
    loading: true,
    processing: false,
    errorMessage: null,
    census: null,
    currentCensus: null,
    censusFilters: null,
};
