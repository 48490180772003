import { PostAcutePaths } from './../../index.routes';
import { HubConnection } from '@microsoft/signalr';
import { ScheduleDto } from '@medone/medonehp-api-client';

import { store } from '../../../../../shared/store';
import { fetchRouteBuilder, setRouteBuilder } from '../slice';
import { getSchedules } from '../slice.schedules';
import { setAssignedProvider } from '../utils';
import { debounce } from 'lodash';
import { SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions } from '../../../../../Config';

export const initRouteBuilderHubConnection = (connection: HubConnection) => {

    connection.on('PatientIntakeUpdated', debounce(() => {
        if (window.location.pathname === PostAcutePaths.RouteBuilder.Index) {
            const state = store.getState();
            const { filters } = state.routeBuilder;
            store.dispatch(fetchRouteBuilder(filters, true)); // Refresh the route builder regulatory information
        }
    }, SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions));

    connection.on('ScheduleAdded', async (dto: ScheduleDto[]) => {
        if (window.location.pathname === PostAcutePaths.RouteBuilder.Index) {
            const state = store.getState();
            const newRouteBuilder = setAssignedProvider(state.routeBuilder.groups, dto);
            const { currentScheduleDate, currentScheduleRegion } = state.routeBuilder;

            // Sets the assigned provider on the route builders left dataset
            store.dispatch(setRouteBuilder(newRouteBuilder));

            if (currentScheduleDate != null && currentScheduleRegion != null) {
                // Reload the Schedules data on the route builders right dataset
                await store.dispatch(getSchedules(currentScheduleDate, currentScheduleRegion, null, null, false));
            }
        }
    });
};
