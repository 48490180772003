import { Alert, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isBeta } from '.';
import { ReactElement } from 'react';

type FallbackProps = {
    error: Error;
    componentStack: any;
    resetError: () => void;
};

export const showNewVersionModal = () => {
    Modal.confirm({
        title: 'New Release',
        icon: <ExclamationCircleOutlined />,
        content: (
            <>
                <p>A new version of the site has been released. Please click Reload to reload the page, or click Continue working to reload manually at a later time.</p>

                <Alert type="warning" description={<em>By continuing to work without a reload you may experience issues.</em>} />
            </>
        ),
        cancelText: 'Continue working...',
        okText: 'Reload',
        onCancel: () => {},
        onOk: async () => {
            const cacheKeys = await caches.keys();

            cacheKeys.forEach(async (key) => {
                await caches.delete(key);
            });

            if (!isBeta()) {
                window.location.reload();
            }
        },
    });
};

export const FallbackComponent = ({ error, componentStack, resetError }: FallbackProps): ReactElement => {
    const errorStr = error.toString();

    if (errorStr.includes('CanceledError')) {
        resetError();

        return <></>;
    }

    if (errorStr.includes('ChunkLoadError')) {
        resetError();
        showNewVersionModal();

        return <></>;
    }

    return <Alert type="error" message={error.toString()} />;
};

export const handleAppError = (error: Error) => {
    if (error.toString().includes('ChunkLoadError')) {
        showNewVersionModal();
    }
};
