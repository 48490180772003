import { RouteBuilderState } from './models';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    RouteBuilderClient,
    
    RouteBuilderPostRequestDto,
    ScheduleProviderGroupDto,
    RouteBuilderSearchRequestDto,
    RouteBuilderPublishRequestDto,
    ScheduleDto,
} from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { handleError } from '../../../../shared/common/HandleErrors';
import { fetchRouteBuilder, routeBuilderSlice } from './slice';
import { flattenDeep } from 'lodash';
//import { fetchTotals } from '../clinical-coordinator/slice';

export const reducers = {
    setSchedulesLoading: (state: RouteBuilderState, action: PayloadAction<boolean>) => {
        state.schedulesLoading = action.payload;
    },

    setSchedules: (state: RouteBuilderState, action: PayloadAction<ScheduleProviderGroupDto[]>) => {
        state.schedules = action.payload;
        state.schedulesLoading = false;
    },

    setScheduleDateRegion: (state: RouteBuilderState, action: PayloadAction<any>) => {
        const { scheduleDate, scheduleRegion } = action.payload;

        state.currentScheduleDate = scheduleDate;
        state.currentScheduleRegion = scheduleRegion;
    },
};

export function getSchedules(scheduleDate: moment.Moment, regionId?: number, facilityId?: number, providerId?: string, toggleLoading = true): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        if (toggleLoading) {
            dispatch(routeBuilderSlice.actions.setSchedulesLoading(true));
        }

        dispatch(routeBuilderSlice.actions.setScheduleDateRegion({ scheduleDate, scheduleRegion: regionId }));

        
        const client = new RouteBuilderClient(null, Axios);

        try {
            const data = RouteBuilderSearchRequestDto.fromJS({ scheduleDate, regionId, facilityId, providerId });
            const response = await client.search(data);

            if (response.result.succeeded) {
                dispatch(routeBuilderSlice.actions.setSchedules(response.result.entity));
            } else {
                handleError(response, () => dispatch(routeBuilderSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(routeBuilderSlice.actions.setError(error.toString())));
        }
    };
}

export function postSchedule(data: RouteBuilderPostRequestDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {

        const client = new RouteBuilderClient(null, Axios);

        try {
            const response = await client.post(data);

            if (response.result.succeeded) {
                await dispatch(getSchedules(data.scheduleDate, data.regionId, null, null, false));

                //await dispatch(fetchTotals());

                await dispatch(fetchRouteBuilder(null, true)); // Gets totals for providers/pys/app
            } else {
                handleError(response, () => dispatch(routeBuilderSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(routeBuilderSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function publishSchedule(data: RouteBuilderPublishRequestDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new RouteBuilderClient(null, Axios);

        try {
            const response = await client.publish(data);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(routeBuilderSlice.actions.setError(null)));
            } else {
                //await dispatch(fetchTotals());

                await dispatch(fetchRouteBuilder(null, true)); // Gets totals for providers/pys/app
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(routeBuilderSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export const selectSchedulesLoading = (state: RootState) => state.routeBuilder.schedulesLoading;
export const selectUsers = (state: RootState) => state.routeBuilder.users;
export const selectSchedules = (state: RootState) => state.routeBuilder.schedules;

export const selectRouteBuilderSchedules = (state: RootState) => {
    if (!state.routeBuilder.schedules) {
        return [];
    }

    return flattenDeep<ScheduleDto[]>((state.routeBuilder.schedules || []).map((x) => x.items.map((y) => y.items))) as ScheduleDto[];
};
