import { HubConnection } from '@microsoft/signalr';
import { CensusDto } from '@medone/medonehp-api-client';

import { store } from '../../../../../shared/store';
import { updateCensus, updateCensusRoomNumber } from '../../census/slice';

const reloadPccCensus = (dto?: any) => {
    if (dto != null && dto.censusItems != null) {
        dto.censusItems.forEach((item) => {
            store.dispatch(updateCensus(CensusDto.fromJS(item)));
        });
    }
};

export const initPccHubConnection = (connection: HubConnection) => {
    // ADT01 Events
    connection.on('patient.admit', reloadPccCensus);
    connection.on('patient.readmit', reloadPccCensus);
    connection.on('patient.cancelAdmit', reloadPccCensus);
    connection.on('patient.discharge', reloadPccCensus); // Update medone census
    connection.on('patient.cancelDischarge', reloadPccCensus); // Update medone census
    connection.on('patient.transfer', (dto: any) => {
        store.dispatch(updateCensusRoomNumber(CensusDto.fromJS(dto.census)));

        reloadPccCensus(dto);
    });

    // ADT02 Events
    connection.on('patient.cancelTransfer', reloadPccCensus);
    connection.on('patient.updateResidentInfo', reloadPccCensus);

    // ADT03 Events
    connection.on('patient.leave', reloadPccCensus);
    connection.on('patient.returnFromLeave', reloadPccCensus);
    connection.on('patient.cancelLeave', reloadPccCensus);
    connection.on('patient.cancelReturnFromLeave', reloadPccCensus);

    // ADT04 Events
    connection.on('patient.preAdmit', reloadPccCensus);

    // ADT05 Events
    connection.on('patient.outpatientToInpatient', reloadPccCensus);
    connection.on('patient.inpatientToOutpatient', reloadPccCensus);

    // ADT06 Events
    connection.on('patient.updateAccount', reloadPccCensus);
    connection.on('patient.updateAccountOnReturnFromLeave', reloadPccCensus);

    // ADT08 Events
    connection.on('patient.updateEpisodeofCare', reloadPccCensus);
};
