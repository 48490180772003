import {
    PatientIntakeSpecialtyDischargeDto,
    PatientIntakeSpecialtyDto,
    PatientIntakeSpecialtyGroupedDto,
    PatientIntakeSpecialtyRequestDto,
    SpecialtiesClient,
} from '@medone/medonehp-api-client';
import { PayloadAction } from '@reduxjs/toolkit';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { censusSlice } from './slice';
import { CensusState, specialtiesAdapter } from './models';
import { handleError } from '../../../../shared/common/HandleErrors';

export const reducers = {
    setSpecialties: (state: CensusState, action: PayloadAction<PatientIntakeSpecialtyGroupedDto[]>) => {
        specialtiesAdapter.setMany(state.specialties, action.payload);
    },
};

export function fetchSpecialties(patientIntakeIds?: number | number[]): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new SpecialtiesClient(null, Axios);

        // Prevent empty requests
        if (!patientIntakeIds || (typeof patientIntakeIds === 'number' && patientIntakeIds === 0) || (typeof patientIntakeIds === 'object' && patientIntakeIds.length === 0)) {
            return;
        }

        try {
            const dto = PatientIntakeSpecialtyRequestDto.fromJS({});

            if (typeof patientIntakeIds === 'number') {
                dto.patientIntakeIds = [patientIntakeIds];
            }

            if (typeof patientIntakeIds === 'object') {
                dto.patientIntakeIds = patientIntakeIds;
            }

            const response = await client.getByPatientIntakeIds(dto);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setSpecialties(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function createReferral(dto: PatientIntakeSpecialtyDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new SpecialtiesClient(null, Axios);

        try {
            const response = await client.createReferral(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function signOffSpecialty(dto: PatientIntakeSpecialtyDischargeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new SpecialtiesClient(null, Axios);

        try {
            const response = await client.discharge(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}
