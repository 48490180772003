import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import {
    RouteBuilderGroupDto,
    RouteBuilderFiltersDto,
    ScheduleProviderGroupDto,
    UserDto,
    RequiredVisitResultDto,
    RouteBuilderProviderGroupCountsDto,
    CensusBadgeDto,
} from '@medone/medonehp-api-client';
import { Moment } from 'moment';

export const routeBuilderBadgesAdapter = createEntityAdapter<CensusBadgeDto, number>({
    selectId: (item) => item.patientIntakeId,
});

export interface RouteBuilderTotalsDto {
    newAdmits?: number;
    physicianFollowUps?: number;
    physicianOrAppFollowUps?: number;
}

export interface RouteBuilderState {
    loading: boolean;
    schedulesLoading: boolean;
    detailsLoading: boolean;
    processing: boolean;
    errorMessage: string;
    badges: EntityState<CensusBadgeDto, number>;
    groups: RouteBuilderGroupDto[];
    totals: RouteBuilderTotalsDto;
    providerTotals: RouteBuilderProviderGroupCountsDto[];
    filters: RouteBuilderFiltersDto;
    users: UserDto[];
    schedules: ScheduleProviderGroupDto[];
    visitDues: RequiredVisitResultDto[];
    currentScheduleDate?: Moment;
    currentScheduleRegion?: number;
}

export const initialState: RouteBuilderState = {
    loading: true,
    schedulesLoading: true,
    detailsLoading: false,
    processing: false,
    errorMessage: null,
    badges: routeBuilderBadgesAdapter.getInitialState({}),
    groups: [],
    filters: null,
    totals: null,
    providerTotals: [],
    users: [],
    schedules: [],
    visitDues: [],
    currentScheduleDate: null,
    currentScheduleRegion: null,
};
