import {
    ClinicalCoordinatorTotalDto,
    ClinicalCoordinatorTotalsDto,
    PatientIntakeDto,
    MedicalSummaryLockStatusDto,
    CensusBadgeDto,
    AwvQueueItemDto,
} from '@medone/medonehp-api-client';

export interface CcTotalUpdate {
    data: ClinicalCoordinatorTotalDto;
    field: string;
}

export interface ClinicalCoordinatorState {
    errorMessage: string;
    totalsLoading: boolean;
    totals: ClinicalCoordinatorTotalsDto;
    medicalSummaryIntake: PatientIntakeDto;
    medicalSummaryLockStatus: MedicalSummaryLockStatusDto;
    medicalSummaryIsLocked: boolean;
    medicalSummaryIntakes: PatientIntakeDto[];
    facesheets: PatientIntakeDto[];
    annualWellnessVisits: PatientIntakeDto[];
    awvQueueItem: AwvQueueItemDto;
    badgeData: CensusBadgeDto;
    badgesData: CensusBadgeDto[];
    nextMedicalSummaryIntakeId: number;
    medicalSummaryType: string;
}

export const initialState: ClinicalCoordinatorState = {
    errorMessage: null,
    totalsLoading: true,
    totals: null,
    medicalSummaryIntake: null,
    medicalSummaryLockStatus: null,
    medicalSummaryIsLocked: false,
    medicalSummaryIntakes: [],
    facesheets: [],
    annualWellnessVisits: [],
    awvQueueItem: null,
    badgeData: null,
    badgesData: [],
    nextMedicalSummaryIntakeId: 0,
    medicalSummaryType: null,
};
