import { useState, useEffect } from 'react';
import { CensusDto, FacilityDto, NoteFaxRequestDto } from '@medone/medonehp-api-client';
import { Button, Form, Input, Modal } from 'antd';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import { selectFacilities } from '../../admin/slice.facilities';
import { faxNote } from '../slice.notes';
import { filterOptions } from '../../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';

import CustomSelect, { getCustomSelectListOptions } from '../../../../../shared/common/components/CustomSelect';

type Props = {
    census?: CensusDto;
    facilityId?: number;
    noteId?: number;
    quickNoteId?: number;
};

const FaxForm = ({ census, facilityId, noteId, quickNoteId }: Props) => {
    const dispatch = useAppDispatch();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const facilities = useAppSelector<FacilityDto[]>(selectFacilities);
    const [form] = Form.useForm();
    const buttonTitle = noteId ? 'Fax Note' : 'Fax';
    const modelTitle = noteId ? 'Fax Note' : 'Fax Quick Note';

    const handleAddFacility = (value) => {
        if (value) {
            const facility = facilities?.find((x) => x.id === value);

            if (facility) {
                form.setFieldsValue({ faxNumbers: facility.mainFaxNumber });
            }
        } else {
            form.setFieldsValue({ faxNumbers: '' });
        }
    };

    const handleFaxNote = async () => {
        setIsProcessing(true);

        try {
            const values = await form.validateFields();
            const { recipientName, faxNumbers, subject } = values;

            const newValues = {
                noteId,
                quickNoteId,
                recipientName,
                faxNumbers,
                subject,
            } as NoteFaxRequestDto;

            const result = await dispatch(faxNote(newValues));

            if (result) {
                toast.success(noteId ? 'Note Faxed!' : 'Quick Note Faxed!');

                form.resetFields();

                setIsVisible(false);
            }
        } catch (e) {
            console.error(e);
        }

        setIsProcessing(false);
    };

    useEffect(() => {
        // Coming from note
        if (census) {
            form.setFieldsValue({ locations: census.admittedToId });

            handleAddFacility(census.admittedToId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [census]);

    useEffect(() => {
        // Coming from quicknote
        if (facilityId) {
            form.setFieldsValue({ locations: facilityId });

            handleAddFacility(facilityId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facilityId]);

    return (
        <>
            <Button type="primary" ghost={noteId != null} onClick={() => setIsVisible(true)}>
                {buttonTitle}
            </Button>

            <Modal title={modelTitle} open={isVisible} onCancel={() => setIsVisible(false)} onOk={handleFaxNote} confirmLoading={isProcessing} zIndex={1001} okText="Send">
                <Form form={form} layout="vertical" initialValues={{ subject: 'Per your request' }}>
                    <Form.Item label="Locations" name="locations">
                        <CustomSelect
                            showSearch
                            filterOption={filterOptions}
                            options={getCustomSelectListOptions(facilities, 'name')}
                            onChange={(value) => handleAddFacility(value)}
                        />
                    </Form.Item>

                    <Form.Item label="Fax Number" name="faxNumbers" rules={[{ required: true, message: 'Fax Number is required' }]}>
                        <InputMask mask="(999) 999-9999" className="ant-input" />
                    </Form.Item>

                    <Form.Item label="Attention" name="recipientName" rules={[{ required: true, max: 40, message: 'Recipient is required and must not exceed 40 characters' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Note" name="subject" rules={[{ required: false, max: 55, message: 'Subject is required and must not exceed 55 characters' }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default FaxForm;
