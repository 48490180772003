import React from 'react';
import { Table } from 'antd';
import { PccContactDto } from '@medone/medonehp-api-client';

const { Column } = Table;

type Props = {
    contacts: PccContactDto[];
};

const ContactsTable = ({ contacts }: Props) => {
    return (
        <Table rowKey={(x) => `${x.name}-${x.relationship}-${x.contactType}`} pagination={false} dataSource={contacts} scroll={{ x: true }}>
            <Column<PccContactDto> title="Name" dataIndex="name" key="name" />
            <Column<PccContactDto> title="Phone" dataIndex="phone" key="phone" />
            <Column<PccContactDto> title="Relationship" dataIndex="relationship" key="relationship" />
            <Column<PccContactDto> title="Type" dataIndex="contactType" key="contactType" />
        </Table>
    );
};

export default ContactsTable;
