import { useEffect } from 'react';
import { PatientAddress, PccPatientDataDto, PccPatientDto } from '@medone/medonehp-api-client';
import { Alert, Collapse, Spin, Descriptions } from 'antd';
import { startCase } from 'lodash';
import moment from 'moment';

import { customExpandIcon } from '../../../../../../../shared/common/helpers/collapse';
import { selectError, setPccPatientLoading } from '../../../slice';
import { fetchPccPatient, selectPccPatient, selectPccPatientLoading } from '../../../slice.patients';
import { useAppSelector, useAppDispatch } from '../../../../../../../shared/hooks';
import { formatDateTime, formatPccGender, valueOrDefault } from '../../../../../../../shared/common/helpers';

import ContactsTable from './contacts-table';
import PccVitals from './pcc-vitals';
import MedicationList from '../../charts/medication-list';
import AllergiesTable from './allergies-table';
import AdvancedDirectivesTable from './advanced-directives-table';
import ImmunizationsTable from './immunizations-table';
import NutritionOrders from './nutrition-orders';
import Conditions from './conditions';
import PccProgressNotesTable from './pcc-progress-notes-table';
import PccErrors from '../../../../components/PccErrors';
import Practitioners from './practitioners';

import anonymousImage from '../../../../../../../shared/images/anonymous-avatar.jpg';
import { PccPatientLoadingState } from '../../../models';

type Params = {
    patientIntakeId?: number;
    alwaysShowData?: boolean;
    hidePatientDetails?: boolean;
};

const { Panel } = Collapse;
const ignoreFields = ['id', 'orgUuid', 'admissionDateTime', 'patientIntakeId', 'isPatientPccLinked'];

const renderPccPatientAddress = (value?: PatientAddress) => {
    return (
        value && (
            <>
                {value.addressLine1}
                <br />
                {value.addressLine2 != null && (
                    <>
                        {value.addressLine2}
                        <br />
                    </>
                )}
                {value.city}, {value.state} {value.postalCode}
            </>
        )
    );
};

const PccData = ({ patientIntakeId = null, alwaysShowData = false, hidePatientDetails = false }: Params) => {
    const dispatch = useAppDispatch();
    const errorMessage = useAppSelector<string>(selectError);
    const pccPatientLoading = useAppSelector<PccPatientLoadingState>(selectPccPatientLoading);
    const pccPatient = useAppSelector<PccPatientDataDto>(selectPccPatient);
    const loadingMessage = pccPatientLoading && pccPatientLoading.loading && patientIntakeId ? 'Loading...' : 'PointClickCare data not currently linked';

    useEffect(() => {
        // If being called from the note panel, pccPatient is already loaded in
        // so we don't need to request it again
        if (patientIntakeId != null) {
            dispatch(fetchPccPatient(patientIntakeId));
        } else if (patientIntakeId == null) {
            dispatch(setPccPatientLoading(null));
        }
    }, [dispatch, patientIntakeId]);

    const renderValue = (item: any) => {
        const label = item[0];
        const value = item[1];

        if (value != null) {
            if (moment.isMoment(value)) {
                return formatDateTime(value, '');
            } else if (value instanceof PatientAddress) {
                return renderPccPatientAddress(value);
            } else {
                if (label === 'gender') {
                    return formatPccGender(value);
                }

                if (typeof value === 'boolean') {
                    return value ? 'Yes' : 'No';
                }

                return valueOrDefault(value, '');
            }
        }

        return '';
    };

    const renderPccPatientData = (data: PccPatientDto) => {
        return (
            <>
                <PccErrors errorMessages={data.errorMessages} />

                {!hidePatientDetails && (
                    <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                        <Panel key="Patient Details" header="Patient Details">
                            <Descriptions title="" bordered column={{ xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
                                {pccPatient &&
                                    Object.entries(pccPatient.pccCensus)
                                        .filter((item) => !ignoreFields.includes(item[0]))
                                        .map((item) => (
                                            <Descriptions.Item key={item[0]} label={`${startCase(item[0])}`}>
                                                {renderValue(item)}
                                            </Descriptions.Item>
                                        ))}
                            </Descriptions>
                        </Panel>
                    </Collapse>
                )}

                <Collapse accordion defaultActiveKey="Patient Photo" expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Patient Photo" header="Patient Photo">
                        <img alt="Patient" className="pcc-patient-photo" src={data?.photo ? `data:image/jpeg;base64,${data?.photo}` : anonymousImage} />
                    </Panel>
                </Collapse>

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Medications" header="Medications">
                        <div className="p-1">
                            <MedicationList medications={data?.medications} />
                        </div>
                    </Panel>
                </Collapse>

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Allergies" header="Allergies">
                        <AllergiesTable allergies={data?.allergies} />
                    </Panel>
                </Collapse>

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Advanced Directives" header="Advanced Directives">
                        <AdvancedDirectivesTable advancedDirectives={data?.advancedDirectives} />
                    </Panel>
                </Collapse>

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Contacts" header="Contacts">
                        <ContactsTable contacts={data?.contacts} />
                    </Panel>
                </Collapse>

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Vitals" header="Vitals">
                        <PccVitals pccVitals={data?.vitals} />
                    </Panel>
                </Collapse>

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Immunizations" header="Immunizations">
                        <ImmunizationsTable immunizations={data?.immunizations} />
                    </Panel>
                </Collapse>

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Nutrition Orders" header="Nutrition Orders">
                        <NutritionOrders nutritionOrders={data?.nutritionOrders} />
                    </Panel>
                </Collapse>

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Conditions" header="Conditions">
                        <Conditions conditions={data?.conditions} />
                    </Panel>
                </Collapse>

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Progress Notes" header="Progress Notes">
                        <PccProgressNotesTable orgUuid={pccPatient?.pccCensus?.orgUuid} pccProgressNotes={data?.progressNotes} />
                    </Panel>
                </Collapse>

                {/* {isActivitiesOfDailyLivingEnabled && (
                    <>
                        <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                            <Panel key="ActivitiesOfDailyLiving" header="Activities of Daily Living">
                                <ActivitiesOfDailyLiving activitiesOfDailyLivings={data?.activitiesOfDailyLivings} />
                            </Panel>
                        </Collapse>

                        <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                            <Panel key="ActivitiesOfDailyLivingScores" header="Activities of Daily Living Scores">
                                <ActivitiesOfDailyLivingScores activitiesOfDailyLivingScores={data?.activitiesOfDailyLivingScores} />
                            </Panel>
                        </Collapse>
                    </>
                )} */}

                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    <Panel key="Practitioners" header="Practitioners">
                        <Practitioners practitioners={data?.practitioners} />
                    </Panel>
                </Collapse>
            </>
        );
    };

    if (errorMessage) {
        return <Alert message={errorMessage} type="error" />;
    }

    return (
        <Spin size="large" spinning={pccPatientLoading?.loading === true}>
            {/* If alwaysShowData, we want to see PCC data regardless of integration or linkage.
            We have to check for pccPatient?.pccPatientData as it might not be loaded at the time of render. */}
            {(!pccPatientLoading || !pccPatientLoading.loading) &&
            ((alwaysShowData && pccPatient?.pccPatientData) || (pccPatient?.isFacilityPccIntegrated && pccPatient?.isPatientPccLinked)) ? (
                renderPccPatientData(pccPatient.pccPatientData)
            ) : (
                <>
                    <Alert message={loadingMessage} type="info" showIcon />
                </>
            )}
        </Spin>
    );
};

export default PccData;
