import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Popconfirm, Tag, Tooltip, Spin, Select } from 'antd';
import { RouteBuilderFiltersDto, RegionDto, RouteBuilderPublishRequestDto, FacilityDto } from '@medone/medonehp-api-client';
import { toast } from 'react-toastify';
import moment from 'moment';

import { filterOptions, filterOptionsStartsWith, getSelectListOptions } from '../../../../../shared/common/helpers';
import { selectRegions } from '../../admin/slice.regions';
import { publishSchedule } from '../slice.schedules';
import { fetchRouteBuilder, getFilter, selectRouteBuilderFilters, selectRouteBuilderTotals } from '../slice';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { handleError } from '../../../../../shared/common/HandleErrors';
import { RouteBuilderTotalsDto } from '../models';

import CustomDatePicker from '../../../../../shared/common/components/CustomDatePicker';
import CustomSelect, { getCustomSelectListOptions } from '../../../../../shared/common/components/CustomSelect';
import { selectActiveFacilities } from '../../admin/slice.facilities';
import useDebounce from '../../../../../shared/hooks/useDebounce';

type Props = {
    scheduleDate: moment.Moment;
    currentRegion: number;
    assignInProgress: boolean;
    setCurrentRegion: (regionId: number) => void;
    setScheduleDate: (date: moment.Moment) => void;
};

const Filters = ({ currentRegion, scheduleDate, assignInProgress, setCurrentRegion, setScheduleDate }: Props) => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector<RouteBuilderFiltersDto>(selectRouteBuilderFilters);
    const regions = useAppSelector<RegionDto[]>(selectRegions);
    const facilities = useAppSelector<FacilityDto[]>(selectActiveFacilities);
    const [locationOptions, setLocationOptions] = useState<any[]>();
    const totals = useAppSelector<RouteBuilderTotalsDto>(selectRouteBuilderTotals);
    const [processing, setProcessing] = useState<boolean>(false);
    const [form] = Form.useForm();

    const handleValuesChange = async () => {
        const allValues = form.getFieldsValue(true);

        const filterValues = RouteBuilderFiltersDto.fromJS({
            ...getFilter(filters),
            ...allValues,
        });

        await dispatch(fetchRouteBuilder(filterValues, false));
    };

    const debouncedChange = useDebounce(handleValuesChange, 1500);

    const updateLocations = () => {
        const regionId = form.getFieldValue('regionId');

        if (regionId != null && facilities?.length > 0) {
            const facilitiesInRegion = (facilities || []).filter((facility) => regionId === facility.regionId);

            setLocationOptions(getSelectListOptions(facilitiesInRegion, 'name'));

            const selectedLocations = form.getFieldValue('facilityIds');
            const selectedLocationsInRegion = (selectedLocations || []).filter((sl) => (facilitiesInRegion || []).some((fir) => fir.id === sl));

            form.setFieldsValue({ facilityIds: selectedLocationsInRegion });
        } else {
            const options = getSelectListOptions(facilities, 'name');

            setLocationOptions(options);
        }
    };

    const handleRegionChange = (value) => {
        updateLocations();

        setCurrentRegion(value);
    };

    const handleDateChange = (evt) => {
        setScheduleDate(moment(evt.target.value));
    };

    const handlePublishSchedules = async () => {
        setProcessing(true);

        try {
            await form.validateFields();

            const region = regions.find((x) => x.id === currentRegion);
            const patchedValues = RouteBuilderPublishRequestDto.fromJS({ scheduleDate, regionId: currentRegion });
            const result = await dispatch(publishSchedule(patchedValues));

            if (result) {
                toast.success(`The schedule for region '${region.regionName}' has been published for ${moment(scheduleDate).format('L')}.`);
            } else {
                toast.error(`The schedule for region '${region.regionName}' failed to publish.`);
            }
        } catch (errors) {
            handleError(errors, () => true);
        }

        setProcessing(false);
    };

    useEffect(() => {
        form.setFieldsValue(filters);
    }, [form, filters]);

    useEffect(() => {
        const options = getSelectListOptions(facilities, 'name');

        setLocationOptions(options);
    }, [facilities]);

    return (
        <Card bordered={false} className="filters">
            <Form form={form} layout="vertical" name="census-filters" initialValues={filters} onValuesChange={debouncedChange}>
                <Row gutter={12} justify="center" align="middle" className="census-filters">
                    <Col lg={4} xs={24}>
                        <Form.Item label="Regions" name="regionId" className="mb-0 mr-2">
                            <CustomSelect
                                options={getCustomSelectListOptions(regions, 'regionName')}
                                showSearch
                                filterOption={filterOptionsStartsWith}
                                onChange={handleRegionChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={4} xs={24}>
                        <Form.Item label="Locations" name="facilityIds" className="mb-0 mr-2">
                            <Select mode="multiple" options={locationOptions} showSearch filterOption={filterOptions} dropdownStyle={{ width: '633px', minWidth: '633px' }} />
                        </Form.Item>
                    </Col>
                    <Col lg={8} xs={24} className="region-totals">
                        <Tooltip title="Number of Admissions H&Ps needed">
                            <Tag color="#6C8095">New Admits: {totals?.newAdmits || 0}</Tag>
                        </Tooltip>

                        <Tooltip title="MD Visits needed">
                            <Tag color="#6C8095">MD: {totals?.physicianFollowUps || 0}</Tag>
                        </Tooltip>

                        <Tooltip title="MD/APP Visits needed">
                            <Tag color="#6C8095">MD/APP: {totals?.physicianOrAppFollowUps || 0}</Tag>
                        </Tooltip>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Row className="mt-1 publish" align="bottom" justify="end">
                            <Spin spinning={processing || assignInProgress}>
                                <CustomDatePicker value={scheduleDate} onChange={handleDateChange} style={{ width: 'fit-content' }} />

                                <Popconfirm
                                    title="Are you sure you want to publish this schedule?"
                                    onConfirm={handlePublishSchedules}
                                    okText="Yes, Publish"
                                    cancelText="No"
                                    placement="left"
                                >
                                    <Button className="ml-2" type="primary">
                                        Publish
                                    </Button>
                                </Popconfirm>
                            </Spin>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default Filters;
