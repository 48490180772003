import { useState } from 'react';
import { Modal, Descriptions } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { EnumSelectListDto, FacilityDto } from '@medone/medonehp-api-client';

import { useAppSelector } from '../../../../../shared/hooks';
import { selectStates, selectFacilityTypes, selectSpecialties } from '../../../../../shared/common/data/slice';
import { getEnumDisplayValue, isNullOrEmpty, valueOrDefault } from '../../../../../shared/common/helpers';

type Props = {
    facility: FacilityDto;
};

const FacilityInfo = ({ facility }: Props) => {
    const states = useAppSelector<EnumSelectListDto[]>(selectStates);
    const facilityTypes = useAppSelector<EnumSelectListDto[]>(selectFacilityTypes);
    const specialties = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const state = getEnumDisplayValue(states, facility.state);

    const toggleModal = (evt: React.SyntheticEvent, value: boolean) => {
        evt.stopPropagation();

        setIsVisible(value);
    };

    return (
        <div className="facility-info-modal-container">
            <InfoCircleOutlined onClick={(evt) => toggleModal(evt, true)} />

            <div onClick={(evt) => evt.stopPropagation()}>
                <Modal title={facility.name} open={isVisible} footer={null} onCancel={(evt) => toggleModal(evt, false)} className="facility-info-modal">
                    <Descriptions layout="vertical" bordered className="descriptions" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                        <Descriptions.Item label="Address">
                            <address className="mb-0">
                                {facility.addressLine1}
                                <br />
                                {facility.addressLine2 && (
                                    <>
                                        {facility.addressLine2}
                                        <br />
                                    </>
                                )}
                                {facility.city}, {state} {facility.zipCode}
                            </address>
                        </Descriptions.Item>

                        <Descriptions.Item label="Phone Number">
                            {isNullOrEmpty(facility.medOneAssignedPhoneNumber) ? (
                                'Not Set'
                            ) : (
                                <a href={`tel:${facility.medOneAssignedPhoneNumber}`}>{facility.medOneAssignedPhoneNumber}</a>
                            )}
                        </Descriptions.Item>

                        <Descriptions.Item label="Fax Number">
                            {isNullOrEmpty(facility.mainFaxNumber) ? 'Not Set' : <a href={`tel:${facility.mainFaxNumber}`}>{facility.mainFaxNumber}</a>}
                        </Descriptions.Item>

                        <Descriptions.Item label="MedOne Facility Call Number">
                            {isNullOrEmpty(facility.medOneFacilityCallNumber) ? (
                                'Not Set'
                            ) : (
                                <a href={`tel:${facility.medOneFacilityCallNumber}`}>{facility.medOneFacilityCallNumber}</a>
                            )}
                        </Descriptions.Item>

                        <Descriptions.Item label="Pharmacy">{valueOrDefault(facility.pharmacy, 'Not Set')}</Descriptions.Item>

                        <Descriptions.Item label="Rounding Physician">{valueOrDefault(facility.roundingProviderName, 'Not Set')}</Descriptions.Item>

                        <Descriptions.Item label="Rounding APP">{valueOrDefault(facility.roundingAppName, 'Not Set')}</Descriptions.Item>

                        <Descriptions.Item label="Rounding Wound Provider (s)">{valueOrDefault(facility.roundingWoundProviderName, 'Not Set')}</Descriptions.Item>

                        <Descriptions.Item label="Type(s)">
                            {(facility.facilityTypes || []).map((facilityType) => (
                                <div key={facilityType}>{getEnumDisplayValue(facilityTypes, facilityType)}</div>
                            ))}
                        </Descriptions.Item>

                        <Descriptions.Item label="Specialties">
                            {(facility.specialties || []).map((specialty) => (
                                <div key={specialty}>{getEnumDisplayValue(specialties, specialty)}</div>
                            ))}
                        </Descriptions.Item>

                        <Descriptions.Item label="MedOne is Medical Director?">{facility.medOneIsMedicalDirector ? 'Yes' : 'No'}</Descriptions.Item>

                        <Descriptions.Item label="NPI">{valueOrDefault(facility.npi, 'Not Set')}</Descriptions.Item>

                        <Descriptions.Item label="Corporation">{valueOrDefault(facility.corporation, 'Not Set')}</Descriptions.Item>

                        <Descriptions.Item label="Additional Info">{valueOrDefault(facility.additionalInfo, 'Not Set')}</Descriptions.Item>
                    </Descriptions>
                </Modal>
            </div>
        </div>
    );
};

export default FacilityInfo;
