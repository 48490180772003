import { useAppSelector } from '../../../../../shared/hooks';
import Badges from '../../components/badges';
import { routeBuilderBadgeSelectors } from '../slice';

type Props = {
    patientIntakeId: number;
    patientTypeIds: string;
    canReturnFromED: boolean;
};

const RouteBuilderBadges = ({ patientIntakeId, patientTypeIds, canReturnFromED }: Props) => {
    const routeBuilderBadge = useAppSelector((state) => routeBuilderBadgeSelectors.selectById(state.routeBuilder.badges, patientIntakeId));

    return <Badges censusBadge={routeBuilderBadge} patientTypeIds={patientTypeIds} canReturnFromED={canReturnFromED} />;
};

export default RouteBuilderBadges;
