import { useEffect, useState } from 'react';
import { PageHeader, Skeleton } from 'antd';
import { RouteBuilderFiltersDto } from '@medone/medonehp-api-client';
import moment from 'moment';

import { fetchRouteBuilder, selectLoading, selectRouteBuilderFilters } from './slice';
import { useAppSelector, useAppDispatch } from '../../../../shared/hooks';

import Results from './components/results';
import Filters from './components/filters';
import { getSchedules } from './slice.schedules';

const Index = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector<boolean>(selectLoading);
    const filters = useAppSelector<RouteBuilderFiltersDto>(selectRouteBuilderFilters);
    const [currentRegion, setCurrentRegion] = useState<number>();
    const [scheduleDate, setScheduleDate] = useState<moment.Moment>(moment());
    const [assignInProgress, setAssignInProgress] = useState<boolean>(false);

    const getRouteBuilder = async () => {
        await dispatch(fetchRouteBuilder(filters));
    };

    useEffect(() => {
        getRouteBuilder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filters != null) {
            setCurrentRegion(filters.regionId);
        }
    }, [filters]);

    useEffect(() => {
        if (scheduleDate && currentRegion) {
            // Only load schedules once we have both a date and region
            dispatch(getSchedules(scheduleDate, currentRegion));
        }
    }, [dispatch, scheduleDate, currentRegion]);

    return (
        <div className="route-builder-app">
            <PageHeader title="Route Builder" />

            <Filters
                currentRegion={currentRegion}
                scheduleDate={scheduleDate}
                setScheduleDate={setScheduleDate}
                setCurrentRegion={setCurrentRegion}
                assignInProgress={assignInProgress}
            />

            <Skeleton active loading={loading}>
                <Results currentRegion={currentRegion} scheduleDate={scheduleDate} assignInProgress={assignInProgress} setAssignInProgress={setAssignInProgress} />
            </Skeleton>
        </div>
    );
};

export default Index;
