import { useEffect, useState } from 'react';
import { Alert, Col, Row, Skeleton } from 'antd';
import { ReturnToHospitalConfigurationDto, ReturnToHospitalSummaryDto } from '@medone/medonehp-api-client';
import { useParams } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { fetchReturnToHospitalsByPatientId, selectReturnToHospitals } from '../../slice.patient-return-to-hospital';
import { fetchReturnToHospitalConfigurations, selectReturnToHospitalConfigurations } from '../../../admin/slice.return-to-hospital-config';
import { formatDateTime } from '../../../../../../shared/common/helpers';

import logo from '../../../../../../shared/images/print-logo.jpg';
import ReturnToHospitalOutput from '../../../return-to-hospital/output';

type Params = { id: string; patientId: string };

const PrintRtH = () => {
    const params = useParams<Params>();
    const { id, patientId } = params;
    const dispatch = useAppDispatch();
    const returnToHospitalConfigurations = useAppSelector<ReturnToHospitalConfigurationDto[]>(selectReturnToHospitalConfigurations);
    const returnToHospitals = useAppSelector<ReturnToHospitalSummaryDto[]>(selectReturnToHospitals);
    const [summary, setSummary] = useState<ReturnToHospitalSummaryDto>();
    const selectedValues = JSON.parse(summary?.valuesJson ?? '[]') as number[];

    const renderRtH = () => {
        if (summary) {
            return (
                <>
                    <Row gutter={0} className="print-logo mb-2">
                        <Col span={24}>
                            <img src={logo} alt="Med One Logo" />
                        </Col>
                    </Row>

                    <Row className="mt-2 mb-2">
                        <Col span={24}>
                            {summary.patientName}, {summary.patientGender}, {formatDateTime(summary.patientDoB, '', 'L', summary.patientAge)}
                        </Col>
                    </Row>

                    <Row gutter={0}>
                        <Col span={6}>Discharge Date</Col>
                        <Col span={18}>{formatDateTime(summary.dischargeDate)}</Col>
                    </Row>

                    <Row gutter={0}>
                        <Col span={6}>Facility</Col>
                        <Col span={18}>{summary.facilityName}</Col>
                    </Row>

                    <Row gutter={0}>
                        <Col span={6}>Arriving From</Col>
                        <Col span={18}>{summary.arrivingFromName}</Col>
                    </Row>

                    <Row gutter={0}>
                        <Col span={6}>Discharge Location</Col>
                        <Col span={18}>{summary.dischargeLocationName}</Col>
                    </Row>

                    <Row gutter={0}>
                        <Col span={6}>Completed By</Col>
                        <Col span={18}>{summary.completedByName}</Col>
                    </Row>

                    <Row gutter={0} className="mt-2">
                        <Col span={6}>Selections</Col>
                        <Col span={18}>
                            <ReturnToHospitalOutput returnToHospitalConfigurations={returnToHospitalConfigurations} selectedValues={selectedValues} />
                        </Col>
                    </Row>

                    {renderFeedback()}
                </>
            );
        }

        return <Alert type="warning" message="Unable to load return to hospital data." />;
    };

    const renderFeedback = () => {
        if (summary.feedback) {
            return (
                <Row gutter={0} className="mt-2">
                    <Col span={6}>Physician Feedback</Col>
                    <Col span={18}>{summary.feedback}</Col>
                </Row>
            );
        }

        return null;
    };

    useEffect(() => {
        if (patientId) {
            dispatch(fetchReturnToHospitalConfigurations());
            dispatch(fetchReturnToHospitalsByPatientId(parseInt(patientId)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    useEffect(() => {
        if (id) {
            const selected = returnToHospitals.find((x) => x.id === parseInt(id));

            setSummary(selected);

            if (selected) {
                setTimeout(() => window.print(), 1500);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnToHospitals]);

    return (
        <Skeleton active loading={!summary}>
            {renderRtH()}
        </Skeleton>
    );
};

export default PrintRtH;
