import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { PageHeader, Layout, Table, Button, Tooltip, Badge } from 'antd';
import { PatientIntakeDto, ReturnToHospitalEligibleIntakeDto } from '@medone/medonehp-api-client';
import { UnorderedListOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { formatDateTime } from '../../../../shared/common/helpers';
import { fetchEligibleReturnToHospitals, selectEligibleIntakes } from './slice';
import { PostAcutePaths } from '../index.routes';
import { fetchCensusByPatientIntakeId } from '../census/slice';

const ReturnToHospital = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState<boolean>(true);
    const eligibleIntakes = useAppSelector<PatientIntakeDto[]>(selectEligibleIntakes);

    const initData = async () => {
        await dispatch(fetchEligibleReturnToHospitals());

        setLoading(false);
    };

    const renderPatientName = (data: ReturnToHospitalEligibleIntakeDto) => {
        return (
            <Button type="link" onClick={() => openNote(data)}>
                {data.patientName} {data.returnToHospitalId != null && <Badge color="gold" offset={[5, 0]} />}
            </Button>
        );
    };

    const renderFormButton = (data: ReturnToHospitalEligibleIntakeDto) => {
        return (
            <Tooltip title="Open Form">
                <Button type="text" icon={<UnorderedListOutlined />} size="small" onClick={() => openForm(data)} />
            </Tooltip>
        );
    };

    const openNote = (intake: ReturnToHospitalEligibleIntakeDto) => {
        dispatch(fetchCensusByPatientIntakeId(intake.patientIntakeId, true));
    };

    const openForm = (intake: ReturnToHospitalEligibleIntakeDto) => {
        history.push(`${PostAcutePaths.ReturnToHospital.Form}/${intake.patientIntakeId}/${intake.returnToHospitalId}`);
    };

    useEffect(() => {
        initData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PageHeader title="Return to Hospital" />

            <Layout.Content className="p-2">
                <Table rowKey={(x) => x.id} dataSource={eligibleIntakes} loading={isLoading}>
                    <Table.Column<PatientIntakeDto> title="Patient Name" render={(data) => renderPatientName(data)} />
                    <Table.Column<PatientIntakeDto> title="DOB (Age)" render={(data) => formatDateTime(data.dateOfBirth, '', 'L', data.age)} />
                    <Table.Column<PatientIntakeDto> title="Facility" dataIndex="facilityName" />
                    <Table.Column<PatientIntakeDto> title="Discharged By" dataIndex="dischargedByUserName" />
                    <Table.Column<PatientIntakeDto> title="Discharge Location" dataIndex="dischargeLocation" />
                    <Table.Column<PatientIntakeDto> title="Discharge Date" dataIndex="dischargeDate" render={(date) => formatDateTime(date)} />
                    <Table.Column<PatientIntakeDto> title="" render={(data) => renderFormButton(data)} />
                </Table>
            </Layout.Content>
        </>
    );
};

export default ReturnToHospital;
