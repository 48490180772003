import { DataListDto, FeatureDto } from '@medone/medonehp-api-client';

export interface DataState {
    loading: boolean;
    appError?: string;
    items?: DataListDto;
    featureFlags: FeatureDto[];
}

export const initialState: DataState = {
    loading: true,
    appError: null,
    items: null,
    featureFlags: [],
};
