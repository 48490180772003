import { PayloadAction } from '@reduxjs/toolkit';
import { PatientLabsClient, PatientLabDto, PatientLabRecentItemDto, PatientLabRecentCategoryDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { CensusState } from './models';
import { censusSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { fetchPatient } from './slice.patients';

export const reducers = {
    setPatientLabProcessing: (state: CensusState, action: PayloadAction<boolean>) => {
        state.patientLabProcessing = action.payload;
    },

    setPatientLabs: (state: CensusState, action: PayloadAction<PatientLabDto[]>) => {
        if (state.patient != null) {
            state.patient.patientLabs = action.payload;
        }
    },

    setRecentLabs: (state: CensusState, action: PayloadAction<PatientLabRecentCategoryDto[]>) => {
        if (state.patientDrawerOpen || state.currentNote != null || state.currentStagedNote != null) {
            state.recentLabs = action.payload;
            state.recentLabsLoading = false;
        }
    },

    setRecentLabsLoading: (state: CensusState, action: PayloadAction<boolean>) => {
        state.recentLabsLoading = action.payload;
    },

    setRecentLabTestDetails: (state: CensusState, action: PayloadAction<PatientLabRecentItemDto[]>) => {
        state.recentLabTestDetails = action.payload;
    },
};

export function addPatientLab(dto: PatientLabDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setPatientLabProcessing(true));

        
        const client = new PatientLabsClient(null, Axios);

        try {
            const response = await client.post(dto);

            if (response.result.succeeded) {
                // Reload the patient and their labs
                dispatch(fetchPatient(dto.patientId));
                dispatch(fetchRecentLabResults(dto.patientId));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            dispatch(censusSlice.actions.setPatientLabProcessing(false));

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function deletePatientLab(labId: number, patientId: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setPatientLabProcessing(true));

        
        const client = new PatientLabsClient(null, Axios);

        try {
            const response = await client.delete(labId);

            if (response.result.succeeded) {
                await dispatch(fetchPatient(patientId));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            dispatch(censusSlice.actions.setPatientLabProcessing(false));

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        dispatch(censusSlice.actions.setPatientLabProcessing(false));

        return false;
    };
}

export function fetchRecentLabResults(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setRecentLabsLoading(true));

        
        const client = new PatientLabsClient(null, Axios);

        try {
            const response = await client.getRecentByCategory(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setRecentLabs(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        dispatch(censusSlice.actions.setRecentLabsLoading(false));
    };
}

export function fetchRecentLabTestDetails(testName: string): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const { currentCensus } = getState().census;
        const client = new PatientLabsClient(null, Axios);

        try {
            const response = await client.getPastTwoYearsByTestType(currentCensus.patientId, testName);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setRecentLabTestDetails(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function getLatestHgbA1CPatientLab(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new PatientLabsClient(null, Axios);

        try {
            const response = await client.getLatestHgbA1CPatientLab(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setLatestHgbA1c(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function getLatestTshPatientLab(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new PatientLabsClient(null, Axios);

        try {
            const response = await client.getLatestTshPatientLab(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setLatestTsh(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export const selectPatientLabProcessing = (state: RootState) => state.census.patientLabProcessing;
export const selectRecentLabs = (state: RootState) => state.census.recentLabs;
export const selectRecentLabsLoading = (state: RootState) => state.census.recentLabsLoading;
export const selectRecentLabTestDetails = (state: RootState) => state.census.recentLabTestDetails;
