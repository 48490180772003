import { Collapse, Tooltip, Tag, Spin, Button, Row } from 'antd';
import {
    EnumSelectListDto,
    ScheduleGroupDto,
    ScheduleProviderGroupDto,
    UserDto,
    RouteBuilderProviderGroupCountsDto,
    RouteBuilderDto,
    RouteBuilderGroupDto,
} from '@medone/medonehp-api-client';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { customExpandIcon } from '../../../../../shared/common/helpers/collapse';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { selectUserSuffixes } from '../../../users/slice';
import { fetchBadgeData, selectProviderTotals } from '../slice';
import { getProviderFullName } from '../../../../../shared/common/helpers';

import ProviderLocationPatients from './provider-location-patients';

type Props = {
    selectedLocation: number;
    handlePostSchedule: (evt, providerId) => void;
    setSelectedLocation: (key) => void;
    provider: UserDto;
    schedule: ScheduleProviderGroupDto;
    processing: boolean;
    selectedRows: RouteBuilderDto[];
};

const ProviderPanel = ({ schedule, selectedRows, handlePostSchedule, selectedLocation, setSelectedLocation, provider, processing, ...props }: Props) => {
    const dispatch = useAppDispatch();
    const userSuffixes = useAppSelector<EnumSelectListDto[]>(selectUserSuffixes);
    const providerTotals = useAppSelector<RouteBuilderProviderGroupCountsDto[]>(selectProviderTotals);

    const renderHeader = (location: ScheduleGroupDto) => {
        return <div>{location.admittedTo}</div>;
    };

    const handleChangeLocation = async (key: string | any) => {
        if (key == null) {
            return;
        }

        setSelectedLocation(key);

        const keyParts = key.split('-');
        const admittedToId = parseInt(keyParts[keyParts.length - 1]); // Grab location id from the end of the key
        const location = schedule.items.find((x) => x.admittedToId === admittedToId);

        const routeBuilderGroup = RouteBuilderGroupDto.fromJS({
            admittedToId,
            patientIntakeIds: location.items.map((x) => x.patientIntakeId),
        });

        await dispatch(fetchBadgeData(routeBuilderGroup));
    };

    const renderAssignButton = () => {
        return (
            selectedRows.length > 0 && (
                <Button disabled={processing} type="primary" size="small" onClick={(evt) => handlePostSchedule(evt, provider.adSid)}>
                    Assign Patients
                </Button>
            )
        );
    };

    const renderProviderTitle = () => {
        const locationCount = schedule?.items.length;
        const totals = providerTotals.find((x) => x.userId === provider.adSid);
        let patientTotal = 0;

        if (schedule) {
            patientTotal = schedule.items.reduce((acc, curr) => acc + curr.items.length, patientTotal);
        }

        return (
            <Row align="middle" className="provider-header">
                <p className="mb-0">
                    {getProviderFullName(provider, userSuffixes)}
                    {patientTotal > 0 && (
                        <span className="ml-1">
                            <Tag color="#2ecc71">Assigned: {patientTotal}</Tag>
                        </span>
                    )}
                </p>

                <div className="provider-tags">
                    <Tag color="#6C8095" className="ml-1">
                        Facilities: {locationCount || 0}
                    </Tag>

                    <Tooltip title="Number of Admissions H&Ps needed">
                        <Tag color="#6c8095">New Admits: {totals?.newAdmits || 0}</Tag>
                    </Tooltip>

                    <Tooltip title="MD Visits needed">
                        <Tag color="#6c8095">MD: {totals?.physicianFollowUps || 0}</Tag>
                    </Tooltip>

                    <Tooltip title="MD/APP Visits needed">
                        <Tag color="#6c8095">MD/APP: {totals?.physicianOrAppFollowUps || 0}</Tag>
                    </Tooltip>
                </div>
            </Row>
        );
    };

    return (
        <Collapse.Panel {...props} className="mb-0 provider-container" key={provider.adSid} header={renderProviderTitle()} extra={renderAssignButton()}>
            <Spin spinning={processing}>
                <Collapse accordion activeKey={selectedLocation} expandIconPosition="end" expandIcon={customExpandIcon} onChange={(key) => handleChangeLocation(key)}>
                    {schedule ? (
                        schedule.items.map((location) => {
                            return (
                                <ProviderLocationPatients
                                    // NOTE: If you change this, make sure you also update handleChangeLocation key parsing
                                    key={`${schedule.providerId}-${location.admittedToId}`}
                                    handlePostSchedule={handlePostSchedule}
                                    header={renderHeader(location)}
                                    location={location}
                                    providerId={schedule.providerId}
                                />
                            );
                        })
                    ) : (
                        <div className="no-assigned">
                            <ExclamationCircleOutlined />
                            <p className="mb-0 ml-1">No assigned patients</p>
                        </div>
                    )}
                </Collapse>
            </Spin>
        </Collapse.Panel>
    );
};

export default ProviderPanel;
