import React from 'react';
import { Card, Form } from 'antd';
import moment from 'moment';
import { QuickNoteDto } from '@medone/medonehp-api-client';

import QuickNoteForm from '../notes/quick-note-form';

const PatientIntakeQuickNote = () => {
    const form = Form.useFormInstance();

    return (
        <Card size="small" type="inner">
            <QuickNoteForm
                form={form}
                formPrefix="newQuickNote"
                patientIntakeId={0}
                quickNote={QuickNoteDto.fromJS({ reminderDate: moment(), needsFollowUp: false, isDraft: false, specialties: [], patientId: 0 })}
                hideSpecialties
            />
        </Card>
    );
};

export default React.forwardRef(PatientIntakeQuickNote);
