import { PayloadAction } from '@reduxjs/toolkit';
import { PatientTypesClient, PatientTypeDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { AdminState } from './models';
import { adminSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';

export const reducers = {
    setPatientTypes: (state: AdminState, action: PayloadAction<PatientTypeDto[]>) => {
        state.patientTypes = action.payload;
        state.errorMessage = null;
    },

    setPatientType: (state: AdminState, action: PayloadAction<PatientTypeDto>) => {
        state.currentPatientType = action.payload;
        state.errorMessage = null;
    },

    syncPatientTypes: (state: AdminState, action: PayloadAction<PatientTypeDto>) => {
        let newItems = [...state.patientTypes];
        const index = state.patientTypes.findIndex((x) => x.id === action.payload.id);

        if (index !== -1) {
            newItems = newItems.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload } as PatientTypeDto;
                }

                return item;
            });
        } else {
            newItems.push(action.payload);
        }

        state.patientTypes = newItems;

        if (state.currentPatientType != null && state.currentPatientType.id === action.payload.id) {
            state.currentPatientType = action.payload;
        }
    },
};

export function fetchPatientTypes(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new PatientTypesClient(null, Axios);

        try {
            const response = await client.getAll();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setPatientTypes(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchPatientType(patientTypeId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new PatientTypesClient(null, Axios);

        try {
            const response = await client.getById(patientTypeId);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setPatientType(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function updatePatientType(patientType: PatientTypeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new PatientTypesClient(null, Axios);

        try {
            const response = await client.post(patientType);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setPatientType(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function syncPatientTypes(dto: PatientTypeDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(adminSlice.actions.syncPatientTypes(dto));
    };
}

export const selectPatientTypes = (state: RootState) => state.admin.patientTypes;
export const selectPatientType = (state: RootState) => state.admin.currentPatientType;
