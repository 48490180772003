import { PatientClient, PatientDto, PatientIntakeDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { censusSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { DuplicateMbiError, DuplicatePatientError } from '../../../../shared/common';
import { getCleanMedicalSummaryFields, trimSpacesFromStrings } from '../../../../shared/common/helpers';

export function updatePatient(patient: PatientDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new PatientClient(null, Axios);

        try {
            const response = await client.post(trimSpacesFromStrings(patient));

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatient(response.result.entity));
            } else {
                if (response.result.message === 'Duplicate patient detected') {
                    throw new DuplicatePatientError(response.result.message, response.result.entity);
                } else if (response.result.message.startsWith('Duplicate MBI')) {
                    throw new DuplicateMbiError(response.result.message, response.result.entity);
                }

                handleError(response, () => true);
            }

            return response.result.succeeded;
        } catch (error) {
            // Ensure this gets bubbled up to the top level so we can handle on the UI
            if (error instanceof DuplicatePatientError || error instanceof DuplicateMbiError) {
                throw error;
            }

            handleError(error, () => true);
        }

        return false;
    };
}

export function syncLatestMedicalSummaryToPatientChart(): AppThunk<Promise<PatientIntakeDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentCensus, currentPatientIntake } = getState().census;

        try {
            if (currentCensus != null && currentPatientIntake != null && currentCensus.patientIntakeId === currentPatientIntake.id) {
                const msValues = getCleanMedicalSummaryFields(currentPatientIntake?.medicalSummary);

                dispatch(censusSlice.actions.updateMedicalSummaryFields(msValues));
            }
        } catch (error) {
            handleError(error, () => true);
        }

        return null;
    };
}

export const selectPatient = (state: RootState) => state.census.patient;
