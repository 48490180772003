import {  PatientIntakeDto, ReturnToHospitalClient, ReturnToHospitalDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { handleError } from '../../../../shared/common/HandleErrors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, ReturnToHospitalState } from './models';
import { AnyProviderWithScribe, Role } from '../../../../shared/common/auth/RoleAuth';
import { isInRole } from '../../../../shared/common/helpers';

export const returnToHospitalSlice = createSlice({
    name: 'returnToHospital',
    initialState,
    reducers: {
        setEligibleIntakes: (state: ReturnToHospitalState, action: PayloadAction<PatientIntakeDto[]>) => {
            state.eligibleIntakes = action.payload;
        },

        setReturnToHospital: (state: ReturnToHospitalState, action: PayloadAction<ReturnToHospitalDto>) => {
            state.returnToHospital = action.payload;
        },
    },
});

export function fetchEligibleReturnToHospitals(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;
        const client = new ReturnToHospitalClient(null, Axios);

        if (!isInRole(permissions, [Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe]) || window.location.pathname !== '/postacute/return-to-hospital') {
            return;
        }

        try {
            const response = await client.getEligibleReturnToHospital();

            if (!response.result.succeeded) {
                handleError(response);
            } else {
                dispatch(returnToHospitalSlice.actions.setEligibleIntakes(response.result.entity));
            }
        } catch (error) {
            handleError(error);
        }
    };
}

export function updateReturnToHospital(returnToHospital: ReturnToHospitalDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new ReturnToHospitalClient(null, Axios);

        try {
            const response = await client.post(returnToHospital);

            if (!response.result.succeeded) {
                handleError(response);
            }
        } catch (error) {
            handleError(error);
        }
    };
}

export function fetchReturnToHospital(id: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new ReturnToHospitalClient(null, Axios);

        try {
            const response = await client.getById(id);

            if (response.result.succeeded) {
                dispatch(returnToHospitalSlice.actions.setReturnToHospital(response.result.entity));
            } else {
                handleError(response);
            }
        } catch (error) {
            handleError(error);
        }
    };
}

export const selectEligibleIntakes = (state: RootState) => state.returnToHospital.eligibleIntakes;
export const selectReturnToHospital = (state: RootState) => state.returnToHospital.returnToHospital;

export default returnToHospitalSlice.reducer;
