import { PccPractitionerDto } from '@medone/medonehp-api-client';
import { Table } from 'antd';

type Props = {
    practitioners: PccPractitionerDto[];
};

const Practitioners = ({ practitioners }: Props) => {
    return (
        <Table dataSource={practitioners} rowKey="id" className="conditions-table" scroll={{ x: true }} pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}>
            <Table.Column<PccPractitionerDto> title="First Name" dataIndex="firstName" />
            <Table.Column<PccPractitionerDto> title="Last Name" dataIndex="lastName" />
            <Table.Column<PccPractitionerDto> title="Profession" dataIndex="providerType" />
            <Table.Column<PccPractitionerDto> title="Relation" dataIndex="relation" />
            <Table.Column<PccPractitionerDto> title="Office Phone" dataIndex="officePhone" />
        </Table>
    );
};

export default Practitioners;
