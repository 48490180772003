import packageInfo from '../package.json';

export const ApiPaths = {
    Url: process.env.REACT_APP_API_URL,
};

export const CaptchaInfo = {
    SiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
};

export const BuildInfo = {
    IsPortal: process.env.REACT_APP_BUILD_TARGET === '' || process.env.REACT_APP_BUILD_TARGET === 'portal',
    IsBedBoard: process.env.REACT_APP_BUILD_TARGET === 'bedboard',
};

export const VersionInfo = {
    AppName: process.env.REACT_APP_NAME,
    AppVersion: process.env.REACT_APP_VERSION,
    PostAcuteClientVersion: packageInfo.dependencies['@medone/medonehp-api-client'],
    BedBoardClientVersion: packageInfo.dependencies['@medone/medonehp-bedboard-client'],
};

export const getPaginationConfig = (data) => {
    return {
        total: (data || []).length,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    };
};

export const SignalRDefaultDebounceTimeout = 1000;
export const SignalRDefaultDebounceOptions = { 'maxWait': 2000 };