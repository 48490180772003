import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Alert, Col, Row, Skeleton } from 'antd';
import { NoteCensusDto } from '@medone/medonehp-api-client';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Breaks from 'remark-breaks';

import { fetchNotePrint, selectNoteCensus, selectNotePrintContent } from '../../../slice.notes';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/hooks';

import logo from '../../../../../../../shared/images/print-logo.jpg';

type DetailsParams = { ids: string };

const Print = () => {
    const params = useParams<DetailsParams>();
    const dispatch = useAppDispatch();
    const noteCensus = useAppSelector<NoteCensusDto>(selectNoteCensus);
    const notePrintContents = useAppSelector<string[]>(selectNotePrintContent);
    const { ids } = params;
    const noteIds = ids.split('|').map((x) => parseInt(x));

    const printNote = () => {
        setTimeout(() => window.print(), 1500);
    };

    const renderPrintNote = () => {
        if (noteCensus && noteCensus.note) {
            const footerContent = `Name: ${noteCensus.census.name}, DOB: ${noteCensus.census.dateOfBirth.format('L')}, Date of Service: ${noteCensus.note.serviceDate.format('L')}`;

            return (
                <>
                    {notePrintContents.map((n) => (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <Row gutter={0} className="print-logo">
                                                <Col span={24}>
                                                    <img src={logo} alt="Med One Logo" />
                                                </Col>
                                            </Row>
                                            <div>&nbsp;</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="note-markdown-container">
                                                <ReactMarkdown children={n} remarkPlugins={[remarkGfm, Breaks]} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                            <div className="print-footer">{footerContent}</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </>
                    ))}
                </>
            );
        }

        return <Alert type="warning" message="Unable to load note data." />;
    };

    useEffect(() => {
        async function getNote() {
            await dispatch(fetchNotePrint(noteIds, false)); // Make sure we pass false here to log a manual print
        }

        if (noteCensus == null) {
            getNote();
        } else if (noteCensus && noteCensus.note && noteCensus.census) {
            printNote();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, ids, noteCensus]);

    return (
        <Skeleton active loading={!noteCensus}>
            <div className="print-note">{renderPrintNote()}</div>
        </Skeleton>
    );
};

export default Print;
