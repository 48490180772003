/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export const customExpandIcon = (props) => {
    if (props.isActive) {
        return (
            <a
                onClick={(e) => {
                    props.onItemClick(props.record, e);
                }}
            >
                Collapse
                <UpOutlined />
            </a>
        );
    } else {
        return (
            <a
                onClick={(e) => {
                    props.onItemClick(props.record, e);
                }}
            >
                Expand
                <DownOutlined />
            </a>
        );
    }
};
