import { EnumSelectListDto } from '@medone/medonehp-api-client';

import { selectRequiredVisitTypes } from '../../../../../../shared/common/data/slice';
import { getEnumDisplayValue } from '../../../../../../shared/common/helpers';
import { useAppSelector } from '../../../../../../shared/hooks';
import { visitDueSelectors } from '../../slice';

type Props = {
    patientIntakeId: number;
};

const ProviderTypeBadge = ({ patientIntakeId }: Props) => {
    const requiredVisitTypes = useAppSelector<EnumSelectListDto[]>(selectRequiredVisitTypes);
    const visitDue = useAppSelector((state) => visitDueSelectors.selectById(state.census.visitDues, patientIntakeId));

    return visitDue ? <>{getEnumDisplayValue(requiredVisitTypes, visitDue.providerVisitType)}</> : <></>;
};

export default ProviderTypeBadge;
