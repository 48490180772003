import { HubConnection } from '@microsoft/signalr';
import { AbandonedBadgeDto, ClinicalCoordinatorTotalDto, MedicalSummaryLockStatusDto, PatientDto, PatientIntakeDto } from '@medone/medonehp-api-client';

import { applyNextPatientIntakeLock, clinicalCoordinatorSlice, fetchTotals, setMedicalSummaryIntakes } from '../slice';
import { CcTotalUpdate } from '../models';
import { store } from '../../../../../shared/store';
import { fetchAbandonedPatients, abandonedSlice } from '../../abandoned/slice';
import { getLockExpirationData } from '../../../../../shared/common/helpers';
import { debounce } from 'lodash';
import { SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions } from '../../../../../Config';

const refreshInterval = 1000 * 60;
let refreshSetInterval = null;

const initRefreshInterval = () => {
    if (refreshSetInterval != null) {
        clearInterval(refreshSetInterval);
    }

    refreshSetInterval = setInterval(async () => {
        await store.dispatch(fetchTotals());
    }, refreshInterval);
};

export const initCCHubConnection = (connection: HubConnection) => {
    initRefreshInterval();

    connection.on('MedicalSummaryTotals', (dto: ClinicalCoordinatorTotalDto) => {
        const { account } = store.getState().auth;

        if (account?.localAccountId === dto.userId) {
            const data = { data: dto, field: 'medicalSummaryTotals' } as CcTotalUpdate;

            store.dispatch(clinicalCoordinatorSlice.actions.updateTotals(data));
        }
    });

    connection.on('FaceSheetTotals', (dto: ClinicalCoordinatorTotalDto) => {
        const { account } = store.getState().auth;

        if (account?.localAccountId === dto.userId) {
            const data = { data: dto, field: 'faceSheetTotals' } as CcTotalUpdate;

            store.dispatch(clinicalCoordinatorSlice.actions.updateTotals(data));
        }
    });

    connection.on('MedicalSummaryLockStatus', (dto: MedicalSummaryLockStatusDto) => {
        const data = MedicalSummaryLockStatusDto.fromJS(dto);

        store.dispatch(applyNextPatientIntakeLock(data));

        const lockExpirationData = getLockExpirationData(data);
        const { account } = store.getState().auth;
        const { medicalSummaryIntakes } = store.getState().clinicalCoordinator;

        // Handle removing someone from the medical summary table if they've been locked by another provider
        if (!lockExpirationData.expired && medicalSummaryIntakes != null && medicalSummaryIntakes.length > 0 && account?.localAccountId !== dto.lockedByUserId) {
            const newIntakes = [...medicalSummaryIntakes].filter((x) => x.id !== data.patientIntakeId);

            store.dispatch(setMedicalSummaryIntakes(newIntakes));
        }
    });

    connection.on('PatientIntakeAbandonedBadge', (dto: AbandonedBadgeDto) => {
        const { localAccountId } = store.getState().auth.account;

        if (localAccountId === dto.userId) {
            store.dispatch(abandonedSlice.actions.setTotalAbandoned(dto));

            store.dispatch(fetchAbandonedPatients());
        }
    });

    connection.on('PatientIntakeAbandoned', debounce(() => {
        store.dispatch(fetchAbandonedPatients());
    }, SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions));

    // connection.on('PatientNoteAdded', () => {
    //     store.dispatch(fetchTotals());
    // });

    // connection.on('PatientNoteDeleted', () => {
    //     store.dispatch(fetchTotals());
    // });

    // connection.on('MedicalSummaryCompleted', () => {
    //     store.dispatch(fetchTotals());
    // });

    connection.on('PatientAdded', async (dto: PatientDto) => {
        const { medicalSummaryIntake } = store.getState().clinicalCoordinator;
        const data = PatientDto.fromJS(dto);

        if (medicalSummaryIntake != null && medicalSummaryIntake.patientId === data.id) {
            const updatedPatientIntake = {
                ...medicalSummaryIntake,
                // Update patient information
                firstName: data.firstName,
                middleName: data.middleName,
                lastName: data.lastName,
                preferredName: data.preferredName,
                dateOfBirth: data.dateOfBirth,
                gender: data.gender,
                roomNumber: data.roomNumber,
                hospitalMRN: data.hospitalMRN,
            } as PatientIntakeDto;

            store.dispatch(clinicalCoordinatorSlice.actions.setMedicalSummaryIntake(updatedPatientIntake));
        }
    });

    connection.on('PatientIntakeUpdated', async (dto: PatientIntakeDto) => {
        const data = PatientIntakeDto.fromJS(dto);
        const { medicalSummaryIntake } = store.getState().clinicalCoordinator;

        // If the current intake is the incoming one, update it with the latest information
        if (data.id === medicalSummaryIntake?.id) {
            const updatedIntake = { ...medicalSummaryIntake, ...data } as PatientIntakeDto;

            // Important to keep row version in sync when updates are incoming during CC workflow
            store.dispatch(clinicalCoordinatorSlice.actions.setMedicalSummaryIntake(updatedIntake));
        }
    });
};
