import { customExpandIcon } from '../../../../../../../shared/common/helpers/collapse';
import { PccVitalsDto } from '@medone/medonehp-api-client';
import { Collapse, Tag } from 'antd';
import PccVitalsTable from './pcc-vitals-table';

const { Panel } = Collapse;

type Props = {
    pccVitals: PccVitalsDto;
};

const PccVitals = ({ pccVitals }: Props) => {
    return (
        <div className="p-1">
            <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="Blood Pressure" header="Blood Pressure" extra={<>{<Tag color="#6C8095">{pccVitals?.bloodPressure?.length ?? 0} Result(s)</Tag>}</>}>
                    <PccVitalsTable pccVitals={pccVitals?.bloodPressure} />
                </Panel>
            </Collapse>

            <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="Respirations" header="Respirations" extra={<>{<Tag color="#6C8095">{pccVitals?.respirations?.length ?? 0} Result(s)</Tag>}</>}>
                    <PccVitalsTable pccVitals={pccVitals?.respirations} />
                </Panel>
            </Collapse>

            <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="Temperature" header="Temperature" extra={<>{<Tag color="#6C8095">{pccVitals?.temperature?.length ?? 0} Result(s)</Tag>}</>}>
                    <PccVitalsTable pccVitals={pccVitals?.temperature} />
                </Panel>
            </Collapse>

            <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="Heart Rate" header="Heart Rate" extra={<>{<Tag color="#6C8095">{pccVitals?.heartRate?.length ?? 0} Result(s)</Tag>}</>}>
                    <PccVitalsTable pccVitals={pccVitals?.heartRate} />
                </Panel>
            </Collapse>

            <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="Blood Sugar" header="Blood Sugar" extra={<>{<Tag color="#6C8095">{pccVitals?.bloodSugar?.length ?? 0} Result(s)</Tag>}</>}>
                    <PccVitalsTable pccVitals={pccVitals?.bloodSugar} />
                </Panel>
            </Collapse>

            <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="Height" header="Height" extra={<>{<Tag color="#6C8095">{pccVitals?.height?.length ?? 0} Result(s)</Tag>}</>}>
                    <PccVitalsTable pccVitals={pccVitals?.height} />
                </Panel>
            </Collapse>

            <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="Weight" header="Weight" extra={<>{<Tag color="#6C8095">{pccVitals?.weight?.length ?? 0} Result(s)</Tag>}</>}>
                    <PccVitalsTable pccVitals={pccVitals?.weight} />
                </Panel>
            </Collapse>

            <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="Oxygen Saturation" header="Oxygen Saturation" extra={<>{<Tag color="#6C8095">{pccVitals?.oxygenSaturation?.length ?? 0} Result(s)</Tag>}</>}>
                    <PccVitalsTable pccVitals={pccVitals?.oxygenSaturation} />
                </Panel>
            </Collapse>

            <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                <Panel key="Pain Level" header="Pain Level" extra={<>{<Tag color="#6C8095">{pccVitals?.painLevel?.length ?? 0} Result(s)</Tag>}</>}>
                    <PccVitalsTable pccVitals={pccVitals?.painLevel} />
                </Panel>
            </Collapse>
        </div>
    );
};

export default PccVitals;
