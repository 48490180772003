import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  HPNeededItem, NewAdmitFiltersDto, PatientIntakesClient, UserFiltersClient } from '@medone/medonehp-api-client';
import moment from 'moment';

import { AppDispatch, AppThunk, RootState } from '../../../../shared/store';
import { handleError } from '../../../../shared/common/HandleErrors';
import { Axios } from '../../../../shared/common/http';
import { NewAdmitsState, initialState } from './model';
import { toCamel } from '../../../../shared/common/helpers';

const filterKey = 'newadmits-filter';

export const newAdmitsSlice = createSlice({
    name: 'newAdmits',
    initialState,
    reducers: {
        setError: (state: NewAdmitsState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        setFilters: (state: NewAdmitsState, action: PayloadAction<NewAdmitFiltersDto>) => {
            state.filters = action.payload;
        },

        setNewAdmits: (state: NewAdmitsState, action: PayloadAction<HPNeededItem[]>) => {
            state.newAdmits = action.payload;
        },
    },
});

export function fetchDefaultFilters(): AppThunk<Promise<NewAdmitFiltersDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getDefaultNewAdmitReportStartDate();

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(newAdmitsSlice.actions.setError(null)));
            }

            return await dispatch(fetchFilter(response.result.succeeded ? NewAdmitFiltersDto.fromJS(response.result.entity) : null));
        } catch (error) {
            handleError(error, () => dispatch(newAdmitsSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function fetchNewAdmits(filters?: NewAdmitFiltersDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new PatientIntakesClient(null, Axios);

        try {
            if (filters == null) {
                const savedFilters = await dispatch(fetchDefaultFilters());

                if (savedFilters != null) {
                    filters = {
                        ...savedFilters,
                        startDate: moment(savedFilters.startDate).utc(),
                        regions: savedFilters.regions ?? [],
                        locations: savedFilters.locations ?? [],
                    } as NewAdmitFiltersDto;
                } else {
                    filters = NewAdmitFiltersDto.fromJS({
                        startDate: moment().add(-21, 'days').utc(),
                        regions: [],
                        locations: [],
                    });
                }
            }

            dispatch(setNewAdmitFilters(filters));

            const response = await client.postNewAdmitReport(filters);

            if (response.result.succeeded) {
                dispatch(newAdmitsSlice.actions.setNewAdmits(response.result.entity));
            } else {
                handleError(response, () => dispatch(newAdmitsSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(newAdmitsSlice.actions.setError(error.toString())));
        }
    };
}

function setNewAdmitFilters(filters: NewAdmitFiltersDto, ignoreSet = false): AppThunk {
    return async (dispatch: AppDispatch) => {
        try {
            await dispatch(saveFilter(filters));

            if (!ignoreSet) {
                dispatch(newAdmitsSlice.actions.setFilters(filters));
            }
        } catch (error) {
            handleError(error, () => dispatch(newAdmitsSlice.actions.setError(error.toString())));
        }
    };
}

export function saveFilter(filters: any): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new UserFiltersClient(null, Axios);

        try {
            const response = await client.save(filterKey, filters);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(newAdmitsSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(newAdmitsSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchFilter(defaultValue?: any): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new UserFiltersClient(null, Axios);

        try {
            const response = await client.getByKey(filterKey);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(newAdmitsSlice.actions.setError(null)));
            }

            if (response.result.entity != null) {
                const json = JSON.parse(response.result.entity.filterValue || '');

                return toCamel(json);
            }
        } catch (error) {
            console.error(error);
        }

        return defaultValue || {};
    };
}

export const selectNewAdmitsFilters = (state: RootState) => state.newAdmits.filters;
export const selectNewAdmits = (state: RootState) => state.newAdmits.newAdmits;

export default newAdmitsSlice.reducer;
