import { useEffect, useState } from 'react';
import { Button, Row, Spin, Tag, Tooltip } from 'antd';
import { CensusDto, NoteTypes, PatientIntakeVisitsDto, VisitDto } from '@medone/medonehp-api-client';
import { AccountInfo } from '@azure/msal-browser';

import { useAppSelector, useAppDispatch } from '../../../../../shared/hooks';
import { fetchVisitsByAdmission, selectCensusItemVisit, selectCensusItemOtherVisits, censusSlice } from '../slice';
import { selectAccount, selectAuth } from '../../../../../shared/common/auth/slice';
import { RootState } from '../../../../../shared/store';
import { formatDateTime, getNoteTypeName, isInRole } from '../../../../../shared/common/helpers';
import { AnyProvider, Role } from '../../../../../shared/common/auth/RoleAuth';
import { AuthState } from '../../../../../shared/common/auth/models';

import OpenNoteButton from './open-note-button';
import StatusButtons from '../../components/status-buttons';
import DischargeButtons from '../../components/discharge-buttons';
import QuickNote from './notes/quick-note';
import StagedNote from './staged-note';

type Props = {
    census: CensusDto;
    isMobile?: boolean;
    patientIntakeVisits?: PatientIntakeVisitsDto;
    openDrawer?: boolean;
    hideQuickNote?: boolean;
};

const ActionButtons = ({ census, patientIntakeVisits, isMobile = false, openDrawer = true, hideQuickNote = false }: Props) => {
    const dispatch = useAppDispatch();
    const account = useAppSelector<AccountInfo>(selectAccount);
    const auth = useAppSelector<AuthState>(selectAuth);
    const visit = useAppSelector<VisitDto>((state: RootState) => selectCensusItemVisit(state, census));
    const otherVisits = useAppSelector<VisitDto[]>((state: RootState) => selectCensusItemOtherVisits(state, census));
    const [loading, setLoading] = useState<boolean>(true);
    const [showMobileButton, setShowMobileButton] = useState<boolean>(isMobile);
    const isValidRoles = isInRole(auth?.permissions, [Role.POST_ACUTE_ADMIN, ...AnyProvider]);
    const isViewer = isInRole(auth?.permissions, [Role.VIEWER]);

    const initData = async () => {
        if (isValidRoles) {
            setLoading(true);

            const patchedValues = {
                facilityId: census?.admittedToId,
                facilityType: census?.facilityType,
                patientIntakeId: census?.patientIntakeId,
                providerId: account?.localAccountId,
            } as VisitDto;

            await dispatch(fetchVisitsByAdmission(patchedValues));

            setShowMobileButton(false);
        }

        setLoading(false);
    };

    const showOpenNoteButton = () => {
        if (!visit || !census) {
            return false;
        }

        // For Discharge types, only show the note button if the patient has been discharged
        if (visit.noteType === NoteTypes.DischargeSummary && census.dischargeDate) {
            return false;
        }

        if (isMobile && visit.noteType === NoteTypes.WoundCare) {
            return true;
        }

        return !isMobile && !visit.isSkipped && visit.noteType;
    };

    const renderTooltip = () => {
        if (visit.skippedReason) {
            return <div>Skipped Reason: {visit.skippedReason}</div>;
        }

        if (visit.noteType) {
            return <div>Visit Type: {getNoteTypeName(visit.noteType)}</div>;
        }

        return null;
    };

    const renderMobileLoadButton = () => {
        return (
            <Button type="primary" block onClick={initData} loading={loading}>
                Show Visits
            </Button>
        );
    };

    useEffect(() => {
        // Only fetch data if the visit info isn't being passed in from the schedule
        if (!patientIntakeVisits && !isMobile) {
            initData();
        } else {
            // Set visits from the schedule page
            // Important for the schedule page to have the proper visit data in the store
            dispatch(censusSlice.actions.setVisits(patientIntakeVisits));

            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [census.patientIntakeId, patientIntakeVisits, isMobile]);

    if (isMobile && showMobileButton) {
        return renderMobileLoadButton();
    }

    return (
        isValidRoles && (
            <Spin spinning={loading}>
                <div className="noteform-actions">
                    <Row className="button-container" align="bottom">
                        {!census.dischargeDate ? (
                            <div className="census-status">
                                {visit != null && (
                                    <Tooltip title={renderTooltip()}>
                                        <Tag color="#41bdf6">
                                            {visit.providerName} | {formatDateTime(visit.serviceDate)}
                                        </Tag>
                                    </Tooltip>
                                )}

                                {otherVisits != null &&
                                    otherVisits.map((otherVisit) => (
                                        <Tag key={otherVisit.id} color="#6C8095">
                                            {otherVisit.providerName} | {formatDateTime(otherVisit.serviceDate)}
                                        </Tag>
                                    ))}

                                {!isMobile && <label className="mr-1">Visit</label>}

                                <StatusButtons key={census?.patientIntakeId} census={census} isMobile={isMobile} setLoading={setLoading} loading={loading} />
                            </div>
                        ) : (
                            <DischargeButtons census={census} visit={visit} />
                        )}

                        {showOpenNoteButton() && openDrawer && <OpenNoteButton key={census.patientIntakeId} census={census} setLoading={setLoading} />}

                        {!isMobile && !hideQuickNote && (
                            <QuickNote
                                fromCensus
                                patientId={census.patientId}
                                facilityId={census.admittedToId}
                                patientName={census.name}
                                patientIntakeId={census.patientIntakeId}
                            />
                        )}

                        {!isViewer && (
                            <span className="ml-1">
                                <StagedNote census={census} fromCensus={true} />
                            </span>
                        )}
                    </Row>
                </div>
            </Spin>
        )
    );
};

export default ActionButtons;
