import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Modal, Radio } from 'antd';
import { CensusDto, StagedNoteDto } from '@medone/medonehp-api-client';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { isMobileView, noteShortTypes, shouldShowVisitButton } from '../../../../../../shared/common/helpers';
import { handleError } from '../../../../../../shared/common/HandleErrors';
import { getOrCreateStagedNote, fetchStagedNotesForIntake } from '../../slice.staged-notes';
import { visitDueSelectors, selectVisitButtons } from '../../slice';
import RoleAuth, { AnyProviderWithScribe, Role } from '../../../../../../shared/common/auth/RoleAuth';
import { VisitButtonDict } from '../../models';
import { RootState } from '../../../../../../shared/store';
import { clearNoteData, setCurrentCensus } from '../../slice.notes';
import { PostAcutePaths } from '../../../index.routes';

type Props = {
    census: CensusDto;
    fromCensus?: boolean;
};

const StagedNote = ({ census, fromCensus = false }: Props) => {
    const { patientIntakeId, name: patientName } = census;
    const history = useHistory();

    const dispatch = useAppDispatch();
    const visitDue = useAppSelector((state) => visitDueSelectors.selectById(state.census.visitDues, patientIntakeId));
    const censusVisitButton = useAppSelector<VisitButtonDict>((state: RootState) => selectVisitButtons(state, census));
    const isMobile = isMobileView();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isProcessing, setProcessing] = useState<boolean>(false);
    const [form] = Form.useForm();
    const buttonClasses = classNames({
        'btn-light-blue': fromCensus,
    });

    const handleOpen = async () => {
        await dispatch(fetchStagedNotesForIntake(patientIntakeId));

        setIsVisible(true);
    };

    const handleSubmit = async (stagedNote?: StagedNoteDto) => {
        setProcessing(true);

        dispatch(setCurrentCensus(census));

        if (stagedNote != null) {
            // Navigate to note edit URL
            history.push(`${PostAcutePaths.StagedNotes.Edit}/${stagedNote.patientIntakeId}/${stagedNote.noteType}`);

            setIsVisible(false);
        } else {
            try {
                const values = await form.validateFields();
                const result = await dispatch(getOrCreateStagedNote(patientIntakeId, values.noteType));

                setIsVisible(false);

                form.resetFields();

                // Store page coming from, so we can redirect them back after the note sign/close
                localStorage.setItem('staged-note-redirect-to', window.location.pathname);

                dispatch(clearNoteData());

                // Navigate to note edit URL
                history.push(`${PostAcutePaths.StagedNotes.Edit}/${result.patientIntakeId}/${result.noteType}`);
            } catch (errors) {
                handleError(errors, () => true);
            }
        }

        setProcessing(false);
    };

    const mobileClass = isMobile ? 'mobile' : '';

    return (
        <RoleAuth roles={[Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe]}>
            <Button type="primary" ghost={fromCensus} className={buttonClasses} onClick={handleOpen}>
                Prep Visit
            </Button>

            <Modal
                title={`Prep Visit for ${patientName}`}
                open={isVisible}
                confirmLoading={isProcessing}
                onCancel={() => setIsVisible(false)}
                destroyOnClose
                width={900}
                footer={[
                    <Button key="Cancel" onClick={() => setIsVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={isProcessing} onClick={() => handleSubmit()}>
                        Continue
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical" className="prep-visit-form">
                    <Form.Item label="Note Type" name="noteType" className="required" rules={[{ required: true, message: 'Required' }]}>
                        <Radio.Group className={`visit-types-menu${mobileClass}`}>
                            {noteShortTypes
                                .filter((type) => shouldShowVisitButton(type, censusVisitButton, visitDue))
                                .map((type) => (
                                    <Radio.Button key={type.value} value={type.value}>
                                        {type.name}
                                    </Radio.Button>
                                ))}
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </RoleAuth>
    );
};

export default StagedNote;
