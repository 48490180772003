import { Badge, Space } from 'antd';

import primaryIcon from '../../../../shared/images/specialty_primary.svg';
import pmrIcon from '../../../../shared/images/specialty_pmr.svg';
import woundIcon from '../../../../shared/images/specialty_wound.svg';

type Props = {
    primary: number;
    pmr: number;
    wound: number;
    spaceSize?: number;
};

const QuickNoteBadges = ({ primary, pmr, wound, spaceSize }: Props) => {
    if (primary === 0 && pmr === 0 && wound === 0) {
        return null;
    }

    return (
        <Space size={spaceSize ?? 'small'}>
            {primary > 0 && (
                <Badge count={primary} size="small" style={{ color: 'white !important' }}>
                    <img src={primaryIcon} className="specialty-icon" alt="Primary" />
                </Badge>
            )}

            {pmr > 0 && (
                <Badge count={pmr} size="small">
                    <img src={pmrIcon} className="specialty-icon" alt="PM&R" />
                </Badge>
            )}

            {wound > 0 && (
                <Badge count={wound} size="small">
                    <img src={woundIcon} className="specialty-icon" alt="Wound" />
                </Badge>
            )}
        </Space>
    );
};

export default QuickNoteBadges;
