import { PayloadAction } from '@reduxjs/toolkit';
import {
    PatientIntakesClient,
    PatientIntakeDto,
    AdmissionDateChangeDto,
    MedicalSummaryLockStatusDto,
    MedicalSummaryLockRequestDto,
    MedicalSummaryDto,
    DischargeDto,
    VisitDto,
    ChangeRoomNumberRequestDto,
    PatientIntakesRequestDto,
    PatientIntakeFacilityTypeUpdateDto,
    CensusDto,
    CensusGroupDto,
    ArrivingFromChangeDto,
    AwvPreventativeDto,
    CanReturnFromEdDto,
} from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { CensusState } from './models';
import { censusSlice, fetchVisitsByAdmission } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { fetchPatientTypes } from '../admin/slice.patient-types';
import { fetchArrivingFroms } from '../admin/slice.arriving-froms';
import { fetchFacilities } from '../admin/slice.facilities';
import { getCleanMedicalSummaryFields, getLockExpirationData, isInRole, trimSpacesFromStrings } from '../../../../shared/common/helpers';
import { fetchPatient, fetchPccPatient } from './slice.patients';
import { Role } from '../../../../shared/common/auth/RoleAuth';
import { fetchRecentLabResults } from './slice.patient-labs';
import { fetchPatientDocumentGroups } from './slice.patient-notes';
import { fetchSpecialties } from './slice.specialties';

export const reducers = {
    setPatientIntake: (state: CensusState, action: PayloadAction<PatientIntakeDto>) => {
        state.currentPatientIntake = action.payload;
        state.errorMessage = null;
    },

    clearPatientIntake: (state: CensusState) => {
        state.currentPatientIntake = null;
        state.currentPatientIntakeLockStatus = null;
        state.currentPatientIntakeIsLocked = false;
        state.currentCensus = null;
        state.awvPreventativeItem = null;
    },

    setPatientIntakeRoomNumberInCensus: (state: CensusState, action: PayloadAction<PatientIntakeDto>) => {
        const { id, roomNumber } = action.payload;

        const newCensus = state.census.map((censusItems) => {
            const newItems = censusItems.items.map((censusItem) => {
                if (censusItem.patientIntakeId === id) {
                    return { ...censusItem, roomNumber: roomNumber } as CensusDto;
                }

                return censusItem;
            });

            return { ...censusItems, items: newItems } as CensusGroupDto;
        });

        state.census = newCensus;
    },

    updateMedicalSummaryFields: (state: CensusState, action: PayloadAction<MedicalSummaryDto>) => {
        if (state.currentPatientIntake != null && state.currentPatientIntake.id === action.payload.patientIntakeId) {
            const newMedicalSummary = { ...state.currentPatientIntake.medicalSummary, ...action.payload } as MedicalSummaryDto;
            const newIntake = { ...state.currentPatientIntake, medicalSummary: newMedicalSummary } as PatientIntakeDto;

            state.currentPatientIntake = newIntake;
        }
    },

    applyPatientIntakeLock: (state: CensusState, action: PayloadAction<MedicalSummaryLockStatusDto>) => {
        const { patientIntakeId } = action.payload;

        if (state.currentPatientIntake != null && state.currentPatientIntake.id === patientIntakeId) {
            state.currentPatientIntakeLockStatus = action.payload;
            state.currentPatientIntakeIsLocked = true;
        }
    },

    clearPatientIntakeLock: (state: CensusState) => {
        state.currentPatientIntakeLockStatus = null;
        state.currentPatientIntakeIsLocked = false;
    },

    setLockActive: (state: CensusState, action: PayloadAction<boolean>) => {
        state.currentPatientIntakeLockActive = action.payload;
    },

    setPatientIntakes: (state: CensusState, action: PayloadAction<PatientIntakeDto[]>) => {
        state.patientIntakes = action.payload;
    },

    setCreateIntakeOnDischarge: (state: CensusState, action: PayloadAction<PatientIntakeDto>) => {
        state.createIntakeOnDischarge = action.payload;
    },

    saveAwvPreventativeItem: (state: CensusState, action: PayloadAction<AwvPreventativeDto>) => {
        state.awvPreventativeItem = action.payload;
    },
};

export function fetchPatientIntake(patientIntakeId: number, loadPatient = false, admittedToId?: number): AppThunk<Promise<PatientIntakeDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { account } = getState().auth;
        const { currentCensus } = getState().census;
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getById(patientIntakeId);

            if (response.result.succeeded) {
                if (loadPatient) {
                    await dispatch(fetchPatient(response.result.entity.patientId));
                }

                await dispatch(fetchPatientIntakeLockStatus(patientIntakeId));

                if (admittedToId) {
                    const patchedValues = {
                        facilityId: admittedToId,
                        facilityType: response.result.entity.facilityType,
                        patientIntakeId: patientIntakeId,
                        providerId: account.localAccountId,
                    } as VisitDto;

                    await dispatch(fetchVisitsByAdmission(patchedValues));
                }

                dispatch(censusSlice.actions.setPatientIntake(response.result.entity));

                if (currentCensus?.isPatientPccLinked) {
                    // This needs to be called after the initial setPatientIntake in the event that
                    // the pcc sync logic mutates the intake so we can grab the latest rowVersion
                    // to prevent any concurrency errors upon saving.
                    await dispatch(fetchPccPatient(patientIntakeId));
                }

                return response.result.entity;
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function fetchPatientIntakeByCensus(census: CensusDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { patientTypes, facilities, arrivingFroms } = getState().admin;

        if (!patientTypes || !patientTypes.length) {
            dispatch(fetchPatientTypes());
        }

        if (!facilities || !facilities.length) {
            dispatch(fetchFacilities());
        }

        if (!arrivingFroms || !arrivingFroms.length) {
            dispatch(fetchArrivingFroms());
        }

        dispatch(fetchPatientIntake(census.patientIntakeId, false, census.admittedToId));
        dispatch(fetchSpecialties(census.patientIntakeId));
        dispatch(fetchRecentLabResults(census.patientId));
        dispatch(fetchPatientDocumentGroups(census.patientId));
    };
}

export function updatePatientIntake(patientIntake: PatientIntakeDto, throwOnError = false): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.post(trimSpacesFromStrings(patientIntake));

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatientIntake(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            if (throwOnError) {
                throw error;
            } else {
                handleError(error, () => true);
            }
        }

        return false;
    };
}

export function updateAdmissionDate(dto: AdmissionDateChangeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;

        if (!isInRole(permissions, [Role.SYSADMIN, Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR])) {
            return;
        }

        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.updateAdmissionDate(dto);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setCurrentCensus(response.result.entity));
                dispatch(censusSlice.actions.updateCensus(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function updateRoomNumber(dto: ChangeRoomNumberRequestDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.changeRoomNumber(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchPatientIntakeLockStatus(patientIntakeId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getMedicalSummaryFieldsLocked(patientIntakeId);

            if (response.result.succeeded) {
                dispatch(applyPatientIntakeLock(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

let lockRefreshTimer: NodeJS.Timeout;

export function applyPatientIntakeLock(dto: MedicalSummaryLockStatusDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { account } = getState().auth;
        const { currentPatientIntake } = getState().census;
        const lockedByCurrentUser = dto.lockedByUserId === account?.localAccountId;
        const lockExpirationData = getLockExpirationData(dto);

        // If this lock is coming in for someone other than the current user
        if (account != null && currentPatientIntake?.id === dto.patientIntakeId && !lockedByCurrentUser) {
            if (lockExpirationData.expired) {
                dispatch(censusSlice.actions.clearPatientIntakeLock());
            } else {
                dispatch(censusSlice.actions.applyPatientIntakeLock(dto));

                if (lockRefreshTimer != null) {
                    clearTimeout(lockRefreshTimer);
                }

                lockRefreshTimer = setTimeout(() => {
                    dispatch(fetchPatientIntakeLockStatus(dto.patientIntakeId));
                }, lockExpirationData.refreshAtMs);
            }
        } else {
            dispatch(censusSlice.actions.clearPatientIntakeLock());
        }
    };
}

export function medicalSummaryFieldsLock(dto: MedicalSummaryDto, lock: boolean): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentPatientIntakeLockActive } = getState().census;

        if (currentPatientIntakeLockActive && lock) {
            return true;
        }

        const client = new PatientIntakesClient(null, Axios);

        try {
            const data = MedicalSummaryLockRequestDto.fromJS({
                patientIntakeId: dto.patientIntakeId,
                lockMedicalSummaryFields: lock,
            });

            await client.medicalSummaryFieldsLock(data);

            dispatch(censusSlice.actions.setLockActive(lock));

            return true;
        } catch (error) {}

        dispatch(censusSlice.actions.setLockActive(false));

        return false;
    };
}

export function updateMedicalSummaryFields(dto: MedicalSummaryDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentPatientIntake } = getState().census;

        if (dto.patientIntakeId === currentPatientIntake?.id) {
            const msValues = getCleanMedicalSummaryFields(dto);

            dispatch(censusSlice.actions.updateMedicalSummaryFields(msValues));
        }
    };
}

export function updateDischargeDate(dto: DischargeDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.updateDischargeDate(dto);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.updateCensus(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function getAllPatientIntakesByPatientId(patientId: number, intakeId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const data = PatientIntakesRequestDto.fromJS({ patientId, exceptPatientIntakeId: intakeId });
            const response = await client.getAllByPatientId(data);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatientIntakes(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function updateFacilityType(dto: PatientIntakeFacilityTypeUpdateDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.updateFacilityType(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function updateArrivingFrom(dto: ArrivingFromChangeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.updateArrivingFrom(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function fetchAwvPreventativeByPatientIntakeId(patientIntakeId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getAwvPreventativeByPatientIntakeId(patientIntakeId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.saveAwvPreventativeItem(response.result.entity));
            } else {
                dispatch(censusSlice.actions.saveAwvPreventativeItem(null));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function saveAwvPreventativeItem(dto: AwvPreventativeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.saveAwvPreventative(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            } else {
                dispatch(censusSlice.actions.saveAwvPreventativeItem(response.result.entity));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function fetchIntakeDto(patientIntakeId: number): AppThunk<Promise<PatientIntakeDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getById(patientIntakeId);

            if (response.result.succeeded) {
                return response.result.entity;
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function fetchCanReturnFromEDForPatientIntakeIds(patientIntakeIds: number[]): AppThunk<Promise<CanReturnFromEdDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getCanReturnFromEDForPatientIntakeIds(patientIntakeIds);

            if (response.result.succeeded) {
                return response.result.entity;
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export const selectPatientIntake = (state: RootState) => state.census.currentPatientIntake;
export const selectPatientIntakeLockStatus = (state: RootState) => state.census.currentPatientIntakeLockStatus;
export const selectPatientIntakeIsLocked = (state: RootState) => state.census.currentPatientIntakeIsLocked;
export const selectPatientIntakes = (state: RootState) => state.census.patientIntakes;
export const selectCreateIntakeOnDischarge = (state: RootState) => state.census.createIntakeOnDischarge;
export const selectAwvPreventativeItem = (state: RootState) => state.census.awvPreventativeItem;
