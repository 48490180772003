import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../shared/store';

import { initialState, ReportingState } from './models';
import { reducers as productivityReducers } from './slice.productivity';

export const reportingSlice = createSlice({
    name: 'reporting',
    initialState,
    reducers: {
        setLoading: (state: ReportingState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        setProcessing: (state: ReportingState, action: PayloadAction<boolean>) => {
            state.processing = action.payload;
        },

        setError: (state: ReportingState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.loading = false;
            state.processing = false;
        },

        ...productivityReducers,
    },
});

export const selectLoading = (state: RootState) => state.reporting.loading;
export const selectProcessing = (state: RootState) => state.reporting.processing;
export const selectError = (state: RootState) => state.reporting.errorMessage;

export const productivityFilterKey = 'productivity-filter';

export default reportingSlice.reducer;
