import { lazy, useEffect } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router';
import { Alert } from 'antd';

import { selectError } from './slice';
import { PccCensusPaths } from './pcc-census/index.routes';
import { useAppSelector } from '../../../shared/hooks';
import { AnyProviderWithScribe, Role } from '../../../shared/common/auth/RoleAuth';
import { CensusPaths } from './census/index.routes';

import AuthorizedRoute from '../../../shared/common/auth/AuthorizedRoute';
import ReturnToHospitalForm from './return-to-hospital/form';
import ReturnToHospital from './return-to-hospital';
import { HubSingleton } from '../../../shared/hubs';
import { clearRefreshInterval, initRefreshInterval } from './census/hubs';
import { allowSignalrConnection } from '../../../shared/common/helpers';
import { selectCurrentNote } from './census/slice.notes';

const Census = lazy(() => import('./census'));
const RouteBuilder = lazy(() => import('./route-builder'));
const Schedule = lazy(() => import('./schedule'));
const PccCensus = lazy(() => import('./pcc-census'));
const PrivacyPolicy = lazy(() => import('./privacy-policy'));
const UnsignedNotes = lazy(() => import('./unsigned-notes'));
const UnsignedNotesPrint = lazy(() => import('./unsigned-notes/print'));
const ClinicalCoordinatorWorkspace = lazy(() => import('./clinical-coordinator'));
const Abandoned = lazy(() => import('./abandoned'));
const QuickNotes = lazy(() => import('./quick-notes'));
const ProductivityPayroll = lazy(() => import('../reporting/components/productivity-payroll'));
const NewAdmits = lazy(() => import('./new-admits'));
const Viewer = lazy(() => import('./viewer'));
const NoteForm = lazy(() => import('./census/components/notes/form'));
const StagedNoteForm = lazy(() => import('./census/components/staged-note/staged-note-form'));

export const PostAcutePaths = {
    Home: {
        Index: '/postacute',
    },

    Viewer: {
        Index: '/postacute/viewer',
    },

    Admin: {
        Index: '/postacute/admin',
    },

    Abandoned: {
        Index: '/postacute/abandoned',
    },

    ReturnToHospital: {
        Index: '/postacute/return-to-hospital',
        Form: '/postacute/return-to-hispital/form',
    },

    ClinicalCoordinator: {
        Index: '/postacute/clinical-coordinator',
    },

    Notes: {
        Edit: '/postacute/note/edit',
    },

    StagedNotes: {
        Edit: '/postacute/staged-note/edit',
    },

    UnsignedNotes: {
        Index: '/postacute/unsigned-notes',
        Print: '/postacute/unsigned-notes/print',
    },

    QuickNotes: {
        Index: '/postacute/quick-notes',
    },

    PccCensus: {
        Index: '/postacute/pcc-census',
    },

    RouteBuilder: {
        Index: '/postacute/route-builder',
    },

    Schedule: {
        Index: '/postacute/schedule',
    },

    PrivacyPolicy: {
        Index: '/postacute/privacy-policy',
    },

    Reporting: {
        Productivity: '/postacute/reporting/productivity',
    },

    NewAdmits: {
        Index: '/postacute/new-admits',
    },
};

const Routes = () => {
    const errorMessage = useAppSelector<string>(selectError);
    const currentNote = useAppSelector(selectCurrentNote);
    const location = useLocation();

    useEffect(() => {
        if (!allowSignalrConnection(currentNote)) {
            HubSingleton.Instance.stopHub().then(clearRefreshInterval);
        } else {
            HubSingleton.Instance.startHub().then(initRefreshInterval);
        }
    }, [currentNote, location]);

    return (
        <Switch>
            <Route exact path={PostAcutePaths.Home.Index} component={() => <Redirect to={CensusPaths.Home.Index} />} />

            <Route path={PostAcutePaths.PrivacyPolicy.Index} component={PrivacyPolicy} />

            <Route path={PostAcutePaths.Abandoned.Index} component={Abandoned} />

            <AuthorizedRoute
                exact
                path={`${PostAcutePaths.Notes.Edit}/:id`}
                component={NoteForm}
                roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, ...AnyProviderWithScribe]}
                redirectTo={CensusPaths.Home.Index}
            />

            <AuthorizedRoute
                exact
                path={`${PostAcutePaths.StagedNotes.Edit}/:patientIntakeId/:noteType`}
                component={StagedNoteForm}
                roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, ...AnyProviderWithScribe]}
                redirectTo={CensusPaths.Home.Index}
            />

            <AuthorizedRoute path={PostAcutePaths.Viewer.Index} component={Viewer} roles={[Role.VIEWER, Role.CLINICAL_COORDINATOR]} />

            <AuthorizedRoute
                path={CensusPaths.Home.Index}
                component={Census}
                roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, ...AnyProviderWithScribe]}
                redirectTo={PostAcutePaths.ClinicalCoordinator.Index}
            />

            <AuthorizedRoute path={PccCensusPaths.Home.Index} component={PccCensus} roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, ...AnyProviderWithScribe]} />
            <AuthorizedRoute path={PostAcutePaths.RouteBuilder.Index} component={RouteBuilder} roles={[Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe]} />
            <AuthorizedRoute
                path={PostAcutePaths.Schedule.Index}
                component={Schedule}
                roles={[Role.POST_ACUTE_ADMIN, Role.POST_ACUTE_PHYSICIAN, Role.POST_ACUTE_ADVANCED_PRACTICE_PRACTITIONER]}
            />

            <AuthorizedRoute exact path={PostAcutePaths.UnsignedNotes.Index} component={UnsignedNotes} roles={[Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe]} />
            <AuthorizedRoute exact path={PostAcutePaths.UnsignedNotes.Print} component={UnsignedNotesPrint} roles={[Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe]} />
            <AuthorizedRoute path={PostAcutePaths.QuickNotes.Index} component={QuickNotes} roles={[Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe]} />
            <AuthorizedRoute
                path={PostAcutePaths.ClinicalCoordinator.Index}
                component={ClinicalCoordinatorWorkspace}
                roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, Role.PHYSICIAN, Role.ADVANCED_PRACTICE_PRACTITIONER]}
                restrictedRoles={[Role.VIEWER]}
            />
            <AuthorizedRoute path={PostAcutePaths.Schedule.Index} component={Schedule} roles={[Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe]} />
            <AuthorizedRoute
                exact
                path={PostAcutePaths.Reporting.Productivity}
                component={ProductivityPayroll}
                roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, ...AnyProviderWithScribe]}
                restrictedRoles={[Role.VIEWER]}
            />

            <AuthorizedRoute
                exact
                path={PostAcutePaths.NewAdmits.Index}
                component={NewAdmits}
                roles={[Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, ...AnyProviderWithScribe]}
                restrictedRoles={[Role.VIEWER]}
            />

            <AuthorizedRoute path={PostAcutePaths.ReturnToHospital.Index} component={ReturnToHospital} roles={[Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe]} />
            <AuthorizedRoute
                path={`${PostAcutePaths.ReturnToHospital.Form}/:id/:rthId`}
                component={ReturnToHospitalForm}
                roles={[Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe]}
            />

            {errorMessage != null && errorMessage !== '' && <Alert message={null} description={errorMessage} type="error" showIcon />}
        </Switch>
    );
};

export default Routes;
