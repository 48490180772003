import { PccConditionDto } from '@medone/medonehp-api-client';
import { Table } from 'antd';

type Props = {
    conditions: PccConditionDto[];
};

const Conditions = ({ conditions }: Props) => {
    return (
        <Table dataSource={conditions} rowKey="id" className="conditions-table" scroll={{ x: true }} pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}>
            <Table.Column<PccConditionDto> title="Icd10" dataIndex="icd10" />
            <Table.Column<PccConditionDto> title="Description" dataIndex="icd10Description" />
            <Table.Column<PccConditionDto> title="Rank Description" dataIndex="rankDescription" />
            <Table.Column<PccConditionDto> title="Classification Description" dataIndex="classificationDescription" />
            <Table.Column<PccConditionDto> title="Therapy" dataIndex="therapy" render={(data) => (data ? 'Yes' : 'No')} />
            <Table.Column<PccConditionDto> title="Clinical Status" dataIndex="clinicalStatus" />
            <Table.Column<PccConditionDto> title="Comments" dataIndex="comments" />
            <Table.Column<PccConditionDto> title="Principle Diagnosis" dataIndex="principleDiagnosis" render={(data) => (data ? 'Yes' : 'No')} />
            <Table.Column<PccConditionDto> title="Onset Date" dataIndex="onsetDate" render={(data) => (data ? data.format('MM/DD/YYYY') : null)} />
            <Table.Column<PccConditionDto> title="Resolved Date" dataIndex="resolvedDate" render={(data) => (data ? data.format('MM/DD/YYYY') : null)} />
        </Table>
    );
};

export default Conditions;
