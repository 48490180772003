import { PayloadAction } from '@reduxjs/toolkit';
import { HospitalSystemsClient, HospitalSystemDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { AdminState } from './models';
import { adminSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { fetchRegions } from './slice.regions';

export const reducers = {
    setHospitalSystems: (state: AdminState, action: PayloadAction<HospitalSystemDto[]>) => {
        state.hospitalSystems = action.payload;
        state.errorMessage = null;
    },

    setHospitalSystem: (state: AdminState, action: PayloadAction<HospitalSystemDto>) => {
        state.currentHospitalSystem = action.payload;
        state.errorMessage = null;
    },

    syncHospitalSystem: (state: AdminState, action: PayloadAction<HospitalSystemDto>) => {
        let newItems = [...state.hospitalSystems];
        const index = state.hospitalSystems.findIndex((x) => x.id === action.payload.id);

        if (index !== -1) {
            newItems = newItems.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload } as HospitalSystemDto;
                }

                return item;
            });
        } else {
            newItems.push(action.payload);
        }

        state.hospitalSystems = newItems;

        if (state.currentHospitalSystem != null && state.currentHospitalSystem.id === action.payload.id) {
            state.currentHospitalSystem = action.payload;
        }
    },
};

export function fetchHospitalSystems(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { hospitalSystems } = getState().admin;

        if (hospitalSystems != null && hospitalSystems.length > 0) {
            return;
        }

        const client = new HospitalSystemsClient(null, Axios);

        try {
            const response = await client.getAll();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospitalSystems(response.result.entity));

                await dispatch(fetchRegions());
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchHospitalSystem(hospitalSystemId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new HospitalSystemsClient(null, Axios);

        try {
            const response = await client.getById(hospitalSystemId);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospitalSystem(response.result.entity));

                await dispatch(fetchRegions());
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function updateHospitalSystem(hospitalSystem: HospitalSystemDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new HospitalSystemsClient(null, Axios);

        try {
            const response = await client.post(hospitalSystem);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospitalSystem(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function syncHospitalSystem(dto: HospitalSystemDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(adminSlice.actions.syncHospitalSystem(dto));
    };
}

export const selectHospitalSystems = (state: RootState) => state.admin.hospitalSystems;
export const selectHospitalSystem = (state: RootState) => state.admin.currentHospitalSystem;
