import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { flattenDeep } from 'lodash';

import {
    
    RouteBuilderClient,
    RouteBuilderFiltersDto,
    RouteBuilderDto,
    ProviderTypes,
    RouteBuilderProviderGroupCountsDto,
    RouteBuilderGroupDto,
    CensusBadgeRequestDto,
    CensusBadgeDto,
} from '@medone/medonehp-api-client';
import { handleError } from '../../../../shared/common/HandleErrors';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { initialState, routeBuilderBadgesAdapter, RouteBuilderState, RouteBuilderTotalsDto } from './models';
import { fetchUsersByRegion, reducers as usersReducers } from './slice.users';
import { reducers as scheduleReducers } from './slice.schedules';
import { fetchFacilities } from '../admin/slice.facilities';
import { fetchRegions } from '../admin/slice.regions';
import { fetchFilter, saveFilter } from '../../users/slice';
import { Role } from '../../../../shared/common/auth/RoleAuth';
import { isInRole } from '../../../../shared/common/helpers';
import { fetchSpecialties } from '../census/slice.specialties';
import { fetchVisitDues } from '../census/slice';

export const routeBuilderSlice = createSlice({
    name: 'routeBuilder',
    initialState,
    reducers: {
        setLoading: (state: RouteBuilderState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
            state.processing = false;
        },

        setLoadingDetails: (state: RouteBuilderState, action: PayloadAction<boolean>) => {
            state.detailsLoading = action.payload;
        },

        setProcessing: (state: RouteBuilderState, action: PayloadAction<boolean>) => {
            state.loading = false;
            state.processing = action.payload;
        },

        setError: (state: RouteBuilderState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.loading = false;
            state.processing = false;
        },

        setRouteBuilder: (state: RouteBuilderState, action: PayloadAction<RouteBuilderGroupDto[]>) => {
            state.groups = action.payload;
            state.loading = false;
            state.processing = false;
            state.errorMessage = null;
        },

        setRouteBuilderTotals: (state: RouteBuilderState, action: PayloadAction<RouteBuilderTotalsDto>) => {
            state.totals = action.payload;
        },

        setProviderTotals: (state: RouteBuilderState, action: PayloadAction<RouteBuilderProviderGroupCountsDto[]>) => {
            state.providerTotals = action.payload;
        },

        setRouteBuilderFilters: (state: RouteBuilderState, action: PayloadAction<RouteBuilderFiltersDto>) => {
            state.filters = action.payload;
        },

        setBadgeData: (state: RouteBuilderState, action: PayloadAction<CensusBadgeDto[]>) => {
            routeBuilderBadgesAdapter.setMany(state.badges, action.payload);
        },

        // setVisitDues(state: RouteBuilderState, action: PayloadAction<RequiredVisitResultDto[]>) {
        //     visitDuesAdapter.setMany(state.visitDues, action.payload);
        // },

        ...usersReducers,
        ...scheduleReducers,
    },
});

export const { setRouteBuilder, setUsers } = routeBuilderSlice.actions;

export const getFilter = (filters?: RouteBuilderFiltersDto) => {
    if (filters == null) {
        filters = RouteBuilderFiltersDto.fromJS({
            regionId: null,
        });
    }

    return filters;
};

export function fetchRouteBuilder(filters?: RouteBuilderFiltersDto, isEdit = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        if (!isEdit) {
            dispatch(routeBuilderSlice.actions.setLoading(true));
        }

        const { facilities, regions } = getState().admin;
        const { permissions } = getState().auth;
        const roles = [Role.ROUTE_BUILDER];

        if (!isInRole(permissions, roles)) {
            return;
        }

        const client = new RouteBuilderClient(null, Axios);

        try {
            if (filters == null) {
                const savedFilters = await dispatch(fetchFilter('route-builder-filter', getFilter()));

                if (savedFilters != null && savedFilters !== '') {
                    filters = RouteBuilderFiltersDto.fromJS(savedFilters);
                }
            }

            filters = getFilter(filters);

            if (regions == null || regions.length === 0) {
                await dispatch(fetchRegions()); // We need these for logic in the publish schedule component
            }

            if (facilities == null || facilities.length === 0) {
                await dispatch(fetchFacilities()); // We need these for logic in the provider dropdown select change
            }

            // Only load data if a region is selected
            if (filters.regionId) {
                await dispatch(fetchUsersByRegion(filters.regionId, [ProviderTypes.Physician], true)); // Load the providers for the Provider Select list

                const response = await client.grouped(filters);

                if (response.result.succeeded) {
                    const dto = {
                        newAdmits: response.result.entity.newAdmits,
                        physicianFollowUps: response.result.entity.physicianFollowUps,
                        physicianOrAppFollowUps: response.result.entity.physicianOrAppFollowUps,
                    } as RouteBuilderTotalsDto;

                    dispatch(routeBuilderSlice.actions.setRouteBuilder(response.result.entity.groups));
                    dispatch(routeBuilderSlice.actions.setRouteBuilderTotals(dto));
                    dispatch(routeBuilderSlice.actions.setProviderTotals(response.result.entity.providerGroupCounts));

                    await dispatch(setRouteBuilderFilters(filters, 'route-builder-filter'));
                } else {
                    handleError(response, () => dispatch(routeBuilderSlice.actions.setError(null)));
                }
            } else {
                // Clear the provider list if switching regions, etc
                dispatch(setUsers([]));
                dispatch(routeBuilderSlice.actions.setRouteBuilder(null));
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                handleError(error, () => dispatch(routeBuilderSlice.actions.setError(error.toString())));
            }
        }
    };
}

export function fetchBadgeData(routeBuilderGroup?: RouteBuilderGroupDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new RouteBuilderClient(null, Axios);

        // Don't run this logic for collapses
        if (!routeBuilderGroup) {
            return;
        }

        try {
            const dto = CensusBadgeRequestDto.fromJS({
                facilityId: routeBuilderGroup.admittedToId,
                patientIntakeIds: routeBuilderGroup.patientIntakeIds,
            });

            const response = await client.getBadgeData(dto);

            if (response.result.succeeded) {
                dispatch(routeBuilderSlice.actions.setBadgeData(response.result.entity));
            } else {
                handleError(response, () => dispatch(routeBuilderSlice.actions.setError(null)));
            }

            await dispatch(fetchSpecialties(routeBuilderGroup.patientIntakeIds));
        } catch (error) {
            handleError(error, () => dispatch(routeBuilderSlice.actions.setError(error.toString())));
        }

        await dispatch(fetchVisitDues(routeBuilderGroup));
    };
}

function setRouteBuilderFilters(filters: RouteBuilderFiltersDto, filterKey?: string, ignoreSet = false): AppThunk {
    return async (dispatch: AppDispatch) => {
        try {
            if (filterKey != null) {
                await dispatch(saveFilter(filterKey, filters));
            }

            if (!ignoreSet) {
                dispatch(routeBuilderSlice.actions.setRouteBuilderFilters(filters));
            }
        } catch (error) {
            handleError(error, () => dispatch(routeBuilderSlice.actions.setError(error.toString())));
        }
    };
}

export const selectLoading = (state: RootState) => state.routeBuilder.loading;
export const selectLoadingDetails = (state: RootState) => state.routeBuilder.detailsLoading;
export const selectProcessing = (state: RootState) => state.routeBuilder.processing;
export const selectError = (state: RootState) => state.routeBuilder.errorMessage;
export const selectRouteBuilderGroups = (state: RootState) => state.routeBuilder.groups;
export const selectRouteBuilderFilters = (state: RootState) => state.routeBuilder.filters;
export const selectRouteBuilderItems = (state: RootState) => {
    if (!state.routeBuilder.groups) {
        return [];
    }

    return flattenDeep<RouteBuilderDto[]>((state.routeBuilder.groups || []).map((x) => x.items)) as RouteBuilderDto[];
};
export const selectRouteBuilderTotals = (state: RootState) => state.routeBuilder.totals;
export const selectProviderTotals = (state: RootState) => state.routeBuilder.providerTotals;

export const routeBuilderBadgeSelectors = routeBuilderBadgesAdapter.getSelectors();

export default routeBuilderSlice.reducer;
