import React from 'react';
import { Table } from 'antd';
import { PccImmunizationDto } from '@medone/medonehp-api-client';

import { formatDateTime } from '../../../../../../../shared/common/helpers';

type Props = {
    immunizations: PccImmunizationDto[];
};

const ImmunizationsTable = ({ immunizations }: Props) => (
    <Table
        dataSource={immunizations}
        rowKey={(data) => `${data.immunization}-${data.status}-${data.statusDateTime}`}
        scroll={{ x: true }}
        pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
    >
        <Table.Column<PccImmunizationDto> title="Immunization" dataIndex="immunization" />
        <Table.Column<PccImmunizationDto> title="Status" dataIndex="status" />
        <Table.Column<PccImmunizationDto> title="Date/Time" dataIndex="statusDateTime" render={(data) => formatDateTime(data)} />
        <Table.Column<PccImmunizationDto> title="Notes" dataIndex="notes" />
    </Table>
);

export default ImmunizationsTable;
