import { Divider, Skeleton } from 'antd';
import { StagedNoteDto } from '@medone/medonehp-api-client';

type Props = {
    note?: StagedNoteDto;
};

const StagedNotePreview = ({ note }: Props) => {
    return (
        <Skeleton loading={!note}>
            <div className="note-body">
                <div className="mb-1">
                    <div>
                        <strong>History of Present Illness</strong>
                    </div>

                    {note?.historyOfPresentIllness}
                </div>

                <Divider className="mb-1 mt-0" />

                {note?.problemList.map((problem, idx) => (
                    <div className="mb-1" key={idx}>
                        <div>
                            <strong>{problem.preferredTerm}</strong>
                        </div>

                        {problem.description}
                    </div>
                ))}
            </div>
        </Skeleton>
    );
};

export default StagedNotePreview;
