import { RouteBuilderGroupDto, ScheduleDto } from '@medone/medonehp-api-client';

export const setAssignedProvider = (group: RouteBuilderGroupDto[], data: ScheduleDto[]) => {
    let newRouteBuilderGroups: RouteBuilderGroupDto[];

    data.forEach((schedule) => {
        if (schedule.census) {
            const isUnassigned = schedule.census.admittedToId === 0 && schedule.assignedUserName == null;

            newRouteBuilderGroups = (newRouteBuilderGroups || group).map((groupItem) => {
                // During an unassign, we still want to run this logic to clear out the assigned provider
                if (groupItem.admittedToId === schedule.census.admittedToId || isUnassigned) {
                    const items = [...groupItem.items].map((x) => {
                        if (x.patientIntakeId === schedule.patientIntakeId) {
                            const providerName = schedule.assignedUserName != null ? schedule.assignedUserName : '';

                            return { ...x, assignedProvider: providerName };
                        }

                        return x;
                    });

                    return { ...groupItem, items: items } as RouteBuilderGroupDto;
                }

                return { ...groupItem } as RouteBuilderGroupDto;
            });
        }
    });

    return newRouteBuilderGroups;
};
