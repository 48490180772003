import { useMemo, useState } from 'react';
import { fetchFeatureFlag } from '../common/data/slice';

import { useAppDispatch, useAppSelector } from './index';
import { selectAuth } from '../common/auth/slice';
import { AuthState } from '../common/auth/models';

/**
 * Retrieves the specified feature flag from Azure App Configuration.
 * @param {string} flagKey App Config Feature Flag key
 * @returns the feature flag for the specified key
 */
const useFeatureFlag = (flagKey = '') => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector<AuthState>(selectAuth);
    const [isEnabled, setEnabled] = useState(false);

    useMemo(async () => {
        if (auth != null && auth.isAuthenticated === true) {
            if (!flagKey || !flagKey.toString().trim().length) {
                console.error('A feature flag key must be supplied.');
            } else {
                try {
                    setEnabled(false);

                    dispatch(fetchFeatureFlag(flagKey)).then((featureFlag) => {
                        setEnabled(featureFlag?.enabled);
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }, [dispatch, flagKey, auth]);

    return isEnabled;
};

export { useFeatureFlag };
