import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { PageHeader, Layout, Button, Checkbox, Row, Skeleton } from 'antd';
import { PatientIntakeDto, ReturnToHospitalConfigurationDto, ReturnToHospitalDto } from '@medone/medonehp-api-client';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';

import { PostAcutePaths } from '../index.routes';
import { fetchReturnToHospitalConfigurations, selectReturnToHospitalConfigurations } from '../admin/slice.return-to-hospital-config';
import { fetchReturnToHospital, selectReturnToHospital, updateReturnToHospital } from './slice';
import { fetchPatientIntake, selectPatientIntake } from '../census/slice.patient-intakes';
import { isInRole, renderPatientIntakeFullName } from '../../../../shared/common/helpers';

import InfoTags from '../components/info-tags';
import { AuthState } from '../../../../shared/common/auth/models';
import { selectAuth } from '../../../../shared/common/auth/slice';
import { Role } from '../../../../shared/common/auth/RoleAuth';
import TextArea from 'antd/lib/input/TextArea';

type FormParams = { id: string; rthId?: string };

interface CheckableDto extends ReturnToHospitalConfigurationDto {
    isChecked: boolean;
}

const ReturnToHospitalForm = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const params = useParams<FormParams>();
    const patientIntakeId = parseInt(params.id);
    const rthId = parseInt(params.rthId);
    const [selectionLevels, setSelectionLevels] = useState<CheckableDto[][]>([]);
    const [nodeIndex, setNodeIndex] = useState<any>({});
    const returnToHospitalConfigurations = useAppSelector<ReturnToHospitalConfigurationDto[]>(selectReturnToHospitalConfigurations);
    const patientIntake = useAppSelector<PatientIntakeDto>(selectPatientIntake);
    const returnToHospital = useAppSelector<ReturnToHospitalDto>(selectReturnToHospital);
    const auth = useAppSelector<AuthState>(selectAuth);
    const isPhysician = isInRole(auth.permissions, [Role.PHYSICIAN, Role.POST_ACUTE_PHYSICIAN]);
    const [feedback, setFeedback] = useState<string>('');

    const initData = async () => {
        const ix = {};
        const indexNodes = (nodes: ReturnToHospitalConfigurationDto[]) => {
            nodes?.forEach((node) => {
                ix[node.id] = node as CheckableDto;

                indexNodes(node.children);
            });
        };

        indexNodes(returnToHospitalConfigurations);
        setNodeIndex(ix);
        setSelectionLevels([returnToHospitalConfigurations.map((x) => x as CheckableDto)]);

        await dispatch(fetchPatientIntake(patientIntakeId));
        if (!isNaN(rthId)) {
            await dispatch(fetchReturnToHospital(rthId));
        }
    };

    const selectionChanged = (level: number, nodeId: number, isChecked: boolean) => {
        (nodeIndex[nodeId] as CheckableDto).isChecked = isChecked;
        updateViewState(level);
    };

    const updateViewState = (level: number) => {
        let levelCounter = level;

        while (levelCounter < selectionLevels.length) {
            const childNodes = [];

            selectionLevels[levelCounter]
                .filter((x) => x.isChecked)
                .forEach((x) => {
                    if (x.children) {
                        childNodes.push(...x.children);
                    }
                });

            levelCounter += 1;

            if (childNodes.length === 0) {
                break;
            }

            selectionLevels[levelCounter] = childNodes;
        }

        setSelectionLevels(selectionLevels.slice(0, levelCounter));
    };

    const renderFeedbackSection = () => {
        if (isPhysician) {
            return (
                <div className="mt-2">
                    <label>Feedback</label>
                    <TextArea value={feedback} onChange={(e) => setFeedback(e.target.value)} autoSize={{ minRows: 3, maxRows: 6 }} maxLength={1000} />
                </div>
            );
        }
        return null;
    };

    const saveForm = async () => {
        const selectedNodeIds = [];

        selectionLevels.forEach((arr) =>
            arr.forEach((node) => {
                if (node.isChecked) {
                    selectedNodeIds.push(node.id);
                }
            })
        );

        const dto = returnToHospital ?? ({} as ReturnToHospitalDto);
        dto.id = isNaN(rthId) ? 0 : rthId;
        dto.patientIntakeId = patientIntakeId;
        dto.valuesJson = JSON.stringify(selectedNodeIds);
        dto.feedback = feedback;

        await dispatch(updateReturnToHospital(dto));

        toast.success('Form saved');

        cancelForm();
    };

    const cancelForm = () => {
        history.push(PostAcutePaths.ReturnToHospital.Index);
    };

    useEffect(() => {
        dispatch(fetchReturnToHospitalConfigurations());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnToHospitalConfigurations]);

    useEffect(() => {
        if (returnToHospital) {
            var selectedValues = JSON.parse(returnToHospital?.valuesJson ?? '[]') as number[];
            selectedValues.forEach((x) => {
                const node = nodeIndex[x] as CheckableDto;
                if (node) {
                    node.isChecked = true;
                }
            });
            updateViewState(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnToHospital]);

    return (
        <>
            <PageHeader title={`Return to Hospital Form for ${renderPatientIntakeFullName(patientIntake)}`} />

            <Layout.Content className="p-2">
                <Skeleton active loading={patientIntake == null || (!isNaN(rthId) && returnToHospital == null)}>
                    <InfoTags patientIntake={patientIntake} />

                    <table>
                        <tbody>
                            <tr>
                                {selectionLevels.map((items, ix) => (
                                    <td key={ix} style={{ verticalAlign: 'top', paddingRight: 10 }}>
                                        {items.map((item) => (
                                            <React.Fragment key={item.id}>
                                                <Checkbox
                                                    key={item.id}
                                                    checked={item.isChecked}
                                                    onChange={(x) => selectionChanged(ix, item.id, x.target.checked)}
                                                    disabled={!isNaN(rthId)}
                                                >
                                                    {item.description}
                                                </Checkbox>

                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>

                    {renderFeedbackSection()}

                    <Row justify="end" className="form-actions mt-2">
                        <Button type="link" onClick={cancelForm}>
                            Cancel
                        </Button>

                        <Button type="primary" onClick={saveForm}>
                            Save Form
                        </Button>
                    </Row>
                </Skeleton>
            </Layout.Content>
        </>
    );
};

export default ReturnToHospitalForm;
