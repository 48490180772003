import { BedBoardEnumSelectListDto, BedBoardHospitalDto, BedBoardHospitalFormTypeDto, BedBoardHospitalRequestConfigurationDto } from '@medone/medonehp-bedboard-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, AdminState } from './models';

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        resetState: (state: AdminState) => {
            state.currentHospital = null;
        },

        setHospitals: (state: AdminState, action: PayloadAction<BedBoardHospitalDto[]>) => {
            state.hospitals = action.payload;
        },

        setHospital: (state: AdminState, action: PayloadAction<BedBoardHospitalDto>) => {
            state.currentHospital = action.payload;
        },

        setHospitalRequestConfigs: (state: AdminState, action: PayloadAction<BedBoardHospitalRequestConfigurationDto[]>) => {
            state.currentHospitalRequestConfigs = action.payload;
        },

        setHospitalFormTypes: (state: AdminState, action: PayloadAction<BedBoardHospitalFormTypeDto[]>) => {
            state.formTypes = action.payload;
        },

        setHospitalRequestTypes: (state: AdminState, action: PayloadAction<BedBoardEnumSelectListDto[]>) => {
            state.requestTypes = action.payload;
        },
    },
});

export default adminSlice.reducer;
