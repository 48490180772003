import { useEffect, useState } from 'react';
import { Card, Checkbox, Col, Form, Row, Select } from 'antd';
import { CensusFiltersDto, EnumSelectListDto, FacilityDto } from '@medone/medonehp-api-client';

import { selectActiveFacilities } from '../../admin/slice.facilities';
import { refreshCensus, selectCensusFilters, setCensusFilters } from '../slice';
import { filterOptions, filterOptionsStartsWith, getSelectListOptions } from '../../../../../shared/common/helpers';
import { selectActiveRegions } from '../../admin/slice.regions';
import { useAppSelector, useAppDispatch } from '../../../../../shared/hooks';
import { selectSpecialties } from '../../../../../shared/common/data/slice';

import FiltersMobile from '../components-mobile/filters';

const Filters = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const censusFilters = useAppSelector<CensusFiltersDto>(selectCensusFilters);
    const specialties = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const facilities = useAppSelector<FacilityDto[]>(selectActiveFacilities);
    const regions = useAppSelector<FacilityDto[]>(selectActiveRegions);
    const [locations, setLocations] = useState<any[]>(getSelectListOptions(facilities, 'name'));

    const handleValuesChange = async (_changedValues, allValues) => {
        const filterValues = CensusFiltersDto.fromJS(allValues);

        await dispatch(setCensusFilters(filterValues, 'census-filter'));

        await dispatch(refreshCensus(null, true));
    };

    const handleRegionChange = (values) => {
        if (values != null && values.length > 0) {
            const facilitiesInRegion = facilities.filter(
                (item) =>
                    // Include locations based on the region selected
                    values.includes(item.regionId) ||
                    // Or include the location from the saved filter in the event they clear a region
                    // that the location is not in
                    censusFilters?.locations?.includes(item.id)
            );

            setLocations(getSelectListOptions(facilitiesInRegion, 'name'));
        } else {
            setLocations(getSelectListOptions(facilities, 'name'));
        }
    };

    useEffect(() => {
        handleRegionChange(form.getFieldValue('regions'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facilities]);

    useEffect(() => {
        if (censusFilters != null && censusFilters.specialties == null) {
            censusFilters.specialties = [];
        }

        form.setFieldsValue(censusFilters);

        handleRegionChange(form.getFieldValue('regions'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [censusFilters, form]);

    return (
        <Card bordered={false} className="filters">
            <div className="hide-small">
                <Form form={form} layout="vertical" name="census-filters" initialValues={censusFilters} onValuesChange={handleValuesChange}>
                    <Row gutter={48} justify="center" align="middle" className="census-filters">
                        <Col span={3}>
                            <div className="ant-form-item-label">
                                <label>Patient Type</label>
                            </div>

                            <Row>
                                <Col span={24}>
                                    <Form.Item name="isActive" valuePropName="checked">
                                        <Checkbox>Active</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="isDischarged" valuePropName="checked">
                                        <Checkbox>Discharged</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={5}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="Specialties" name="specialties">
                                        <Select mode="multiple" showSearch filterOption={filterOptionsStartsWith} options={getSelectListOptions(specialties, 'name')} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="isNew" valuePropName="checked">
                                        <Checkbox>New</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Regions" name="regions">
                                <Select
                                    mode="multiple"
                                    options={getSelectListOptions(regions, 'regionName')}
                                    showSearch
                                    filterOption={filterOptionsStartsWith}
                                    onChange={handleRegionChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="Locations" name="locations">
                                <Select mode="multiple" options={locations} showSearch filterOption={filterOptions} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            <FiltersMobile handleValuesChange={handleValuesChange} handleRegionChange={handleRegionChange} />
        </Card>
    );
};

export default Filters;
