import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PccPatientClient, PccCensusGroupDto, PccCensusFilterDto, PccCensusDto, UnlinkPccPatientDto } from '@medone/medonehp-api-client';
import { orderBy } from 'lodash';

import { initialState, PccCensusState } from './models';
import { Axios } from '../../../../shared/common/http';
import { handleError } from '../../../../shared/common/HandleErrors';
import { AppDispatch, AppThunk, RootState } from '../../../../shared/store';
import { censusSlice } from '../census/slice';

export const pccCensusSlice = createSlice({
    name: 'pcc-census',
    initialState,
    reducers: {
        setLoading: (state: PccCensusState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
            state.processing = false;
        },

        setProcessing: (state: PccCensusState, action: PayloadAction<boolean>) => {
            state.loading = false;
            state.processing = action.payload;
        },

        setError: (state: PccCensusState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.loading = false;
            state.processing = false;
        },

        setCensus: (state: PccCensusState, action: PayloadAction<PccCensusGroupDto>) => {
            state.census = action.payload;
            state.loading = false;
            state.processing = false;
            state.errorMessage = null;
        },

        setCurrentPccCensus: (state: PccCensusState, action: PayloadAction<PccCensusDto>) => {
            state.currentCensus = action.payload;
        },

        setCensusFilters: (state: PccCensusState, action: PayloadAction<PccCensusDto>) => {
            state.censusFilters = action.payload;
        },

        updateCensus: (state: PccCensusState, action: PayloadAction<PccCensusDto>) => {
            const { patientId } = action.payload;

            if (state.census?.items != null) {
                const index = state.census.items.findIndex((x) => x.patientId === patientId);

                let newItems = [...state.census.items];

                if (index !== -1) {
                    newItems = newItems.map((item) => {
                        if (item.patientId === patientId) {
                            return { ...item, ...action.payload } as PccCensusDto;
                        }

                        return item;
                    });
                } else {
                    newItems.push(action.payload);

                    // Match ordering from server side logic
                    newItems = orderBy(newItems, ['lastName', 'firstName', 'middleName', (x) => x.birthDate?.format('L')], ['asc', 'asc', 'asc', 'asc']);
                }

                const newCensus = { ...state.census, items: newItems } as PccCensusGroupDto;

                state.census = newCensus;
            }
        },
    },
});

export function refreshPccCensus(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { censusFilters } = getState().pccCensus;

        if (censusFilters != null) {
            await dispatch(fetchPccCensus(censusFilters.facilityId, censusFilters.orgUuid, true));
        }
    };
}

export function fetchPccCensus(pccFacilityId: number, orgUuid: string, isRefresh = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        if (!isRefresh) {
            dispatch(pccCensusSlice.actions.setLoading(true));
        }


        const client = new PccPatientClient(null, Axios);

        try {
            const filter = PccCensusFilterDto.fromJS({
                facilityId: pccFacilityId,
                orgUuid,
            });

            dispatch(pccCensusSlice.actions.setCensusFilters(filter));

            const response = await client.census(filter);

            if (response.result.succeeded) {
                dispatch(pccCensusSlice.actions.setCensus(response.result.entity));
            } else {
                handleError(response, () => dispatch(pccCensusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(pccCensusSlice.actions.setError(error.toString())));
        }
    };
}

export function setCurrentPccCensus(item?: PccCensusDto, visible = false): AppThunk {
    return async (dispatch: AppDispatch) => {
        if (visible) {
            dispatch(pccCensusSlice.actions.setCurrentPccCensus(item));
        } else {
            dispatch(pccCensusSlice.actions.setCurrentPccCensus(null));

            // Fix for: https://switchbox.teamwork.com/#/projects/568400/tasks/29909374
            dispatch(censusSlice.actions.setPccPatient(null));
        }
    };
}

export function unlinkPccPatient(item: PccCensusDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PccPatientClient(null, Axios);

        try {
            const dto = UnlinkPccPatientDto.fromJS({
                patientIntakeId: item.patientIntakeId
            });

            const response = await client.unlinkPccPatient(dto);

            if (response.result.succeeded) {
                item.isPatientPccLinked = false;
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export const selectPccLoading = (state: RootState) => state.pccCensus.loading;
export const selectPccProcessing = (state: RootState) => state.pccCensus.processing;
export const selectPccError = (state: RootState) => state.pccCensus.errorMessage;
export const selectPccCensus = (state: RootState) => state.pccCensus.census;
export const selectPccCurrentCensus = (state: RootState) => state.pccCensus.currentCensus;

export default pccCensusSlice.reducer;
