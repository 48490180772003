import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../../shared/store';
import { initialState, AdminState } from './models';
import { reducers as regionsReducers } from './slice.regions';
import { reducers as facilitiesReducers } from './slice.facilities';
import { reducers as patientTypesReducers } from './slice.patient-types';
import { reducers as arrivingFromsReducers } from './slice.arriving-froms';
import { reducers as eventLogsReducers } from './slice.event-logs';
import { reducers as hospitalSystemsReducers } from './slice.hospital-systems';
import { reducers as returnToHospitalConfigReducers } from './slice.return-to-hospital-config';
import { reducers as hospiceCompaniesReducers } from './slice.hospice-intake-companies';

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setError: (state: AdminState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        resetState: (state: AdminState) => {
            state.currentArrivingFrom = null;
            state.currentFacility = null;
            state.currentHospitalSystem = null;
            state.currentPatientType = null;
            state.currentRegion = null;
        },

        ...regionsReducers,
        ...facilitiesReducers,
        ...patientTypesReducers,
        ...arrivingFromsReducers,
        ...eventLogsReducers,
        ...hospitalSystemsReducers,
        ...returnToHospitalConfigReducers,
        ...hospiceCompaniesReducers,
    },
});

export const selectError = (state: RootState) => state.admin.errorMessage;

export default adminSlice.reducer;
