import { BedBoardEnumSelectListDto, BedBoardHospitalDto, BedBoardHospitalFormTypeDto, BedBoardHospitalRequestConfigurationDto } from '@medone/medonehp-bedboard-client';

export interface AdminState {
    hospitals: BedBoardHospitalDto[];
    currentHospital: BedBoardHospitalDto;
    currentHospitalRequestConfigs: BedBoardHospitalRequestConfigurationDto[];
    formTypes: BedBoardHospitalFormTypeDto[];
    requestTypes: BedBoardEnumSelectListDto[];
}

export const initialState: AdminState = {
    hospitals: null,
    currentHospital: null,
    currentHospitalRequestConfigs: null,
    formTypes: null,
    requestTypes: null,
};
