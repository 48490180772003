import { PayloadAction } from '@reduxjs/toolkit';
import {
    ReportsClient,
    ProviderProductivityResult,
    ProductivityReportRequestDto,
    ProductivityDefaultDatesResult,
    ProviderTypes,
    UsersClient,
    UsersRegionProviderFiltersDto,
    UserDto,
} from '@medone/medonehp-api-client';
import moment from 'moment';

import { RootState, AppDispatch, AppThunk } from '../../../shared/store';
import { Axios } from '../../../shared/common/http';
import { handleError } from '../../../shared/common/HandleErrors';
import { ReportingState } from './models';
import { reportingSlice, productivityFilterKey } from './slice';
import { saveFilter } from '../users/slice';

export const reducers = {
    setProductivityReport: (state: ReportingState, action: PayloadAction<ProviderProductivityResult[]>) => {
        state.productivityReport = action.payload;
        state.loading = false;
        state.processing = false;
    },

    setPhysicianAndApps: (state: ReportingState, action: PayloadAction<UserDto[]>) => {
        state.physicianAndApps = action.payload;
    },
};

export function getDefaultDates(): AppThunk<Promise<ProductivityDefaultDatesResult>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            
            const client = new ReportsClient(null, Axios);
            const response = await client.getDefaultProductivityReportDates();

            if (response.result.succeeded) {
                return response.result.entity;
            }

            handleError(response, () => dispatch(reportingSlice.actions.setError(null)));
        } catch (error) {
            handleError(error, () => dispatch(reportingSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function getProductivityReport(providerIds: string[], startDate: moment.Moment, endDate: moment.Moment): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(reportingSlice.actions.setProductivityReport([]));
        dispatch(reportingSlice.actions.setLoading(true));

        
        const client = new ReportsClient(null, Axios);

        try {
            const dto = ProductivityReportRequestDto.fromJS({
                providerIds,
                startDate,
                endDate,
            });

            await dispatch(saveFilter(productivityFilterKey, dto));

            const response = await client.getProductivityReport(dto);

            if (response.result.succeeded) {
                dispatch(reportingSlice.actions.setProductivityReport(response.result.entity.providerProductivityResults));
            } else {
                handleError(response, () => dispatch(reportingSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(reportingSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchPhysicianAndApps(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new UsersClient(null, Axios);

        try {
            const data = UsersRegionProviderFiltersDto.fromJS({ providerTypes: [ProviderTypes.Physician, ProviderTypes.AdvancedPracticePractitioner] });
            const response = await client.getAllByRegion(data);

            if (response.result.succeeded) {
                dispatch(reportingSlice.actions.setPhysicianAndApps(response.result.entity));
            } else {
                handleError(response, () => dispatch(reportingSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(reportingSlice.actions.setError(error.toString())));
        }
    };
}

export const selectProductivityReport = (state: RootState) => state.reporting.productivityReport;
export const selectPhysicianAndApps = (state: RootState) => state.reporting.physicianAndApps;
