/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

function useDebounce(callback, delay: number = 1000, deps = []) {
    const debouncedCallback = useCallback(debounce(callback, delay), [delay, ...deps]);

    useEffect(() => {
        return () => {
            // Make sure to cleanup old debounce callback during re-renders/unmounts to prevent extra network calls
            debouncedCallback.cancel();
        };
    }, [delay, debouncedCallback, ...deps]);

    return debouncedCallback;
}

export default useDebounce;
