import { useEffect, useState } from 'react';
import { Collapse, Modal } from 'antd';
import { PccCensusDto } from '@medone/medonehp-api-client';

import { useAppSelector, useAppDispatch } from '../../../../../../shared/hooks';
import { selectPccCurrentCensus, setCurrentPccCensus } from '../../slice';
import { customExpandIcon } from '../../../../../../shared/common/helpers/collapse';

import PccData from '../../../census/components/patient-charts/pcc-data';
import PccCensuDetails from '../../../census/components/patient-charts/pcc-data/pcc-census-details';

const DetailsModal = () => {
    const dispatch = useAppDispatch();
    const census = useAppSelector<PccCensusDto>(selectPccCurrentCensus);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const showModal = async (visible: boolean) => {
        setIsVisible(visible);

        if (!visible) {
            await dispatch(setCurrentPccCensus(null, false));
        }
    };

    const renderData = () => {
        return census.isPatientPccLinked && census.patientIntakeId ? <PccData patientIntakeId={census.patientIntakeId} alwaysShowData /> : <PccCensuDetails census={census} />;
    };

    useEffect(() => {
        setIsVisible(census != null);
    }, [census]);

    return (
        census && (
            <Modal
                title={`PointClickCare Details for ${census.lastName}, ${census.firstName}`}
                open={isVisible}
                onOk={() => showModal(false)}
                onCancel={() => showModal(false)}
                width="95%"
            >
                <Collapse accordion expandIconPosition="end" expandIcon={customExpandIcon}>
                    {renderData()}
                </Collapse>
            </Modal>
        )
    );
};

export default DetailsModal;
