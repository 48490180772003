import { PccNutritionOrderDto } from '@medone/medonehp-api-client';
import { Table } from 'antd';

import { formatDateTime } from '../../../../../../../shared/common/helpers';

type Props = {
    nutritionOrders: PccNutritionOrderDto[];
};

const NutritionOrders = ({ nutritionOrders }: Props) => {
    return (
        <Table dataSource={nutritionOrders} rowKey="id" scroll={{ x: true }} pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}>
            <Table.Column<PccNutritionOrderDto> title="Oral Diet Type" dataIndex={['oralDiet', 'type']} />
            <Table.Column<PccNutritionOrderDto> title="Consistency Type" dataIndex={['oralDiet', 'fluidConsistencyType']} />
            <Table.Column<PccNutritionOrderDto> title="Texture Modifier" dataIndex={['oralDiet', 'texture', 'modifier']} />
            <Table.Column<PccNutritionOrderDto> title="Order Date & Time" dataIndex="orderDateTime" render={(data) => (data ? formatDateTime(data) : null)} />
            <Table.Column<PccNutritionOrderDto> title="Start Date" dataIndex={['oralDiet', 'startDateTime']} render={(data) => (data ? formatDateTime(data) : null)} />
            <Table.Column<PccNutritionOrderDto> title="End Date" dataIndex={['oralDiet', 'endDateTime']} render={(data) => (data ? formatDateTime(data) : null)} />
            <Table.Column<PccNutritionOrderDto> title="Status" dataIndex="status" />
        </Table>
    );
};

export default NutritionOrders;
