import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { EnumSelectListDto, PatientIntakeSpecialtyDischargeDto, PatientIntakeSpecialtyDto } from '@medone/medonehp-api-client';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { selectSpecialties } from '../../../../shared/common/data/slice';
import { signOffSpecialty } from '../census/slice.specialties';
import { handleError } from '../../../../shared/common/HandleErrors';
import { isInRole } from '../../../../shared/common/helpers';
import { Role, SpecialtyRole } from '../../../../shared/common/auth/RoleAuth';
import { AuthState } from '../../../../shared/common/auth/models';
import { selectAuth } from '../../../../shared/common/auth/slice';

type Props = {
    specialty: PatientIntakeSpecialtyDto;
};

const signOffRoles = [Role.CLINICAL_COORDINATOR, Role.POST_ACUTE_ADMIN];
const specialtyRoles = [SpecialtyRole.Primary, SpecialtyRole.PMR, SpecialtyRole.Wound];

const SpecialtySignOff = ({ specialty }: Props) => {
    const dispatch = useAppDispatch();
    const specialties = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const specialtyEnum = specialties?.find((x) => x.id === specialty.specialty);
    const auth = useAppSelector<AuthState>(selectAuth);

    const handleSignOff = () => {
        Modal.confirm({
            title: `Sign off from ${specialtyEnum.name}`,
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to sign off the patient from this specialty?',
            okText: 'Yes, Sign off',
            cancelText: 'No',
            zIndex: 5000,
            onOk() {
                return new Promise(async (resolve) => {
                    const requestDto = PatientIntakeSpecialtyDischargeDto.fromJS({
                        ...specialty,
                    });

                    const result = await dispatch(signOffSpecialty(requestDto));

                    if (result) {
                        Modal.success({
                            title: 'Success',
                            content: `You have signed off the patient from ${specialtyEnum.name}`,
                            zIndex: 1003,
                        });
                    }

                    resolve(result);
                }).catch((err) => {
                    handleError(err);

                    Modal.error({ title: 'Error', content: 'Unable to sign off specialty due to an error.', zIndex: 1003 });
                });
            },
        });
    };

    return (
        <>
            {isInRole(auth.permissions, [...signOffRoles, specialtyRoles[specialty.specialty - 1]]) && (
                <Button size="small" type="default" className="btn-sign-off" onClick={handleSignOff}>
                    Sign off
                </Button>
            )}
        </>
    );
};

export default SpecialtySignOff;
