import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { UsersClient,  DataListDto, UserDto, UserFiltersClient, AdditionalRoles, AdditionalRolesFiltersDto } from '@medone/medonehp-api-client';
import {  BedBoardUsersClient, BedBoardAssignableUserDto } from '@medone/medonehp-bedboard-client';
import { handleError } from '../../../shared/common/HandleErrors';

import { Axios } from '../../../shared/common/http';
import { AppDispatch, AppThunk, RootState } from '../../../shared/store';
import { fetchRegions } from '../post-acute/admin/slice.regions';
import { reducers as importReducers } from './slice.import';
import { initialState, UsersState } from './models';
import { toCamel } from '../../../shared/common/helpers';

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setLoading: (state: UsersState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        setProcessing: (state: UsersState, action: PayloadAction<boolean>) => {
            state.processing = action.payload;
        },

        setError: (state: UsersState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.loading = false;
            state.processing = false;
        },

        setUsers: (state: UsersState, action: PayloadAction<UserDto[]>) => {
            state.users = action.payload;
            state.currentUser = null;
            state.loading = false;
            state.processing = false;
        },

        setUser: (state: UsersState, action: PayloadAction<UserDto>) => {
            state.currentUser = action.payload;
            state.loading = false;
            state.processing = false;
        },

        setAdditionalRoleUsers: (state: UsersState, action: PayloadAction<UserDto[]>) => {
            state.additionalRoleUsers = action.payload;
        },

        setBedboardAssignableUsers: (state: UsersState, action: PayloadAction<BedBoardAssignableUserDto[]>) => {
            state.bedboardAssignableUsers = action.payload;
        },

        ...importReducers,
    },
});

export function fetchUsers(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(usersSlice.actions.setLoading(true));

        
        const client = new UsersClient(null, Axios);

        try {
            const response = await client.getAll();

            if (response.result.succeeded) {
                dispatch(usersSlice.actions.setUsers(response.result.entity));
            } else {
                handleError(response, () => dispatch(usersSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(usersSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchUser(userId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(usersSlice.actions.setLoading(true));

        
        const client = new UsersClient(null, Axios);

        try {
            const response = await client.getById(userId);

            if (response.result.succeeded) {
                await dispatch(fetchRegions());

                dispatch(usersSlice.actions.setUser(response.result.entity));
            } else {
                handleError(response, () => dispatch(usersSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(usersSlice.actions.setError(error.toString())));
        }
    };
}

export function updateUser(user: UserDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(usersSlice.actions.setProcessing(true));

        
        const client = new UsersClient(null, Axios);

        try {
            const response = await client.post(user);

            if (response.result.succeeded) {
                dispatch(usersSlice.actions.setUser(response.result.entity));
            } else {
                handleError(response, () => dispatch(usersSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(usersSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function fetchFilter(filterKey: string, defaultValue?: any): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new UserFiltersClient(null, Axios);

        try {
            const response = await client.getByKey(filterKey);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(usersSlice.actions.setError(null)));
            }

            if (response.result.entity != null) {
                const json = JSON.parse(response.result.entity.filterValue || '');
                return toCamel(json);
            }
        } catch (error) {
            console.error(error);
        }

        return defaultValue || {};
    };
}

export function saveFilter(filterKey: string, filters: any): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new UserFiltersClient(null, Axios);

        try {
            const response = await client.save(filterKey, filters);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(usersSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(usersSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchUsersByAdditionalRoles(regionIds?: number[], additionalRole?: AdditionalRoles): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new UsersClient(null, Axios);

        try {
            const data = AdditionalRolesFiltersDto.fromJS({ regionIds, additionalRole });
            const response = await client.getByAdditionalRoles(data);

            if (response.result.succeeded) {
                dispatch(usersSlice.actions.setAdditionalRoleUsers(response.result.entity));
            } else {
                handleError(response, () => dispatch(usersSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(usersSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchBedboardAssignableUsers(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(usersSlice.actions.setLoading(true));

        
        const client = new BedBoardUsersClient( null, Axios);

        try {
            const response = await client.getAllBedboardAssignable();

            if (response.result.succeeded) {
                dispatch(usersSlice.actions.setBedboardAssignableUsers(response.result.entity));
            } else {
                handleError(response, () => dispatch(usersSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(usersSlice.actions.setError(error.toString())));
        }
    };
}

export const selectItems = (state: RootState) => state.data.items;
export const selectUserSuffixes = createSelector(selectItems, (data: DataListDto) => data?.userSuffixes ?? []);
export const selectProviderTypes = createSelector(selectItems, (data: DataListDto) => data?.providerTypes ?? []);
export const selectLoading = (state: RootState) => state.users.loading;
export const selectProcessing = (state: RootState) => state.users.processing;
export const selectError = (state: RootState) => state.users.errorMessage;
export const selectUsers = (state: RootState) => state.users.users;
export const selectUser = (state: RootState) => state.users.currentUser;
export const selectAdditionalRoleUsers = (state: RootState) => state.users.additionalRoleUsers;
export const selectBedboardAssignableUsers = (state: RootState) => state.users.bedboardAssignableUsers;

export default usersSlice.reducer;
