import { ProviderProductivityResult, UserDto } from '@medone/medonehp-api-client';

export interface ReportingState {
    loading: boolean;
    processing: boolean;
    errorMessage: string | null;
    productivityReport: ProviderProductivityResult[];
    physicianAndApps: UserDto[];
}

export const initialState: ReportingState = {
    loading: false,
    processing: false,
    errorMessage: null,
    productivityReport: [],
    physicianAndApps: [],
};
