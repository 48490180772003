import { HubConnection } from '@microsoft/signalr';

import { store } from '../../../../../shared/store';
import { PostAcutePaths } from '../../index.routes';
import { fetchNewAdmits } from '../slice';
import { debounce } from 'lodash';
import { SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions } from '../../../../../Config';

const handleRefresh = async () => {
    // Only reload if the user is currently on the new admits page
    if (window.location.pathname === PostAcutePaths.NewAdmits.Index) {
        await store.dispatch(fetchNewAdmits());
    }
};

export const initNewAdmitsHubConnection = (connection: HubConnection) => {
    connection.on('PatientIntakeAdded', debounce(async () => {
        await handleRefresh();
    }, SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions));

    connection.on('PatientIntakeUpdated', debounce(async () => {
        await handleRefresh();
    }, SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions));

    connection.on('PatientIntakeDischarged', debounce(async () => {
        await handleRefresh();
    }, SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions));
};
