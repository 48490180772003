import { useState } from 'react';
import { Button, Row, Form, Input, Modal } from 'antd';
import { CensusDto, VisitDto } from '@medone/medonehp-api-client';

import { postAdHocVisit } from '../../schedule/slice.schedules';
import { useAppDispatch } from '../../../../../shared/hooks';
import { handleError } from '../../../../../shared/common/HandleErrors';

type Params = {
    census?: CensusDto;
    visit?: VisitDto;
    providerId?: string;
    showSkipReason: boolean;
    onCancel: () => void;
    onFinish: () => void;
};

const SkippedForm = ({ census, visit, providerId, showSkipReason, onCancel, onFinish }: Params) => {
    const dispatch = useAppDispatch();
    const [processing, setProcessing] = useState<boolean>(false);
    const [form] = Form.useForm();

    const handleFinishForm = async () => {
        setProcessing(true);

        try {
            const values = await form.validateFields();
            const { skippedReason } = values;

            // If this is coming from the census page, this is an ad-hoc visit
            const patchedValues = {
                ...visit,
                facilityId: census?.admittedToId,
                facilityType: census?.facilityType,
                patientIntakeId: census?.patientIntakeId,
                providerId,
                noteType: null, // Clear value if skipped?
                isSkipped: true,
                skippedReason,
            } as VisitDto;

            await dispatch(postAdHocVisit(patchedValues));

            form.resetFields();

            onFinish();
        } catch (errors) {
            handleError(errors, () => true);
        }

        setProcessing(false);
    };

    return (
        <Modal title={`Skipped Reason for ${census?.name}`} open={showSkipReason} onCancel={onCancel} confirmLoading={processing} footer={null}>
            <Form name="skip-form" autoComplete="off" layout="vertical" form={form} onFinish={handleFinishForm}>
                <Form.Item label="Please provide a reason" name="skippedReason" rules={[{ required: true, message: 'Reason is required.' }]}>
                    <Input.TextArea autoSize={{ minRows: 2 }} />
                </Form.Item>

                <Row justify="end" className="form-actions">
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
};

export default SkippedForm;
