import { BedBoardHospitalDto, BedBoardIntakeDto } from '@medone/medonehp-bedboard-client';

export interface BedBoardIntakeFormState {
    errorMessage: string;
    intake: BedBoardIntakeDto;
    hospital: BedBoardHospitalDto;
}

export const initialState: BedBoardIntakeFormState = {
    errorMessage: null,
    intake: null,
    hospital: null,
};
