import { Popover, Tag } from 'antd';
import { PatientTypeDto, QuickNoteDto } from '@medone/medonehp-api-client';
import { BedBoardQuickNoteDto } from '@medone/medonehp-bedboard-client';
import { FileTextOutlined } from '@ant-design/icons';
import { isNullOrEmpty } from '.';

export const renderPatientTypes = (patientTypes: PatientTypeDto[], patientTypeIds?: string) => {
    if (!patientTypeIds || !patientTypes) {
        return null;
    }

    const typeIds = JSON.parse(patientTypeIds);
    const types = typeIds?.map((type) => {
        return patientTypes.find((x) => x.id === type);
    });

    return (
        types?.length > 0 &&
        types
            .filter((type) => type != null)
            .map((type) => (
                <Tag key={type.id} color="#ababab">
                    {
                        !isNullOrEmpty(type.tooltipContent)
                            ? <Popover overlayClassName="note-preview-popover" content={() => <div className="note-body">{renderHtml(type.tooltipContent)}</div>} placement="bottom" title={type.description}>
                                {type.description}
                            </Popover>
                            : <>{type.description}</>
                    }

                </Tag>
            ))
    );
};

export const renderNotOnFile = (notOnFile: boolean, notOnFileText: string, onClick: () => void) => {
    return notOnFile ? <span>{notOnFileText}</span> : <FileTextOutlined onClick={onClick} />;
};

export const renderHtml = (html: string) => {
    const txt = document.createElement('textarea');

    txt.innerHTML = html;

    return <div className="element-with-rendered-html" dangerouslySetInnerHTML={{ __html: txt.value }} />;
};

export const renderQuickNoteCreatedBy = (note: QuickNoteDto | BedBoardQuickNoteDto) => {
    return note.isSentFromAcoQualityCommittee
        ? "ACO Quality Committee"
        : note.createdByName;
};
