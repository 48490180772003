import { useState } from 'react';
import { Radio, Popconfirm, Spin } from 'antd';
import moment, { Moment } from 'moment';
import { EnumSelectListDto, DischargeDispositionChangeDto, UndoDischargeDto, CensusDto, DischargeDto, PatientDispositions, VisitDto } from '@medone/medonehp-api-client';
import { toast } from 'react-toastify';

import { formatDateTime } from '../../../../../shared/common/helpers';
import { selectPatientDispositions } from '../../../../../shared/common/data/slice';
import { useAppSelector, useAppDispatch } from '../../../../../shared/hooks';
import { updatePatientDisposition, readmitPatient } from '../../census/slice';

import CustomDatePicker from '../../../../../shared/common/components/CustomDatePicker';

import { handleError } from '../../../../../shared/common/HandleErrors';
import { updateDischargeDate } from '../../census/slice.patient-intakes';
import CustomSelect, { getCustomSelectListOptions } from '../../../../../shared/common/components/CustomSelect';
import classNames from 'classnames';

type Props = {
    census: CensusDto;
    visit: VisitDto;
};

const DischargeButtons = ({ census, visit }: Props) => {
    const dispatch = useAppDispatch();
    const dispositions = useAppSelector<EnumSelectListDto[]>(selectPatientDispositions);
    const activeClass = census.dischargeDate ? 'discharged' : 'readmit';
    const [dischargeDateValue, setDischargeDate] = useState<Moment>(census.dischargeDate);
    const [dischargeDateUpdating, setDischargeDateUpdating] = useState<boolean>(false);
    const returnFromEdClasses = classNames({
        complete: !census.dischargeDate,
    });

    const handleReadmit = async () => {
        const dto = {
            patientIntakeId: census.patientIntakeId,
            visitId: visit?.id ?? 0,
        } as UndoDischargeDto;

        await dispatch(readmitPatient(dto));
    };

    const handleChangeDischargeDate = async (e: any) => {
        setDischargeDateUpdating(true);

        try {
            const { value } = e.target;
            const date = moment(value).startOf('day');
            const dto = {
                patientIntakeId: census.patientIntakeId,
                patientDisposition: PatientDispositions.Other, // This is actually ignored by the update discharge date api, just needed to pass validation
                dischargeDate: date,
            } as DischargeDto;

            await dispatch(updateDischargeDate(dto));

            setDischargeDate(date);

            toast.success(`Discharge Date has been updated to ${formatDateTime(date)} for ${census.name}`);
        } catch (error) {
            handleError(error, () => true);
        }

        setDischargeDateUpdating(false);
    };

    const handleChangeDisposition = async (dispositionId: number) => {
        const dto = {
            patientIntakeId: census.patientIntakeId,
            patientDisposition: dispositionId,
        } as DischargeDispositionChangeDto;

        await dispatch(updatePatientDisposition(dto));
    };

    return (
        <>
            <Spin spinning={dischargeDateUpdating}>
                <div className="discharge-datepicker">
                    <span>Discharge Date:</span>

                    <CustomDatePicker value={dischargeDateValue} max={moment().format('YYYY-MM-DD')} onChange={handleChangeDischargeDate} />
                </div>
            </Spin>

            <CustomSelect
                dropdownMatchSelectWidth={false}
                value={census.patientDisposition}
                options={getCustomSelectListOptions(dispositions, 'name')}
                onChange={(data) => handleChangeDisposition(data)}
            />

            <Radio.Group className="visit-types-menu ml-2">
                <Radio.Button value="discharged" className={activeClass === 'discharged' ? 'complete' : ''}>
                    Discharged
                </Radio.Button>

                {census.canReturnFromED ? (
                    <Popconfirm
                        title="Are you sure you want to Return from ED for this patient?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={handleReadmit}
                        onCancel={(evt) => evt.stopPropagation()}
                    >
                        <Radio.Button value="readmit" className={returnFromEdClasses}>
                            Return from ED
                        </Radio.Button>
                    </Popconfirm>
                ) : (
                    <Popconfirm
                        title="Are you sure you want to undo the discharge for this patient?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={handleReadmit}
                        onCancel={(evt) => evt.stopPropagation()}
                    >
                        <Radio.Button value="readmit" className={returnFromEdClasses}>
                            Undo Discharge
                        </Radio.Button>
                    </Popconfirm>
                )}
            </Radio.Group>
        </>
    );
};

export default DischargeButtons;
