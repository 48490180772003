import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { FacilityQuickNotesDto } from '@medone/medonehp-api-client';

import { fetchFacilityQuickNotes, selectFacilityQuickNotes } from '../slice.notes';
import { useAppSelector, useAppDispatch } from '../../../../../shared/hooks';
import { setFacilityQuickNotes } from '../slice';

import QuickNoteTimeline from '../../components/quicknote-timeline';
import FacilityQuickNoteBadges from './facility-quick-notes-badges';

type Props = {
    isVisible: boolean;
    toggleVisible: React.Dispatch<React.SetStateAction<boolean>>;
    facilityId: number;
    qnFacilityName: string;
};

const FacilityQuickNotesModal = ({ isVisible, toggleVisible, facilityId, qnFacilityName }: Props) => {
    const dispatch = useAppDispatch();
    const quickNotes = useAppSelector<FacilityQuickNotesDto>(selectFacilityQuickNotes);

    const renderTitle = () => {
        return (
            <>
                <span className="mr-1">{`Quick Notes for ${qnFacilityName}`}</span> <FacilityQuickNoteBadges facilityQuickNotes={quickNotes} />
            </>
        );
    };

    useEffect(() => {
        if (isVisible && facilityId) {
            dispatch(fetchFacilityQuickNotes(facilityId));
        } else {
            dispatch(setFacilityQuickNotes(null));
        }
    }, [dispatch, facilityId, isVisible]);

    return (
        <Modal title={renderTitle()} open={isVisible} onCancel={() => toggleVisible(false)} className="facility-qn-modal quicknote-timeline-container" footer={null}>
            <QuickNoteTimeline facilityId={facilityId} facilityQuickNotes={quickNotes} />
        </Modal>
    );
};

export default FacilityQuickNotesModal;
