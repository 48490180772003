import { Children, cloneElement, ReactElement } from 'react';

import { selectPermissions } from './slice';
import { useAppSelector } from '../../hooks';

export enum Role {
    POST_ACUTE_ADMIN = 'PostAcuteAdmin',
    POST_ACUTE_PHYSICIAN = 'PostAcutePhysician',
    CLINICAL_COORDINATOR = 'ClinicalCoordinator',
    POST_ACUTE_ADVANCED_PRACTICE_PRACTITIONER = 'PostAcuteAdvancedPracticePractitioner',
    PHYSICIAN = 'Physician',
    ADVANCED_PRACTICE_PRACTITIONER = 'AdvancedPracticePractitioner',
    ROUTE_BUILDER = 'RouteBuilder',
    SCRIBE = 'PhysicianScribe',
    VIEWER = 'Viewer',
    SYSADMIN = 'SystemAdmin',

    BEDBOARD_ADMIN = 'BedBoardAdmin',
    BEDBOARD_USER = 'BedBoardUser',

    ACO_QUALITY_COMMITTEE = 'AcoQualityCommittee',
}

export enum SpecialtyRole {
    Primary = 'Primary',
    PMR = 'PMR',
    Wound = 'WoundCare',
}

export const AnyProvider: Role[] = [
    // All all the roles from CustomClaims.AnyProvider
    Role.PHYSICIAN,
    Role.POST_ACUTE_PHYSICIAN,
    Role.ADVANCED_PRACTICE_PRACTITIONER,
    Role.POST_ACUTE_ADVANCED_PRACTICE_PRACTITIONER,
];

export const AnyProviderWithScribe: Role[] = [...AnyProvider, Role.SCRIBE];
export const RolesAdminOrAnyProvider = [Role.SYSADMIN, Role.POST_ACUTE_ADMIN, ...AnyProvider];
export const RolesAdminOrAnyProviderWithScribe = [Role.SYSADMIN, Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe];
export const AdminRoles = [Role.SYSADMIN, Role.POST_ACUTE_ADMIN, Role.BEDBOARD_ADMIN];
export const PostAcuteRoles = [Role.ROUTE_BUILDER, Role.VIEWER, Role.CLINICAL_COORDINATOR, ...RolesAdminOrAnyProviderWithScribe];
export const BedBoardRoles = [Role.SYSADMIN, Role.BEDBOARD_ADMIN, Role.BEDBOARD_USER];
export const ReportingRoles = [Role.SYSADMIN, Role.POST_ACUTE_ADMIN];

type Params = {
    children: any;
    roles: Role[];
    notAuthedElement?: ReactElement;
};

const RoleAuth = ({ children, roles, notAuthedElement, ...props }: Params) => {
    const userRoles = useAppSelector<string[]>(selectPermissions);

    const childrenWithProps = Children.map(children, (child) => {
        return cloneElement(child, props);
    });

    if ((roles || []).some((r) => (userRoles || []).includes(r))) {
        return <>{childrenWithProps}</>;
    } else if (notAuthedElement != null) {
        return <>{notAuthedElement}</>;
    } else {
        return null;
    }
};

export default RoleAuth;
