import { useEffect, useState } from 'react';
import { Table, Tooltip, Collapse, Tag } from 'antd';
import { ExclamationCircleTwoTone, ScheduleOutlined } from '@ant-design/icons';
import { RouteBuilderGroupDto, RouteBuilderDto, VisitTypes, RequiredVisitTypes, AwvEligibilityStatus } from '@medone/medonehp-api-client';
import { uniq, uniqBy } from 'lodash';

import { sortWithNullsAtBottom } from '../../../../../shared/common/helpers/table';
import { formatDateTime } from '../../../../../shared/common/helpers';
import { useAppSelector } from '../../../../../shared/hooks';
import { visitDueSelectors } from '../../census/slice';

import RouteBuilderBadges from './badges';
import VisitDueBadge from '../../census/components/badges/visit-due';
import VisitTypeBadge from '../../census/components/badges/visit-type';
import ProviderTypeBadge from '../../census/components/badges/provider-type';
import { getStoredTableState, LocalStorageComponentKeys, LocalStoragePageKeys, storeTableState } from '../../../../../shared/common/helpers/page-state-helpers';

const { Column } = Table;

type Props = {
    group: RouteBuilderGroupDto;
    selectedRows: RouteBuilderDto[];
    filteredVisitTypes: number[];
    setSelectedRows: (selected: any[]) => void;
};

const PatientTable = ({ group, selectedRows, setSelectedRows, filteredVisitTypes, ...props }: Props) => {
    const visitDues = useAppSelector((state) => visitDueSelectors.selectAll(state.census.visitDues));
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(selectedRows.map((x) => `${x.patientIntakeId ?? 0}-${x.name}`));
    const [sorterInfo, setSorter] = useState<any>();

    const getItems = () => {
        if (filteredVisitTypes && filteredVisitTypes.length) {
            return group.items.filter((x) => filteredVisitTypes.includes(x.visitType));
        }

        return group.items;
    };

    const [filteredItems, setFilteredItems] = useState<RouteBuilderDto[]>(getItems());

    const onSelectChange = (rowKeys: React.Key[], rows: RouteBuilderDto[]) => {
        setSelectedRowKeys(rowKeys);

        const newSelectedRows = rows.map((row) =>
            RouteBuilderDto.fromJS({
                admittedToId: row.admittedToId,
                patientIntakeId: row.patientIntakeId,
            })
        );

        setSelectedRows([...newSelectedRows, ...selectedRows.filter((x) => x.admittedToId !== group.admittedToId)]);
    };

    const renderIcons = (data: RouteBuilderDto) => {
        let tooltipContent: JSX.Element = null;
        const isSkipped = data.skippedReason != null && data.skippedReason !== '';

        if (data.lastVisitedBy != null && data.lastVisitedBy !== '') {
            tooltipContent = <div>Last Visited by: {data.lastVisitedBy}</div>;
        }

        if (isSkipped) {
            tooltipContent = (
                <>
                    {tooltipContent}

                    <div>Skipped Reason: {data.skippedReason}</div>
                </>
            );
        }

        const icon = isSkipped ? <ExclamationCircleTwoTone twoToneColor="#e74c3c" /> : <ScheduleOutlined twoToneColor="#0079c2" />;

        return tooltipContent != null && <Tooltip title={tooltipContent}>{icon}</Tooltip>;
    };

    const selectBulk = (evt: React.SyntheticEvent, bulk: RouteBuilderDto[]) => {
        evt.stopPropagation();

        const existingRows = filteredItems.filter((x) => selectedRowKeys.includes(`${x.patientIntakeId}-${x.name}`));
        const keys: React.Key[] = bulk.map((admit) => `${admit.patientIntakeId ?? 0}-${admit.name}`);

        onSelectChange(uniq([...keys, ...selectedRowKeys]), uniqBy([...bulk, ...existingRows], 'patientIntakeId'));
    };

    const filterBulk = (type: string) => {
        const visitType = type === 'newAdmits' ? VisitTypes.HistoryAndPhysical : VisitTypes.FollowUp;
        const filtered = filteredItems.filter((x) => x.visitType === visitType);

        if (type === 'newAdmits') {
            return filtered;
        }

        const requiredVisitType = type === 'physicianFollowUps' ? RequiredVisitTypes.Physician : RequiredVisitTypes.PhysicianOrApp;

        return filtered.filter((x) => x.providerVisitType === requiredVisitType);
    };

    const selectedBulk = (bulk: RouteBuilderDto[]) =>
        bulk.reduce((acc, current) => (selectedRowKeys.includes(`${current.patientIntakeId ?? 0}-${current.name}`) ? acc + 1 : acc), 0);

    const renderBulkAssignTag = (type: string) => {
        let title = 'Number of Admissions H&P needed';
        let tag = 'New Admits';
        const filtered = filterBulk(type);
        const bulk = selectedBulk(filtered);

        if (type === 'physicianFollowUps') {
            title = 'MD Visits needed';
            tag = 'MD';
        } else if (type === 'physicianOrAppFollowUps') {
            title = 'MD/APP Visits needed';
            tag = 'MD/APP';
        }

        return (
            <Tooltip key={`${group.admittedToId}-${type}`} title={title}>
                <Tag color={group[type] > 0 && group[type] === bulk ? '#41bdf6' : '#6c8095'} onClick={(evt) => selectBulk(evt, filtered)}>
                    {tag}: {group[type] || 0}
                </Tag>
            </Tooltip>
        );
    };

    const renderExtra = () => {
        const tags = [];

        if (group != null) {
            tags.push(renderBulkAssignTag('newAdmits'));
            tags.push(renderBulkAssignTag('physicianFollowUps'));
            tags.push(renderBulkAssignTag('physicianOrAppFollowUps'));

            return <>{tags}</>;
        }

        return null;
    };

    const renderAWV = (item: RouteBuilderDto) => {
        const fontClass = item.awvEligibilityStatus === AwvEligibilityStatus.EligibleWithRecentQueue ? 'text-green' : '';

        return item.awvEligibilityStatus !== AwvEligibilityStatus.NotEligible && <span className={fontClass}>AWV</span>;
    };

    useEffect(() => {
        const tableState = getStoredTableState([LocalStoragePageKeys.routebuilder, LocalStorageComponentKeys.patientTable]);

        if (!tableState) {
            return;
        }

        setSorter(tableState.sorter);
    }, []);

    useEffect(() => {
        setFilteredItems(getItems());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group, filteredVisitTypes]);

    useEffect(() => {
        if (!selectedRows.length) {
            setSelectedRowKeys([]); // Clear the table selection when the selected items is cleared (i.e. After assign patients)
        }
    }, [selectedRows]);

    return (
        <Collapse.Panel {...props} className="mb-0" header={group.admittedTo} extra={renderExtra()} key={group.admittedToId}>
            <Table
                size="small"
                rowKey={(x) => `${x.patientIntakeId ?? 0}-${x.name}`}
                pagination={false}
                dataSource={filteredItems}
                className="route-builder-table"
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onSelectChange,
                }}
                onChange={(pagination, filters, sorter, { currentDataSource }) => {
                    storeTableState([LocalStoragePageKeys.routebuilder, LocalStorageComponentKeys.patientTable], { pagination, filters, sorter });
                    setSorter(sorter);
                }}
            >
                <Column<RouteBuilderDto>
                    title="Patient Name"
                    key="name"
                    sorter={(a, b, direction) => sortWithNullsAtBottom(a, b, direction, 'name')}
                    sortOrder={(sorterInfo?.columnKey === 'name' && sorterInfo.order) || null}
                    render={(data) => (
                        <>
                            {data.name} {renderIcons(data)}
                            <RouteBuilderBadges patientIntakeId={data.patientIntakeId} patientTypeIds={data.patientTypeIds} canReturnFromED={data.canReturnFromED} />
                        </>
                    )}
                />

                <Column<RouteBuilderDto>
                    title="AWV"
                    key="awvEligibilityStatus"
                    width={120}
                    sorter={(a, b, direction) => sortWithNullsAtBottom(a, b, direction, 'awvEligibilityStatus')}
                    sortOrder={(sorterInfo?.columnKey === 'awvEligibilityStatus' && sorterInfo.order) || null}
                    render={(item) => renderAWV(item)}
                />

                <Column<RouteBuilderDto>
                    title="Visit Due"
                    key="overDueInDays"
                    sorter={(a, b, direction) => {
                        const itemA = visitDues.find((x) => x.patientIntakeId === a.patientIntakeId);
                        const itemB = visitDues.find((x) => x.patientIntakeId === b.patientIntakeId);

                        return sortWithNullsAtBottom(itemA, itemB, direction, 'overDueInDays');
                    }}
                    sortOrder={(sorterInfo?.columnKey === 'overDueInDays' && sorterInfo.order) || null}
                    render={(data: RouteBuilderDto) => <VisitDueBadge admissionDate={data.admissionDate} admittedToId={data.admittedToId} patientIntakeId={data.patientIntakeId} />}
                />

                <Column<RouteBuilderDto>
                    title="Provider Type"
                    key="providerVisitType"
                    onFilter={(value, record) => {
                        const item = visitDues.find((x) => x.patientIntakeId === record.patientIntakeId);

                        return item?.providerVisitType === value;
                    }}
                    width={150}
                    sorter={(a, b, direction) => {
                        const itemA = visitDues.find((x) => x.patientIntakeId === a.patientIntakeId);
                        const itemB = visitDues.find((x) => x.patientIntakeId === b.patientIntakeId);

                        return sortWithNullsAtBottom(itemA, itemB, direction, 'providerVisitType');
                    }}
                    sortOrder={(sorterInfo?.columnKey === 'providerVisitType' && sorterInfo.order) || null}
                    render={(data: RouteBuilderDto) => <ProviderTypeBadge patientIntakeId={data.patientIntakeId} />}
                />

                <Column<RouteBuilderDto>
                    title="Visit Type(s)"
                    key="visitType"
                    sorter={(a, b, direction) => {
                        const itemA = visitDues.find((x) => x.patientIntakeId === a.patientIntakeId);
                        const itemB = visitDues.find((x) => x.patientIntakeId === b.patientIntakeId);

                        return sortWithNullsAtBottom(itemA, itemB, direction, 'visitType');
                    }}
                    sortOrder={(sorterInfo?.columnKey === 'visitType' && sorterInfo.order) || null}
                    render={(data: RouteBuilderDto) => <VisitTypeBadge patientIntakeId={data.patientIntakeId} />}
                />

                <Column<RouteBuilderDto>
                    title="Last Visit (days)"
                    key="visitServiceDate"
                    sorter={(a, b, direction) => sortWithNullsAtBottom(a, b, direction, 'visitServiceDate')}
                    sortOrder={(sorterInfo?.columnKey === 'visitServiceDate' && sorterInfo.order) || null}
                    render={(data: RouteBuilderDto) => formatDateTime(data.visitServiceDate, '', 'L', data.visitServiceDateDaysPassed)}
                />

                <Column<RouteBuilderDto>
                    title="Assigned"
                    key="assignedProvider"
                    dataIndex="assignedProvider"
                    sorter={(a: RouteBuilderDto, b: RouteBuilderDto, direction) => sortWithNullsAtBottom(a, b, direction, 'assignedProvider')}
                    sortOrder={(sorterInfo?.columnKey === 'assignedProvider' && sorterInfo.order) || null}
                />
            </Table>
        </Collapse.Panel>
    );
};

export default PatientTable;
