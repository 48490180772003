import { Row, Col, Tag } from 'antd';
import { RouteBuilderDto, UserDto, ScheduleProviderGroupDto } from '@medone/medonehp-api-client';
import { toast } from 'react-toastify';

type Props = {
    providers: UserDto[];
    schedules: ScheduleProviderGroupDto[];
    selectedRows: RouteBuilderDto[];
    processing: boolean;
    hidden: boolean;
    handlePostSchedule: (evt, providerId) => void;
};

const ProvidersBanner = ({ providers, schedules, selectedRows, processing, hidden, handlePostSchedule }: Props) => {
    return (
        <Row className={`provider-selector ${!hidden && 'not-hidden p-1'}`}>
            <Col span={2}>
                <h3 className="mb-0 ml-1" style={{ color: 'white' }}>
                    Providers
                </h3>
            </Col>

            <Col span={22}>
                {providers.map((provider) => {
                    const schedule = schedules.find((x) => x.providerId === provider.adSid);
                    let patientTotal = 0;

                    if (schedule) {
                        patientTotal = schedule.items.reduce((acc, curr) => acc + curr.items.length, patientTotal);
                    }

                    return (
                        <Tag
                            key={provider.adSid}
                            color="#FFF"
                            className="ml-1"
                            onClick={(evt) => {
                                if (processing) {
                                    toast.warn('Please wait for the previous process to finish.');
                                    return;
                                }

                                if (!selectedRows.length) {
                                    toast.warning('Select patients to assign to provider.');
                                    return;
                                }

                                if (processing) {
                                    toast.warning('Assign operation is ongoing, try again shortly.');
                                    return;
                                }

                                handlePostSchedule(evt, provider.adSid);
                            }}
                        >
                            {`${provider.firstName} ${provider.lastName}: ${patientTotal}`}
                        </Tag>
                    );
                })}
            </Col>
        </Row>
    );
};

export default ProvidersBanner;
