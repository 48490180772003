import { HubConnection } from '@microsoft/signalr';
import { ArrivingFromLookupDto, FacilityDto, PatientTypeDto, RegionDto } from '@medone/medonehp-api-client';

import { store } from '../../../../../shared/store';

import { syncArrivingFrom } from '../slice.arriving-froms';
import { fetchEventLogs } from '../slice.event-logs';
import { syncFacility } from '../slice.facilities';
import { syncPatientTypes } from '../slice.patient-types';
import { syncRegion } from '../slice.regions';
import { debounce } from 'lodash';
import { SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions } from '../../../../../Config';

export const initAdminHubConnection = (connection: HubConnection) => {
    connection.on('RegionAdded', (dto: RegionDto) => {
        const convertedDto = RegionDto.fromJS(dto);

        store.dispatch(syncRegion(convertedDto));
    });

    connection.on('FacilityAdded', (dto: FacilityDto) => {
        const convertedDto = FacilityDto.fromJS(dto);

        store.dispatch(syncFacility(convertedDto));
    });

    connection.on('PatientTypeAdded', (dto: PatientTypeDto) => {
        const convertedDto = PatientTypeDto.fromJS(dto);

        store.dispatch(syncPatientTypes(convertedDto));
    });

    connection.on('ArrivingFromAdded', (dto: ArrivingFromLookupDto) => {
        const convertedDto = ArrivingFromLookupDto.fromJS(dto);

        store.dispatch(syncArrivingFrom(convertedDto));
    });

    connection.on('EventLogAdded', debounce(() => {
        const { eventLogFilter } = store.getState().admin;

        store.dispatch(fetchEventLogs(eventLogFilter));
    }, SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions));
};
