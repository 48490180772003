import {
    RegionDto,
    FacilityDto,
    PatientTypeDto,
    ArrivingFromLookupDto,
    AntdTableResponseOfListOfEventLogDto,
    EventLogSearchDto,
    HospitalSystemDto,
    UserDto,
    ReturnToHospitalConfigurationDto,
    HospiceCompanyDto,
} from '@medone/medonehp-api-client';

export interface AdminState {
    errorMessage: string | null;
    regions: RegionDto[];
    currentRegion: RegionDto;
    facilities: FacilityDto[];
    currentFacility: FacilityDto;
    patientTypes: PatientTypeDto[];
    currentPatientType: PatientTypeDto;
    arrivingFroms: ArrivingFromLookupDto[];
    currentArrivingFrom: ArrivingFromLookupDto;
    eventLogs: AntdTableResponseOfListOfEventLogDto;
    eventLogFilter: EventLogSearchDto;
    hospitalSystems: HospitalSystemDto[];
    currentHospitalSystem: HospitalSystemDto;
    users: UserDto[];
    physicianUsers: UserDto[];
    appUsers: UserDto[];
    woundProviders: UserDto[];
    returnToHospitalConfigurations: ReturnToHospitalConfigurationDto[];
    hospiceCompanies: HospiceCompanyDto[];
    currentHospiceCompany: HospiceCompanyDto;
}

export const initialState: AdminState = {
    errorMessage: null,
    regions: null,
    currentRegion: null,
    facilities: null,
    currentFacility: null,
    patientTypes: null,
    currentPatientType: null,
    arrivingFroms: null,
    currentArrivingFrom: null,
    eventLogs: null,
    eventLogFilter: null,
    hospitalSystems: null,
    currentHospitalSystem: null,
    users: [],
    physicianUsers: [],
    appUsers: [],
    woundProviders: [],
    returnToHospitalConfigurations: [],
    hospiceCompanies: [],
    currentHospiceCompany: null,
};
