import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { CensusBadgeDto, ProviderSchedulesDto, ScheduledPatientIntakeStatsDto } from '@medone/medonehp-api-client';

export const scheduleBadgesAdapter = createEntityAdapter<CensusBadgeDto, number>({
    selectId: (item) => item.patientIntakeId,
});

export interface ScheduleState {
    errorMessage: string;
    schedules: ProviderSchedulesDto[];
    currentProviderId?: string;
    stats: ScheduledPatientIntakeStatsDto;
    badges: EntityState<CensusBadgeDto, number>;
}

export const initialState: ScheduleState = {
    errorMessage: null,
    schedules: [],
    currentProviderId: null,
    stats: null,
    badges: scheduleBadgesAdapter.getInitialState({}),
};
