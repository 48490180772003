import { ReturnToHospitalConfigurationDto } from '@medone/medonehp-api-client';

type Props = {
    returnToHospitalConfigurations: ReturnToHospitalConfigurationDto[];
    selectedValues: number[];
    feedback?: string;
};

const ReturnToHospitalOutput = ({ returnToHospitalConfigurations, selectedValues, feedback }: Props) => {
    const renderConfigChildren = (configItem: ReturnToHospitalConfigurationDto) => {
        if (configItem.children) {
            return (
                <ul>
                    {configItem.children
                        .filter((item) => selectedValues.includes(item.id))
                        .map((item) => (
                            <li key={item.id}>
                                {item.description}
                                {renderConfigChildren(item)}
                            </li>
                        ))}
                </ul>
            );
        }

        return null;
    };

    return (
        <>
            {returnToHospitalConfigurations && (
                <ul>
                    {returnToHospitalConfigurations
                        .filter((item) => selectedValues.includes(item.id))
                        .map((item) => (
                            <li key={item.id}>
                                {item.description}
                                {renderConfigChildren(item)}
                            </li>
                        ))}
                </ul>
            )}

            {feedback && (
                <div className="mt-2">
                    <p className="mb-1">
                        <strong>Physician Feedback:</strong>
                    </p>

                    <div>{feedback}</div>
                </div>
            )}
        </>
    );
};

export default ReturnToHospitalOutput;
