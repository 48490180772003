import { AccountInfo } from '@azure/msal-browser';

export interface AuthState {
    account: AccountInfo;
    error: any;
    idToken: any;
    //accessToken: string;
    isAuthenticated: boolean;
    //expiresOn?: Moment;
    profile: any;
    errorMessage: string;
    permissions: string[];
    permissionsLoading: boolean;
}

export const initialState: AuthState = {
    account: null,
    error: null,
    idToken: null,
    //accessToken: null,
    isAuthenticated: false,
    profile: null,
    //expiresOn: null,
    errorMessage: null,
    permissions: [],
    permissionsLoading: true,
};
