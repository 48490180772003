import { PayloadAction } from '@reduxjs/toolkit';
import { ReturnToHospitalConfigurationClient, ReturnToHospitalConfigurationDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { adminSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { AdminState } from './models';

export const reducers = {
    setReturnToHospitalConfigurations: (state: AdminState, action: PayloadAction<ReturnToHospitalConfigurationDto[]>) => {
        state.returnToHospitalConfigurations = action.payload;
        state.errorMessage = null;
    },
};

export function fetchReturnToHospitalConfigurations(includeActive = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new ReturnToHospitalConfigurationClient(null, Axios);

        try {
            const response = await client.getTree(includeActive);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            } else {
                dispatch(adminSlice.actions.setReturnToHospitalConfigurations(response.result.entity));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function updateReturnToHospitalConfig(returnToHospital: ReturnToHospitalConfigurationDto): AppThunk<Promise<ReturnToHospitalConfigurationDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new ReturnToHospitalConfigurationClient(null, Axios);

        try {
            const response = await client.post(returnToHospital);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }

            return response.result.entity;
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function deleteReturnToHospitalConfig(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new ReturnToHospitalConfigurationClient(null, Axios);

        try {
            const response = await client.delete(id);

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export const selectReturnToHospitalConfigurations = (state: RootState) => state.admin.returnToHospitalConfigurations;
