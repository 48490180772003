import {
    BedBoardHospitalsClient,
    BedBoardHospitalDto,
    BedBoardHospitalFormDto,
    BedBoardHospitalFormsClient,
    BedBoardHospitalRequestConfigurationDto,
    BedBoardHospitalRequestConfigurationsClient,
} from '@medone/medonehp-bedboard-client';

import { Axios } from '../../../../shared/common/http';
import { adminSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

export function fetchHospitals(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalsClient(null, Axios);

        try {
            const response = await client.getAll();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospitals(response.result.entity));
            } else {
                handleError(response);
            }
        } catch (error) {
            handleError(error, () => {});
        }
    };
}

export function fetchHospital(hospitalId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalsClient(null, Axios);

        try {
            const response = await client.getById(hospitalId);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospital(response.result.entity));
            } else {
                handleError(response);
            }
        } catch (error) {
            handleError(error, () => {});
        }
    };
}

export function updateHospital(hospital: BedBoardHospitalDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalsClient(null, Axios);

        try {
            const response = await client.post(hospital);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospital(response.result.entity));
            } else {
                handleError(response);
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => {});
        }

        return false;
    };
}

export function updateHospitalForm(dto: BedBoardHospitalFormDto): AppThunk<Promise<BedBoardHospitalFormDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalFormsClient(null, Axios);

        try {
            const response = await client.post(dto);

            if (!response.result.succeeded) {
                handleError(response);
            }

            return response.result.entity;
        } catch (error) {
            handleError(error);
        }

        return null;
    };
}

export function deleteHospitalForm(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalFormsClient(null, Axios);

        try {
            const response = await client.delete(id);

            return response.result.succeeded;
        } catch (error) {
            handleError(error);
        }

        return false;
    };
}

export function fetchHospitalFormTypes(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalFormsClient(null, Axios);

        try {
            const response = await client.getFormTypes();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospitalFormTypes(response.result.entity));
            } else {
                handleError(response);
            }
        } catch (error) {
            handleError(error);
        }
    };
}

export function fetchHospitalRequestTypes(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalRequestConfigurationsClient(null, Axios);

        try {
            const response = await client.getRequestTypes();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospitalRequestTypes(response.result.entity));
            } else {
                handleError(response);
            }
        } catch (error) {
            handleError(error);
        }
    };
}

export function fetchHospitalRequestConfigurations(hospitalId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalRequestConfigurationsClient(null, Axios);

        try {
            const response = await client.getByHospitalId(hospitalId);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospitalRequestConfigs(response.result.entity));
            } else {
                handleError(response);
            }
        } catch (error) {
            handleError(error);
        }
    };
}

export function updateHospitalRequestConfiguration(dto: BedBoardHospitalRequestConfigurationDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalRequestConfigurationsClient(null, Axios);

        try {
            const response = await client.post(dto);

            if (!response.result.succeeded) {
                handleError(response);
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => {});
        }

        return false;
    };
}

export function deleteHospitalRequestConfiguration(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new BedBoardHospitalRequestConfigurationsClient(null, Axios);

        try {
            const response = await client.delete(id);

            if (!response.result.succeeded) {
                handleError(response);
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => {});
        }

        return false;
    };
}

export const selectHospitals = (state: RootState) => state.bedBoardAdmin.hospitals;
export const selectHospital = (state: RootState) => state.bedBoardAdmin.currentHospital;
export const selectHospitalRequestConfigs = (state: RootState) => state.bedBoardAdmin.currentHospitalRequestConfigs;
export const selectHospitalFormTypes = (state: RootState) => state.bedBoardAdmin.formTypes;
export const selectHospitalRequestTypes = (state: RootState) => state.bedBoardAdmin.requestTypes;
