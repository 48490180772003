import { HubConnection } from '@microsoft/signalr';
import { BedBoardIntakeDto } from '@medone/medonehp-bedboard-client';

import { store } from '../../shared/store';
import { isBedBoardKiosk } from '../../shared/common/helpers';
import { fetchHospitals } from '../features/bedboard/admin/slice.hospitals';
import { fetchIntakes, fetchIntakesByKioskId, setLastAssignedIntakeId } from '../features/bedboard/slice';
import { fetchBedboardAssignableUsers } from '../../post-acute/features/users/slice';
import { debounce } from 'lodash';
import { SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions } from '../../Config';

export const initBedBoardHubConnection = (connection: HubConnection, groupName?: string) => {
    connection.on('BedBoardReleaseSuccess', debounce((message: any) => {
        window.location.reload();
    }, SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions));

    connection.on('BedBoardIntakeAdded', async (dto: BedBoardIntakeDto) => {
        // Only send this update payload to the kiosk the intake is assigned to
        if (isBedBoardKiosk() && dto.hospitalKioskId != null && groupName != null && groupName.toLowerCase() === dto.hospitalKioskId.toLowerCase()) {
            await store.dispatch(fetchIntakesByKioskId(dto.hospitalKioskId));
        }

        // If on the bedboard dashboard, then we can just reload the intakes table
        if (window.location.pathname === '/') {
            // Only reload here if the incoming DTO was not just "assigned" in this browser instance.
            // Assigned intakes are handled with the fadeout logic
            const lastAssignedIntakeId = store.getState().bedBoard.lastAssignedIntakeId;
            if (dto.id === lastAssignedIntakeId) {
                store.dispatch(setLastAssignedIntakeId(null));
            } else {
                await store.dispatch(fetchIntakes(null, false));
            }
        }
    });

    connection.on('BedboardIntakeDeleted', async (dto: BedBoardIntakeDto) => {
        // Only send this update payload to the kiosk the intake is assigned to
        if (isBedBoardKiosk() && dto.hospitalKioskId != null && groupName != null && groupName.toLowerCase() === dto.hospitalKioskId.toLowerCase()) {
            await store.dispatch(fetchIntakesByKioskId(dto.hospitalKioskId));
        }

        // If on the bedboard dashboard, then we can just reload the intakes table
        if (window.location.pathname === '/') {
            await store.dispatch(fetchIntakes(null, false));
        }
    });

    connection.on('HospitalAdded', debounce(async () => {
        await store.dispatch(fetchHospitals());

        if (window.location.pathname === '/') {
            await store.dispatch(fetchIntakes(null, false));
        }
    }, SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions));

    connection.on('UserAdded', debounce(async () => {
        if (!isBedBoardKiosk()) {
            await store.dispatch(fetchBedboardAssignableUsers());
        }
    }, SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions));
};
