import { Input } from 'antd';
import InputMask from 'react-input-mask';
import moment from 'moment';

type Props = {
    type?: 'date' | 'datetime-local' | 'masked';
    [x: string]: any;
};

const CustomDatePicker = ({ type = 'date', ...props }: Props) => {
    const format = 'mm/dd/yyyy';
    const { value } = props;

    const getConvertedValue = () => {
        if (moment.isMoment(value)) {
            return value.format(props.placeholder ?? 'YYYY-MM-DD');
        }

        return value;
    };

    if (type === 'masked') {
        if (props.className !== undefined) {
            props.className += ' ant-input';
        } else {
            props.className = 'ant-input';
        }
    }

    return type === 'masked' ? (
        <InputMask mask="99/99/9999" placeholder={props.placeholder ?? format} {...props} value={getConvertedValue()} />
    ) : (
        <Input type={type} placeholder={props.placeholder ?? format} {...props} value={getConvertedValue()} />
    );
};

export default CustomDatePicker;
