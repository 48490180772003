import { PayloadAction } from '@reduxjs/toolkit';
import { ReturnToHospitalClient, ReturnToHospitalSummaryDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { CensusState } from './models';
import { censusSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';

export const reducers = {
    setReturnToHospitals: (state: CensusState, action: PayloadAction<ReturnToHospitalSummaryDto[]>) => {
        state.returnToHospitals = action.payload;
    },
};

export function fetchReturnToHospitalsByPatientId(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new ReturnToHospitalClient(null, Axios);

        try {
            const response = await client.getByPatientId(patientId);

            if (!response.result.succeeded) {
                handleError(response);
            } else {
                dispatch(censusSlice.actions.setReturnToHospitals(response.result.entity));
            }
        } catch (error) {
            handleError(error);
        }
    };
}

export const selectReturnToHospitals = (state: RootState) => state.census.returnToHospitals;
