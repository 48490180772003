import { NoteDto, PatientDto, PatientIntakeDto } from '@medone/medonehp-api-client';

export interface ViewerState {
    loading: boolean;
    errorMessage: string;
    patient: PatientDto;
    patientIntake: PatientIntakeDto;
    notes: NoteDto[];
}

export const initialState: ViewerState = {
    loading: true,
    errorMessage: '',
    patient: null,
    patientIntake: null,
    notes: [],
};
