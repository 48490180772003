import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HubSingleton } from '.';

import { AppThunk, AppDispatch, RootState } from '../store';
import { HubState, initialState } from './models';

export const hubSlice = createSlice({
    name: 'hub',
    initialState,
    reducers: {
        setOffline: (state: HubState, action: PayloadAction<boolean>) => {
            state.offline = action.payload;
        },
    },
});

export const { setOffline } = hubSlice.actions;

export function initHubConnection(groupName?: string): AppThunk {
    return async (dispatch: AppDispatch) => {
        try {
            await HubSingleton.Instance.startHub(groupName);
        } catch (ex) {
            console.log('Error starting hub connection:');
            console.error(ex);
        }
    };
}

export const selectOffline = (state: RootState) => state.hub.offline;

export default hubSlice.reducer;
