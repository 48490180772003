import { CensusBadgeDto, FacilityNoteCensusDto, UnsignedNoteCensusDto } from '@medone/medonehp-api-client';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { ValueLabelOption } from '../../../../shared/common/helpers';

export const unsignedNotesBadgesAdapter = createEntityAdapter<CensusBadgeDto, number>({
    selectId: (item) => item.patientIntakeId,
});

export interface UnsignedNotesState {
    errorMessage: string | null;
    unsignedNotes: FacilityNoteCensusDto[];
    unsignedNotesTab: string;
    currentUnsignedNote: UnsignedNoteCensusDto;
    unsignedBadges: EntityState<CensusBadgeDto, number>;
    stagedNoteBadges: EntityState<CensusBadgeDto, number>;
    providerOptions: ValueLabelOption[];
    facilityOptions: ValueLabelOption[];
    roomNumberOptions: ValueLabelOption[];
    dateOfServiceOptions: ValueLabelOption[];
    expirationDayOptions: ValueLabelOption[];
}

export const initialState: UnsignedNotesState = {
    errorMessage: null,
    unsignedNotes: [],
    unsignedNotesTab: null,
    currentUnsignedNote: null,
    unsignedBadges: unsignedNotesBadgesAdapter.getInitialState({}),
    stagedNoteBadges: unsignedNotesBadgesAdapter.getInitialState({}),
    providerOptions: [],
    facilityOptions: [],
    roomNumberOptions: [],
    dateOfServiceOptions: [],
    expirationDayOptions: [],
};
