import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../shared/store';

import { initialState, PortalState } from './models';

export const portalSlice = createSlice({
    name: 'portal',
    initialState,
    reducers: {
        setLoading: (state: PortalState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const selectLoading = (state: RootState) => state.portal.loading;

export default portalSlice.reducer;
