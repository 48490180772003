import { Tooltip, Tag, Button, Modal, Form, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { CensusDto, EnumSelectListDto, FacilityDto, FacilityTypes, PatientIntakeFacilityTypeUpdateDto } from '@medone/medonehp-api-client';
import { useState } from 'react';
import { toast } from 'react-toastify';

import CustomSelect, { getEnumCustomSelectListOptions } from '../../../../../shared/common/components/CustomSelect';
import { selectFacilityTypes } from '../../../../../shared/common/data/slice';
import { handleError } from '../../../../../shared/common/HandleErrors';
import { filterOptionsStartsWith } from '../../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { selectFacilities } from '../../admin/slice.facilities';
import { specialtiesSelectors } from '../slice';
import { updateFacilityType } from '../slice.patient-intakes';

type Props = {
    census: CensusDto;
    mobile?: boolean;
};

const Skilled = ({ census, mobile = false }: Props) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const facilities = useAppSelector<FacilityDto[]>(selectFacilities);
    const facilityTypes = useAppSelector<EnumSelectListDto[]>(selectFacilityTypes);
    const intakeSpecialties = useAppSelector((state) => specialtiesSelectors.selectById(state.census.specialties, census.patientIntakeId));
    const [visible, setVisible] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    const facility = facilities?.find((x) => x.id === census.admittedToId);
    const options = (facility?.facilityTypes ?? []).map((type) => facilityTypes.find((x) => x.id === type));
    const selectedFacilityTypes = getEnumCustomSelectListOptions(options);

    const handleUpdateSkilled = async () => {
        setProcessing(true);

        try {
            const values = await form.validateFields();
            const visitIds = intakeSpecialties.specialties.flatMap((x) => x.currentDayVisits).flatMap((x) => x.visitId);

            const patchedValues = {
                patientIntakeId: census.patientIntakeId,
                // If visits are loaded in, lets also update them
                visitIds: visitIds,
                facilityType: values.facilityType,
            } as PatientIntakeFacilityTypeUpdateDto;

            const result = await dispatch(updateFacilityType(patchedValues));

            if (result) {
                toast.success('Updated Patient Facility Type');
            }

            setVisible(false);
        } catch (errors) {
            handleError(errors, () => true);
        }

        setProcessing(false);
    };

    return (
        <>
            {census.facilityType === FacilityTypes.SkilledNursingFacility && (
                <Tooltip title="Skilled">
                    <Tag color="#ababab" className={mobile ? 'ml-1' : 'm-0'}>
                        S
                    </Tag>
                </Tooltip>
            )}

            <Tooltip title="Edit Facility Type">
                <Button type="link" className="skilled-btn" onClick={() => setVisible(true)} disabled={processing}>
                    <EditOutlined />
                </Button>
            </Tooltip>

            <Modal title="Update Facility Type" open={visible} footer={null} onCancel={() => setVisible(false)} zIndex={1002}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={handleUpdateSkilled}
                    initialValues={{
                        facilityType: census.facilityType,
                    }}
                >
                    <Form.Item name="facilityType" label="Facility Type" rules={[{ required: true, message: 'Facility Type is required' }]}>
                        <CustomSelect
                            allowClear
                            showSearch
                            filterOption={filterOptionsStartsWith}
                            options={selectedFacilityTypes}
                            popupClassName="note-select"
                            dropdownMatchSelectWidth={false}
                        />
                    </Form.Item>

                    <Row justify="end">
                        <Button loading={processing} type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default Skilled;
