import { PayloadAction } from '@reduxjs/toolkit';
import { ArrivingFromLookupsClient, ArrivingFromLookupDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { AdminState } from './models';
import { adminSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { fetchRegions } from './slice.regions';
import { fetchHospitalSystems } from './slice.hospital-systems';

export const reducers = {
    setArrivingFroms: (state: AdminState, action: PayloadAction<ArrivingFromLookupDto[]>) => {
        state.arrivingFroms = action.payload;
        state.errorMessage = null;
    },

    setArrivingFrom: (state: AdminState, action: PayloadAction<ArrivingFromLookupDto>) => {
        state.currentArrivingFrom = action.payload;
        state.errorMessage = null;
    },

    syncArrivingFrom: (state: AdminState, action: PayloadAction<ArrivingFromLookupDto>) => {
        let newItems = [...state.arrivingFroms];
        const index = state.arrivingFroms.findIndex((x) => x.id === action.payload.id);

        if (index !== -1) {
            newItems = newItems.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload } as ArrivingFromLookupDto;
                }

                return item;
            });
        } else {
            newItems.push(action.payload);
        }

        state.arrivingFroms = newItems;

        if (state.currentArrivingFrom != null && state.currentArrivingFrom.id === action.payload.id) {
            state.currentArrivingFrom = action.payload;
        }
    },
};

export function fetchArrivingFroms(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new ArrivingFromLookupsClient(null, Axios);

        try {
            const response = await client.getAll();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setArrivingFroms(response.result.entity));

                await dispatch(fetchRegions());
                await dispatch(fetchHospitalSystems());
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchArrivingFrom(arrivingFromId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new ArrivingFromLookupsClient(null, Axios);

        try {
            const response = await client.getById(arrivingFromId);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setArrivingFrom(response.result.entity));

                await dispatch(fetchRegions());
                await dispatch(fetchHospitalSystems());
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function updateArrivingFrom(arrivingFrom: ArrivingFromLookupDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new ArrivingFromLookupsClient(null, Axios);

        try {
            const response = await client.post(arrivingFrom);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setArrivingFrom(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function syncArrivingFrom(dto: ArrivingFromLookupDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(adminSlice.actions.syncArrivingFrom(dto));
    };
}

export const selectArrivingFroms = (state: RootState) => state.admin.arrivingFroms;
export const selectArrivingFrom = (state: RootState) => state.admin.currentArrivingFrom;
