import { Alert } from 'antd';
import { uniq } from 'lodash';

type Props = {
    errorMessages: string[];
};

const PccErrors = ({ errorMessages }: Props) => {
    return (
        errorMessages &&
        errorMessages.length > 0 && (
            <Alert
                showIcon
                type="error"
                className="mb-2 pcc-alert"
                message="PointClickCare system may be experiencing issues. Please try again later."
                description={
                    <ul>
                        {uniq(errorMessages).map((errorMessage) => (
                            <li key={errorMessage}>{errorMessage}</li>
                        ))}
                    </ul>
                }
            />
        )
    );
};

export default PccErrors;
