import { useEffect } from 'react';
import { Popover } from 'antd';
import { PatientIntakeSpecialtyDto, PatientIntakeSpecialtyGroupedDto, Specialties } from '@medone/medonehp-api-client';

import SpecialtySignOff from './specialty-sign-off';

import { formatDateTime, getNoteTypeShortName, isInRole } from '../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { specialtiesSelectors } from '../census/slice';
import { fetchSpecialties } from '../census/slice.specialties';

import primaryIcon from '../../../../shared/images/specialty_primary.svg';
import pmrIcon from '../../../../shared/images/specialty_pmr.svg';
import woundIcon from '../../../../shared/images/specialty_wound.svg';
import { SpecialtyRole } from '../../../../shared/common/auth/RoleAuth';
import { AuthState } from '../../../../shared/common/auth/models';
import { selectAuth } from '../../../../shared/common/auth/slice';
import classNames from 'classnames';

type Props = {
    patientIntakeId: number;
    showSignOff?: boolean;

    // In some scenarios we call fetchSpecialties in bulk, so we should set this flag
    // to prevent duplicate network requests
    disableAutoLoad?: boolean;
};

const specialtyRoles = [SpecialtyRole.Primary, SpecialtyRole.PMR, SpecialtyRole.Wound];

export const getSpecialtyIcon = (auth: AuthState, item: PatientIntakeSpecialtyDto, showNew = false) => {
    let icon = <>{item.specialtyFormatted}</>;

    if (item.specialty === Specialties.Primary) {
        icon = <img src={primaryIcon} alt={item.specialtyFormatted} className="specialty-icon" />;
    }

    if (item.specialty === Specialties.PMR) {
        icon = <img src={pmrIcon} alt={item.specialtyFormatted} className="specialty-icon" />;
    }

    if (item.specialty === Specialties.WoundCare) {
        icon = <img src={woundIcon} alt={item.specialtyFormatted} className="specialty-icon" />;
    }

    if (showNew && item.isNew && isInRole(auth?.permissions, [specialtyRoles[item.specialty - 1]])) {
        icon = (
            <span style={{ whiteSpace: 'nowrap' }}>
                {icon} <span className="is-new">New</span>
            </span>
        );
    }

    return icon;
};

const getSpecialtiesCardClasses = (primaryClass: string, group: PatientIntakeSpecialtyGroupedDto, item: PatientIntakeSpecialtyDto) => {
    const currentDayVisit = item.currentDayVisits && item.currentDayVisits.length;
    const currentDaySignedNote = item.hasSignedNoteForCurrentDay;

    return {
        [primaryClass]: true,
        'current-day-visit': currentDayVisit,
        'current-day-signed-note': currentDaySignedNote,
        'is-new': item.isNew,
        'is-skipped': group.isSkipped,
    };
};

const renderCurrentDayVisits = (item: PatientIntakeSpecialtyDto) => {
    let title = 'Visited today';

    if (item.hasSignedNoteForCurrentDay) {
        title = 'Has signed note(s) for the current day';
    }

    return (
        item?.currentDayVisits &&
        item?.currentDayVisits.length > 0 && (
            <div className="current-day-visits mb-2">
                <div>
                    <strong>{title}:</strong>
                </div>

                {item?.currentDayVisits.map((item) => (
                    <div key={item.providerName}>
                        {item.providerName} on {formatDateTime(item.date)} ({getNoteTypeShortName(item.noteType)})
                    </div>
                ))}
            </div>
        )
    );
};

const renderSkipped = (item: PatientIntakeSpecialtyGroupedDto) => {
    return (
        item?.isSkipped && (
            <div className="is-skipped mb-2">
                <strong>Skipped</strong>
            </div>
        )
    );
};

const SpecialtiesBadges = ({ patientIntakeId, showSignOff = false, disableAutoLoad = false }: Props) => {
    const dispatch = useAppDispatch();
    const intakeSpecialties = useAppSelector((state) => specialtiesSelectors.selectById(state.census.specialties, patientIntakeId));
    const auth = useAppSelector<AuthState>(selectAuth);

    useEffect(() => {
        if (intakeSpecialties == null && !disableAutoLoad) {
            dispatch(fetchSpecialties(patientIntakeId));
        }
    }, [patientIntakeId, intakeSpecialties, dispatch, disableAutoLoad]);

    return (
        <>
            {intakeSpecialties != null && intakeSpecialties.specialties.length > 0 && (
                <div className="specialties-cards">
                    {intakeSpecialties.specialties.map((item, index) => {
                        return (
                            <div key={`${item.specialty}-${index}`} className={classNames(getSpecialtiesCardClasses('specialties-card', intakeSpecialties, item))}>
                                <div className="specialty">
                                    <Popover
                                        trigger="hover focus"
                                        destroyTooltipOnHide
                                        title={
                                            <div className={classNames(getSpecialtiesCardClasses('specialty-popover-title', intakeSpecialties, item))}>
                                                {item.specialtyFormatted}

                                                {getSpecialtyIcon(auth, item)}
                                            </div>
                                        }
                                        content={
                                            <div className="specialties-info">
                                                <div className="mb-1">
                                                    <div>
                                                        <strong>Referred on:</strong>
                                                    </div>

                                                    <div>{formatDateTime(item.startDate)}</div>
                                                </div>

                                                {renderCurrentDayVisits(item)}

                                                {renderSkipped(intakeSpecialties)}

                                                {showSignOff && <SpecialtySignOff specialty={item} />}
                                            </div>
                                        }
                                    >
                                        {getSpecialtyIcon(auth, item, true)}
                                    </Popover>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default SpecialtiesBadges;
