import { PatientDto } from '@medone/medonehp-api-client';

type ConvertFilesToBlobsProps = {
    data: Blob;
    fileName: string;
};

const SessionId = Math.random().toString(36).substr(2, 9);

const convertFileToBlobs = (file): Promise<ConvertFilesToBlobsProps> => {
    return new Promise<ConvertFilesToBlobsProps>((resolve, reject) => {
        if (file != null) {
            const reader = new FileReader();

            reader.onload = function (e) {
                const blob = new Blob([new Uint8Array(e.target.result as ArrayBuffer)], { type: file.type });

                resolve({
                    data: blob,
                    fileName: file.name,
                });
            };

            reader.readAsArrayBuffer(file);
        } else {
            resolve(null);
        }
    });
};

const convertFilesToBlobs = (files): Promise<ConvertFilesToBlobsProps[]> => {
    return new Promise<ConvertFilesToBlobsProps[]>((resolve, reject) => {
        const results = [];

        if (files != null) {
            files.forEach((file) => {
                results.push(convertFileToBlobs(file));
            });
        }

        resolve(results);
    });
};

class DuplicatePatientError extends Error {
    public patient: PatientDto;

    constructor(message: string, patient: PatientDto) {
        super(message);

        Object.setPrototypeOf(this, DuplicatePatientError.prototype);

        this.name = 'DuplicatePatientError';
        this.patient = patient;
    }
}

class DuplicateMbiError extends Error {
    public patient: PatientDto;

    constructor(message: string, patient: PatientDto) {
        super(message);

        Object.setPrototypeOf(this, DuplicateMbiError.prototype);

        this.name = 'DuplicateMbiError';
        this.patient = patient;
    }
}

export { SessionId, convertFileToBlobs, convertFilesToBlobs, DuplicatePatientError, DuplicateMbiError };
