import { PayloadAction } from '@reduxjs/toolkit';
import { EventLogClient, EventLogSearchDto, AntdTableResponseOfListOfEventLogDto, EventLogDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { AdminState } from './models';
import { adminSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';

export const reducers = {
    setEventLogs: (state: AdminState, action: PayloadAction<AntdTableResponseOfListOfEventLogDto>) => {
        state.eventLogs = action.payload;
        state.errorMessage = null;
    },

    setEventLogFilter: (state: AdminState, action: PayloadAction<EventLogSearchDto>) => {
        state.eventLogFilter = action.payload;
    },

    syncEventLog: (state: AdminState, action: PayloadAction<EventLogDto>) => {
        let newItems = [...state.eventLogs.entity];
        const index = state.eventLogs.entity.findIndex((x) => x.id === action.payload.id);

        if (index !== -1) {
            newItems = newItems.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload } as EventLogDto;
                }

                return item;
            });
        } else {
            newItems.push(action.payload);
        }

        state.eventLogs.entity = newItems;
    },
};

export function fetchEventLogs(dto: EventLogSearchDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(adminSlice.actions.setEventLogFilter(dto));

        const client = new EventLogClient(null, Axios);

        try {
            const response = await client.getEventLogs(dto);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setEventLogs(response.result));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export const selectEventLogs = (state: RootState) => state.admin.eventLogs;
