import { Tag } from 'antd';

import { useAppSelector } from '../../../../../../shared/hooks';
import { censusBadgeSelectors } from '../../slice';

import QuickNoteEyecon from '../quicknote-eyecon';

type Props = {
    patientIntakeId: number;
};

const ScheduledBadge = ({ patientIntakeId }: Props) => {
    const censusBadge = useAppSelector((state) => censusBadgeSelectors.selectById(state.census.badges, patientIntakeId));

    const renderScheduledUser = (scheduledUser: string, isCurrentUser: boolean) => {
        return scheduledUser != null && <Tag color={isCurrentUser ? '#41bdf6' : '#ababab'}>{scheduledUser}</Tag>;
    };

    return censusBadge != null ? (
        <>
            {censusBadge?.hasUnacknowledgedQuickNote && (
                <QuickNoteEyecon facilityId={censusBadge.admittedToId} patientId={censusBadge.patientId} patientIntakeId={patientIntakeId} />
            )}

            {renderScheduledUser(censusBadge?.scheduledUser, censusBadge?.scheduledUserIsCurrentUser)}
        </>
    ) : (
        <></>
    );
};

export default ScheduledBadge;
