import { Configuration, SilentRequest } from '@azure/msal-browser';

import { VersionInfo } from '../../../Config';

// For a full list of msal.js configuration parameters visit
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const getSystemConfig = () => {
    // if (isBeta()) {
    //     return {
    //         loggerOptions: {
    //             loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
    //                 switch (level) {
    //                     case LogLevel.Error:
    //                         console.error(message);
    //                         return;
    //                     case LogLevel.Info:
    //                         console.info(message);
    //                         return;
    //                     case LogLevel.Verbose:
    //                         console.debug(message);
    //                         return;
    //                     case LogLevel.Warning:
    //                         console.warn(message);
    //                         return;
    //                 }
    //             },

    //             piiLoggingEnabled: true,
    //         },
    //     };
    // }

    return null;
};

export const msalConfig: Configuration = {
    auth: {
        authority: process.env.REACT_APP_MS_AUTHORITY,
        clientId: process.env.REACT_APP_MS_CLIENTID,
        navigateToLoginRequestUrl: true,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },

    system: {
        ...getSystemConfig(),
        allowNativeBroker: false, // Disables WAM Broker
        tokenRenewalOffsetSeconds: 300,
        // 8 hours
        refreshTokenExpirationOffsetSeconds: 8 * 60 * 60,
        preventCorsPreflight: true
    },

    telemetry: {
        application: {
            appName: 'MedOne Post-Acute',
            appVersion: VersionInfo.AppVersion,
        },
    },
};

// Coordinates and required scopes for your web api
export const apiConfig = {
    // Enter the API scopes as declared in the app registration 'Expose an Api' blade in the form of 'api://{client_id}/.default'
    resourceScope: process.env.REACT_APP_MS_SCOPES,
};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
export const loginRequest = {
    scopes: ['openid', 'profile', 'offline_access'],
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
    scopes: [apiConfig.resourceScope],
};

// Add here scopes for silent token request
export const silentRequest = {
    scopes: ['openid', 'profile', apiConfig.resourceScope],
} as SilentRequest;

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
