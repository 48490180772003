import { useState } from 'react';
import { Button, Collapse, Table } from 'antd';
import { ScheduleGroupDto, RouteBuilderPostRequestItemDto, ScheduleDto } from '@medone/medonehp-api-client';

import { useAppSelector } from '../../../../../shared/hooks';
import { sortWithNullsAtBottom } from '../../../../../shared/common/helpers/table';

import RouteBuilderBadges from './badges';
import { visitDueSelectors } from '../../census/slice';
import VisitDueBadge from '../../census/components/badges/visit-due';
import ProviderTypeBadge from '../../census/components/badges/provider-type';
import VisitTypeBadge from '../../census/components/badges/visit-type';

type Props = {
    location: ScheduleGroupDto;
    handlePostSchedule: (evt, providerId, selectedRows) => void;
    header: React.ReactNode;
    providerId: string;
};

const ProviderLocationPatients = ({ header, location, handlePostSchedule, providerId, ...props }: Props) => {
    const visitDues = useAppSelector((state) => visitDueSelectors.selectAll(state.census.visitDues));
    const [selectedRows, setSelectedRows] = useState([]);

    const onSelectChange = (key, rows) => {
        setSelectedRows(
            rows.map((row) => {
                return RouteBuilderPostRequestItemDto.fromJS({ patientIntakeId: row.patientIntakeId });
            })
        );
    };

    const rowSelection = {
        selectedRows,
        onChange: onSelectChange,
    };

    const renderUnassignButton = () => {
        return (
            selectedRows.length > 0 && (
                <Button type="primary" className="mr-1" onClick={(evt) => handlePostSchedule(evt, null, selectedRows)}>
                    Unassign Patients
                </Button>
            )
        );
    };

    const renderPatientName = (value: any, item: ScheduleDto) => {
        return (
            <>
                {value.name}

                <RouteBuilderBadges patientIntakeId={item.patientIntakeId} patientTypeIds={null} canReturnFromED={false} />
            </>
        );
    };

    return (
        <Collapse.Panel {...props} className="mb-0 provider-location" header={header} key={`${providerId}-${location.admittedToId}`} extra={renderUnassignButton()}>
            <Table
                dataSource={location.items}
                pagination={false}
                rowKey={(x) => `${x.id ?? 0}-${x.patientIntakeId ?? 0}-${x.census.name}`}
                rowSelection={rowSelection}
                size="small"
                className="route-builder-table"
            >
                <Table.Column<ScheduleDto> title="Patient Name" dataIndex="census" key="patientName" render={(x, y) => renderPatientName(x, y)} />

                <Table.Column<ScheduleDto>
                    title="Visit Due"
                    key="overDueInDays"
                    sorter={(a, b, direction) => {
                        const itemA = visitDues.find((x) => x.patientIntakeId === a.patientIntakeId);
                        const itemB = visitDues.find((x) => x.patientIntakeId === b.patientIntakeId);

                        return sortWithNullsAtBottom(itemA, itemB, direction, 'overDueInDays');
                    }}
                    render={(data: ScheduleDto) => (
                        <VisitDueBadge admissionDate={data.census.admissionDate} admittedToId={location.admittedToId} patientIntakeId={data.patientIntakeId} />
                    )}
                />

                <Table.Column<ScheduleDto>
                    title="Provider Type"
                    key="providerVisitType"
                    onFilter={(value, record) => {
                        const item = visitDues.find((x) => x.patientIntakeId === record.patientIntakeId);

                        return item?.providerVisitType === value;
                    }}
                    width={150}
                    sorter={(a, b, direction) => {
                        const itemA = visitDues.find((x) => x.patientIntakeId === a.patientIntakeId);
                        const itemB = visitDues.find((x) => x.patientIntakeId === b.patientIntakeId);

                        return sortWithNullsAtBottom(itemA, itemB, direction, 'providerVisitType');
                    }}
                    render={(data: ScheduleDto) => <ProviderTypeBadge patientIntakeId={data.patientIntakeId} />}
                />

                <Table.Column<ScheduleDto>
                    title="Visit Type(s)"
                    key="visitType"
                    sorter={(a, b, direction) => {
                        const itemA = visitDues.find((x) => x.patientIntakeId === a.patientIntakeId);
                        const itemB = visitDues.find((x) => x.patientIntakeId === b.patientIntakeId);

                        return sortWithNullsAtBottom(itemA, itemB, direction, 'visitType');
                    }}
                    render={(data: ScheduleDto) => <VisitTypeBadge patientIntakeId={data.patientIntakeId} />}
                />
            </Table>
        </Collapse.Panel>
    );
};

export default ProviderLocationPatients;
