import { useState } from 'react';
import { Spin, Typography } from 'antd';
import { CensusDto, ChangeRoomNumberRequestDto } from '@medone/medonehp-api-client';

import { useAppDispatch } from '../../../../../shared/hooks';

import { updateRoomNumber } from '../slice.patient-intakes';

type Props = {
    census: CensusDto;
};

const EditableRoomNumber = ({ census }: Props) => {
    const dispatch = useAppDispatch();
    const [processing, setProcessing] = useState<boolean>(false);

    const handleChangeRoomNumber = async (value: string, patientIntakeId: number) => {
        setProcessing(true);

        await dispatch(updateRoomNumber({ roomNumber: value, patientIntakeId } as ChangeRoomNumberRequestDto));

        setProcessing(false);
    };

    return (
        <Spin spinning={processing}>
            <Typography.Paragraph
                editable={
                    !processing && {
                        onChange: (value) => handleChangeRoomNumber(value, census.patientIntakeId),
                        tooltip: 'Edit Room #',
                    }
                }
            >
                {census.roomNumber || ''}
            </Typography.Paragraph>
        </Spin>
    );
};

export default EditableRoomNumber;
