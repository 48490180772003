import { useState } from 'react';
import { Drawer, Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import { isMobileView } from '../../../../../../shared/common/helpers';

import PatientIntakeForm from './PatientIntakeForm';

const Create = () => {
    const [isVisible, setVisible] = useState<boolean>(false);

    const handleOpen = () => {
        setVisible(true);
    };

    const handleClose = () => {
        setVisible(false);
    };

    return (
        <>
            <Button type="primary" onClick={handleOpen}>
                <UserAddOutlined />
                Add New Patient
            </Button>

            {isVisible && (
                <Drawer title="Create Patient Intake" className="patient-intake-drawer" width={isMobileView() ? '100%' : '50%'} onClose={handleClose} open={isVisible}>
                    <PatientIntakeForm onFinishCallback={handleClose} />
                </Drawer>
            )}
        </>
    );
};

export default Create;
