import { PayloadAction } from '@reduxjs/toolkit';
import { PatientNotesClient, PatientNoteDto, FileParameter, AdmissionDocGroupDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { CensusState } from './models';
import { censusSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';

export const reducers = {
    setPatientNotes: (state: CensusState, action: PayloadAction<PatientNoteDto[]>) => {
        state.patientNotes = action.payload;
        state.currentPatientNote = null;
        state.patientNoteLoading = false;
        state.patientNoteProcessing = false;
    },

    setPatientNote: (state: CensusState, action: PayloadAction<PatientNoteDto>) => {
        state.currentPatientNote = action.payload;
        state.patientNoteLoading = false;
        state.patientNoteProcessing = false;
    },

    setPatientNoteLoading: (state: CensusState, action: PayloadAction<boolean>) => {
        state.patientNoteLoading = action.payload;
    },

    setPatientNoteProcessing: (state: CensusState, action: PayloadAction<boolean>) => {
        state.patientNoteProcessing = action.payload;
    },

    setPatientDocGroups: (state: CensusState, action: PayloadAction<AdmissionDocGroupDto[]>) => {
        state.patientDocumentGroups = action.payload;
    },
};

export function createOrUpdatePatientNote(patientNote: PatientNoteDto): AppThunk<Promise<PatientNoteDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setPatientNoteProcessing(true));

        
        const client = new PatientNotesClient(null, Axios);

        try {
            const response = await client.post(patientNote);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatientNote(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            await dispatch(fetchPatientNotes(patientNote.patientId));
            await dispatch(fetchPatientDocumentGroups(patientNote.patientId));

            return response.result.entity;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        dispatch(censusSlice.actions.setPatientNoteProcessing(false));

        return null;
    };
}

export function deletePatientNote(patientNote: PatientNoteDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setPatientNoteProcessing(true));

        
        const client = new PatientNotesClient(null, Axios);

        try {
            const response = await client.delete(patientNote.id);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatientNote(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            await dispatch(fetchPatientNotes(patientNote.patientId));
            await dispatch(fetchPatientDocumentGroups(patientNote.patientId));

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function fetchPatientNotes(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setPatientNoteLoading(true));

        
        const client = new PatientNotesClient(null, Axios);

        try {
            const response = await client.getByPatientId(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatientNotes(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchPatientNote(noteId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setPatientNoteLoading(true));

        
        const client = new PatientNotesClient(null, Axios);

        try {
            const response = await client.getById(noteId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatientNote(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function uploadAttachment(patientNoteId: number | null, file: FileParameter): AppThunk<Promise<string>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setPatientNoteLoading(true));

        
        const client = new PatientNotesClient(null, Axios);

        try {
            const response = await client.uploadAttachment(patientNoteId, file);

            if (response.result.succeeded) {
                // Do we need to do anything after upload?
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.entity;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(null)));
        }

        return null;
    };
}

export function fetchPatientDocumentGroups(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new PatientNotesClient(null, Axios);

        try {
            const response = await client.getAdmissionDocsGrouped(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatientDocGroups(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export const selectPatientNotes = (state: RootState) => state.census.patientNotes;
export const selectPatientNote = (state: RootState) => state.census.currentPatientNote;
export const selectPatientNoteLoading = (state: RootState) => state.census.patientNoteLoading;
export const selectPatientNoteProcessing = (state: RootState) => state.census.patientNoteProcessing;
export const selectPatientDocumentGroups = (state: RootState) => state.census.patientDocumentGroups;
