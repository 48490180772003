import { PayloadAction } from '@reduxjs/toolkit';
import { CensusGroupDto, FacilityStagedNotesDto, NoteDto, NoteTypes, ProblemListItemDto, StagedNoteDto, StagedNotesClient } from '@medone/medonehp-api-client';
import { last, uniq } from 'lodash';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { CensusState } from './models';
import { censusSlice, fetchBadgeData, fetchCensusByPatientIntakeId } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { getCleanMedicalSummaryFields } from '../../../../shared/common/helpers';
import { fetchSpecialties } from './slice.specialties';
import { fetchPatientIntake } from './slice.patient-intakes';

export const reducers = {
    setCurrentStagedNote: (state: CensusState, action: PayloadAction<StagedNoteDto>) => {
        state.errorMessage = null;

        if (action.payload != null) {
            state.currentStagedNote = { ...state.currentStagedNote, ...action.payload } as StagedNoteDto;

            if (state.currentStagedNote.problemList == null || state.currentStagedNote.problemList.length === 0) {
                state.currentStagedNote.problemList = [ProblemListItemDto.fromJS({})];
            } else if (state.currentStagedNote.problemList != null) {
                const lastItem = last(state.currentStagedNote.problemList) as ProblemListItemDto;

                // Only add a new one at the end if there isn't one yet
                if (lastItem.snomedConceptId != null) {
                    state.currentStagedNote = { ...state.currentStagedNote, problemList: [...state.currentStagedNote.problemList, ProblemListItemDto.fromJS({})] } as StagedNoteDto;
                }
            }
        } else {
            state.currentStagedNote = null;
        }
    },

    setCurrentStagedNotesGrouped: (state: CensusState, action: PayloadAction<FacilityStagedNotesDto[]>) => {
        state.errorMessage = null;
        state.currentStagedNotesGrouped = action.payload;
    },

    setCurrentStagedNotes: (state: CensusState, action: PayloadAction<StagedNoteDto[]>) => {
        state.errorMessage = null;
        state.currentStagedNotes = action.payload;
    },
};

export function getOrCreateStagedNote(patientIntakeId: number, noteType: NoteTypes): AppThunk<Promise<StagedNoteDto>> {
    return async (dispatch: AppDispatch) => {
        const client = new StagedNotesClient(null, Axios);

        try {
            const response = await client.getOrCreate(patientIntakeId, noteType);

            if (response.result.succeeded) {
                const censusGroupDto = {
                    admittedToId: response.result.entity.facilityId,
                    patientIntakeIds: [response.result.entity.patientIntakeId],
                } as CensusGroupDto;

                dispatch(censusSlice.actions.setCurrentStagedNote(response.result.entity));

                await dispatch(fetchPatientIntake(response.result.entity.patientIntakeId, true));
                await dispatch(fetchBadgeData(censusGroupDto));
                await dispatch(fetchCensusByPatientIntakeId(response.result.entity.patientIntakeId, false));

                return response.result.entity;
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function fetchStagedNotesGrouped(): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new StagedNotesClient(null, Axios);

        try {
            const response = await client.getAllGrouped();

            if (response.result.succeeded) {
                const allIntakeIds = uniq(response.result.entity.flatMap((n) => n.stagedNotes.flatMap((y) => y.patientIntakeId)));

                // Load specialties for unsigned notes screen
                await dispatch(fetchSpecialties(allIntakeIds));

                dispatch(censusSlice.actions.setCurrentStagedNotesGrouped(response.result.entity));
            } else {
                handleError(response, () => {
                    dispatch(censusSlice.actions.setCurrentStagedNotesGrouped([]));
                    dispatch(censusSlice.actions.setError(null));
                });
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchStagedNotesForIntake(patientIntakeId: number): AppThunk<Promise<StagedNoteDto[]>> {
    return async (dispatch: AppDispatch) => {
        const client = new StagedNotesClient(null, Axios);

        try {
            const response = await client.getAllNonExpiredForIntake(patientIntakeId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setCurrentStagedNotes(response.result.entity));

                return response.result.entity;
            }

            handleError(response, () => dispatch(censusSlice.actions.setError(null)));
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return [];
    };
}

export function fetchStagedNoteForIntake(patientIntakeId: number, noteType: NoteTypes): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new StagedNotesClient(null, Axios);

        try {
            const response = await client.getForIntake(patientIntakeId, noteType);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setCurrentStagedNote(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function syncLatestMedicalSummaryToCurrentStagedNote(): AppThunk<Promise<StagedNoteDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentStagedNote, currentPatientIntake } = getState().census;

        try {
            if (currentStagedNote != null && currentPatientIntake != null) {
                const msValues = getCleanMedicalSummaryFields(currentPatientIntake?.medicalSummary);

                const updatedDto: any = {
                    ...currentStagedNote,
                    medicalSummary: msValues,
                    rowVersion: currentStagedNote.rowVersion,
                };

                dispatch(censusSlice.actions.setCurrentStagedNote(updatedDto));

                return updatedDto;
            }
        } catch (error) {
            handleError(error, () => true);
        }

        return null;
    };
}

export function updateStagedNote(dto: StagedNoteDto): AppThunk<Promise<StagedNoteDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentStagedNote, currentPatientIntake } = getState().census;
        const client = new StagedNotesClient(null, Axios);

        try {
            const response = await client.post(dto);

            if (response.result.succeeded) {
                // Ensure the note coming back is still the note that's open
                // Sometimes network requests are delayed and the note is updated before the response is received
                // causing the wrong data to appear on the form
                if (response.result.entity.patientIntakeId === currentPatientIntake?.id && response.result.entity.id === currentStagedNote?.id) {
                    dispatch(censusSlice.actions.setCurrentStagedNote(response.result.entity));
                }
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.entity;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function promoteStagedNote(stagedNoteId: number, visitId: number): AppThunk<Promise<NoteDto>> {
    return async (dispatch: AppDispatch) => {
        const client = new StagedNotesClient(null, Axios);

        try {
            const response = await client.promoteToNote(stagedNoteId, visitId);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.entity;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function deleteStagedNote(stagedNoteId: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const client = new StagedNotesClient(null, Axios);

        try {
            const response = await client.delete(stagedNoteId);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export const selectCurrentStagedNote = (state: RootState) => state.census.currentStagedNote;
export const selectCurrentStagedNotes = (state: RootState) => state.census.currentStagedNotes;
export const selectCurrentStagedNotesGrouped = (state: RootState) => state.census.currentStagedNotesGrouped;
