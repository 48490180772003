import { HubConnection } from '@microsoft/signalr';
import { ReturnToHospitalDto } from '@medone/medonehp-api-client';

import { store } from '../../../../../shared/store';
import { fetchReturnToHospitalCount } from '../../slice';

export const initReturnToHospitalHubConnection = (connection: HubConnection) => {
    connection.on('ReturnToHospitalAdded', (_dto: ReturnToHospitalDto) => {
        store.dispatch(fetchReturnToHospitalCount());
    });
};
