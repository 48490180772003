import { Badge, Space } from 'antd';
import { EnumSelectListDto, FacilityQuickNotesDto, Specialties } from '@medone/medonehp-api-client';

import { useAppSelector } from '../../../../../shared/hooks';
import { selectSpecialties } from '../../../../../shared/common/data/slice';

import primaryIcon from '../../../../../shared/images/specialty_primary.svg';
import pmrIcon from '../../../../../shared/images/specialty_pmr.svg';
import woundIcon from '../../../../../shared/images/specialty_wound.svg';
import { Fragment } from 'react';

type Props = {
    facilityQuickNotes: FacilityQuickNotesDto;
};

const FacilityQuickNoteBadges = ({ facilityQuickNotes }: Props) => {
    const allSpecialties = useAppSelector<EnumSelectListDto[]>(selectSpecialties);

    const getSpecialtyIcon = (specialty: Specialties) => {
        const dto = allSpecialties.find((x) => x.id === specialty);

        switch (specialty) {
            case Specialties.Primary:
                return <img src={primaryIcon} alt={dto?.name} className="specialty-icon" />;

            case Specialties.PMR:
                return <img src={pmrIcon} alt={dto?.name} className="specialty-icon" />;

            case Specialties.WoundCare:
                return <img src={woundIcon} alt={dto?.name} className="specialty-icon" />;

            default:
                return null;
        }
    };

    const renderSpecialtyCounts = (specialty: Specialties) => {
        if (facilityQuickNotes) {
            let count = 0;

            for (let d of facilityQuickNotes.quickNoteDates) {
                for (let qn of d.quickNotes) {
                    for (let ack of qn.acknowledgements) {
                        if (ack.isNotAcknowledged && ack.specialty === specialty) {
                            count++;
                        }
                    }
                }
            }

            if (count > 0) {
                return (
                    <Badge count={count} size="small">
                        {getSpecialtyIcon(specialty)}
                    </Badge>
                );
            }
        }

        return null;
    };

    return (
        <Space>
            {allSpecialties?.map((x) => (
                <Fragment key={x.id}>{renderSpecialtyCounts(x.id)}</Fragment>
            ))}
        </Space>
    );
};

export default FacilityQuickNoteBadges;
