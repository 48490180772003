import { Table } from 'antd';
import { PccAllergyDto } from '@medone/medonehp-api-client';

import { valueOrDefault } from '../../../../../../../shared/common/helpers';

const { Column } = Table;

type Props = {
    allergies: PccAllergyDto[];
};

const AllergiesTable = ({ allergies }: Props) => {
    return (
        <Table rowKey="id" pagination={false} dataSource={allergies}>
            <Column<PccAllergyDto> title="Description" dataIndex="description" key="description" />
            <Column<PccAllergyDto> title="Severity" dataIndex="severity" key="severity" render={(data) => valueOrDefault(data, '-')} />
            <Column<PccAllergyDto> title="Status" dataIndex="status" key="status" render={(data) => valueOrDefault(data, '-')} />
        </Table>
    );
};

export default AllergiesTable;
