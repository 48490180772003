import { useState } from 'react';
import { Button, Card, Modal, Table } from 'antd';
import { PccProgressNoteDetailAggregationDto, PccProgressNoteDetailDto, PccProgressNoteDto } from '@medone/medonehp-api-client';

import { useAppDispatch, useAppSelector } from '../../../../../../../shared/hooks';
import { fetchPccProgressNoteDetail, selectPccPatientNoteDetail } from '../../../slice.patients';
import { renderHtml } from '../../../../../../../shared/common/helpers/renderers';

const { Column } = Table;

type Props = {
    orgUuid: string;
    pccProgressNotes: PccProgressNoteDto[];
};

const PccProgressNotesTable = ({ orgUuid, pccProgressNotes }: Props) => {
    const dispatch = useAppDispatch();
    const progressNoteDetails = useAppSelector<PccProgressNoteDetailAggregationDto>(selectPccPatientNoteDetail);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const showProgressNoteDetails = async (record: PccProgressNoteDto) => {
        await dispatch(fetchPccProgressNoteDetail(orgUuid, record.pccProgressNoteId));

        setShowDetails(true);
    };

    const renderViewButton = (record) => {
        return (
            <Button className="btn-view-progress-note-details" size="small" type="primary" onClick={() => showProgressNoteDetails(record)}>
                View
            </Button>
        );
    };

    const renderProgressNoteColumn = (data, record) => {
        return (
            <>
                <Button type="link" className="lnk-view-progress-note-details" onClick={() => showProgressNoteDetails(record)}>
                    <u>{data}</u>
                </Button>

                <div className="progress-note-type">{data}</div>
            </>
        );
    };

    const renderLinkedNotes = (notes: PccProgressNoteDto[]) => {
        if (notes && notes.length > 0) {
            return (
                <Card title="Linked Notes" className="mt-2">
                    <Table
                        className="m-0"
                        rowKey="pccProgressNoteId"
                        pagination={false}
                        scroll={{ x: true }}
                        dataSource={notes}
                        expandable={{
                            indentSize: 10,
                            rowExpandable: (data) => true,
                            expandedRowRender: (data) => (
                                <Table rowKey="id" pagination={false} scroll={{ x: true }} dataSource={data.details}>
                                    <Column<PccProgressNoteDetailDto> title="Section" dataIndex="sectionName" key="sectionName" />
                                    <Column<PccProgressNoteDetailDto> title="Text" dataIndex="sectionText" key="sectionText" render={(html) => renderHtml(html)} />
                                </Table>
                            ),
                        }}
                    >
                        <Column<PccProgressNoteDto>
                            title="Effective Date"
                            dataIndex="effectiveDate"
                            key="effectiveDate"
                            render={(data) => (data ? data.format('MM/DD/YYYY') : null)}
                        />
                        <Column<PccProgressNoteDto>
                            title="Progress Note"
                            dataIndex="progressNoteType"
                            key="progressNoteType"
                            render={(data) => <div className="progress-note-type">{data}</div>}
                        />
                        <Column<PccProgressNoteDto> title="User" dataIndex="createdBy" key="createdBy" />
                    </Table>
                </Card>
            );
        }

        return null;
    };

    const renderModal = () => {
        if (progressNoteDetails) {
            return (
                <Modal title="Progress Note Details" open={showDetails} footer={null} onCancel={() => setShowDetails(false)} width="80%" zIndex={1005}>
                    <Table rowKey="id" pagination={false} scroll={{ x: true }} dataSource={progressNoteDetails.sections}>
                        <Column<PccProgressNoteDetailDto> title="Section" dataIndex="sectionName" key="sectionName" />
                        <Column<PccProgressNoteDetailDto> title="Text" dataIndex="sectionText" key="sectionText" render={(html) => renderHtml(html)} />
                    </Table>

                    {renderLinkedNotes(progressNoteDetails.followUpTos)}
                </Modal>
            );
        }

        return null;
    };

    return (
        <>
            <Table rowKey="progressNoteId" pagination={false} scroll={{ x: true }} dataSource={pccProgressNotes}>
                <Column<PccProgressNoteDto> title="Effective Date" dataIndex="effectiveDate" key="effectiveDate" render={(data) => (data ? data.format('MM/DD/YYYY') : null)} />
                <Column<PccProgressNoteDto>
                    title="Progress Note"
                    dataIndex="progressNoteType"
                    key="progressNoteType"
                    render={(data, record) => renderProgressNoteColumn(data, record)}
                />
                <Column<PccProgressNoteDto> title="User" dataIndex="createdBy" key="createdBy" />
                <Column<PccProgressNoteDto>
                    className="view-progress-note-details-column"
                    title=""
                    dataIndex="createdBy"
                    key="createdBy"
                    render={(data, record) => renderViewButton(record)}
                />
            </Table>

            {renderModal()}
        </>
    );
};

export default PccProgressNotesTable;
