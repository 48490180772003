import { useEffect, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import { EnumSelectListDto, FacilityDto, PatientIntakeDto, PatientIntakeSpecialtyDto, Specialties } from '@medone/medonehp-api-client';
import { toast } from 'react-toastify';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { filterOptionsStartsWith } from '../../../../../shared/common/helpers';
import { getEnumCustomSelectListOptions } from '../../../../../shared/common/components/CustomSelect';
import { selectSpecialties } from '../../../../../shared/common/data/slice';
import { createReferral } from '../slice.specialties';
import { selectActiveFacilities } from '../../admin/slice.facilities';

type Props = {
    patientIntake: PatientIntakeDto;
    onReferralCreatedCallback?: (dto: PatientIntakeSpecialtyDto) => void;
};

const Referrals = ({ patientIntake, onReferralCreatedCallback }: Props) => {
    const dispatch = useAppDispatch();
    const facilities = useAppSelector<FacilityDto[]>(selectActiveFacilities);
    const specialties = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isProcessing, setProcessing] = useState<boolean>(false);
    const [selectedSpecialties, setSelectedSpecialties] = useState<any[]>([]);
    const [form] = Form.useForm();

    const handleOpen = () => {
        setIsVisible(true);
    };

    const handleSubmit = async () => {
        setProcessing(true);

        const values = await form.validateFields();
        const specialtyFormatted = specialties.find(x => x.id === values.specialty)?.name;

        const patchedValues = {
            id: 0,
            patientIntakeId: patientIntake.id,
            specialty: values.specialty as Specialties,
            specialtyFormatted: specialtyFormatted,
            startDate: moment(),
            endDate: null,
            dischargedFromPrimary: false,
            isNew: false,
        } as PatientIntakeSpecialtyDto;

        const result = await dispatch(createReferral(patchedValues));

        if (result) {
            toast.success('Referral submitted successfully!');

            form.resetFields();

            setIsVisible(false);

            if (onReferralCreatedCallback) {
                onReferralCreatedCallback(patchedValues);
            }
        }

        setProcessing(false);
    };

    useEffect(() => {
        if (patientIntake != null) {
            const facility = facilities.find((x) => x.id === patientIntake.facilityId);
            if (facility != null) {
                const specialtiesOptions = facility.specialties.map((type) => specialties.find((x) => x.id === type));

                setSelectedSpecialties(getEnumCustomSelectListOptions(specialtiesOptions));
            }
        }
    }, [facilities, patientIntake, specialties]);

    return (
        <>
            <Button type="primary" onClick={handleOpen} disabled={patientIntake == null || !!patientIntake?.dischargeDate}>
                Referral
            </Button>

            <Modal
                title="Referral"
                open={isVisible}
                confirmLoading={isProcessing}
                onCancel={() => setIsVisible(false)}
                destroyOnClose
                width={600}
                footer={[
                    <Button key="Cancel" onClick={() => setIsVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={isProcessing} onClick={() => handleSubmit()}>
                        Save
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical" className="prep-visit-form">
                    <Form.Item label="Specialty" name="specialty">
                        <Select
                            showSearch
                            disabled={selectedSpecialties == null}
                            filterOption={filterOptionsStartsWith}
                            options={selectedSpecialties}
                            dropdownMatchSelectWidth={false}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Referrals;
