import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  FilesClient, FileResponse } from '@medone/medonehp-api-client';

import { Axios } from '../http';
import { initialState, FileState } from './models';
import { RootState, AppThunk, AppDispatch } from '../../store';

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setError: (state: FileState, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
    },
});

export function downloadFile(filePath: string): AppThunk<Promise<FileResponse>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new FilesClient(null, Axios);

        try {
            const response = await client.download(filePath);

            return response.result;
        } catch (error) {}

        return null;
    };
}

export const selectFilesError = (state: RootState) => state.files.error;

export default filesSlice.reducer;
