import React, { useState, SyntheticEvent, useRef } from 'react';
import { Alert, Card, Col, Collapse, Form, Row, Skeleton, Spin } from 'antd';
import { RouteBuilderGroupDto, RegionDto, RouteBuilderPostRequestDto, ScheduleProviderGroupDto, UserDto, RouteBuilderDto } from '@medone/medonehp-api-client';
import { toast } from 'react-toastify';
import moment from 'moment';

import { selectRouteBuilderGroups, selectLoading, selectLoadingDetails, fetchBadgeData } from '../slice';
import { postSchedule, selectSchedules } from '../slice.schedules';
import { customExpandIcon } from '../../../../../shared/common/helpers/collapse';
import { selectUsers } from '../slice.users';
import { selectRegions } from '../../admin/slice.regions';
import { useAppSelector, useAppDispatch } from '../../../../../shared/hooks';

import PatientTable from './patient-table';
import ProviderPanel from './provider-panel';
import ProvidersBanner from './providers-banner';
import VisitsFilters from './visits-filters';

type Props = {
    currentRegion: number;
    scheduleDate: moment.Moment;
    assignInProgress: boolean;
    setAssignInProgress: React.Dispatch<React.SetStateAction<boolean>>;
};

const lsLocationsFilteredKey = 'routebuilder-filteredlocations';
const lsVisitTypesFilteredKey = 'routebuilder-filteredvisittypes';

const getFilteredLocations = () => {
    const value = localStorage.getItem(lsLocationsFilteredKey);

    if (!value) {
        return [];
    }

    return (
        value
            .split(',')
            .map((x) => parseInt(x))
            .filter((x) => !isNaN(x)) ?? []
    );
};

const getFilteredVisitTypes = () => {
    const value = localStorage.getItem(lsVisitTypesFilteredKey);

    if (!value) {
        return [];
    }

    return (
        value
            .split(',')
            .map((x) => parseInt(x))
            .filter((x) => !isNaN(x)) ?? []
    );
};

const Results = ({ currentRegion, scheduleDate, assignInProgress, setAssignInProgress }: Props) => {
    const dispatch = useAppDispatch();
    const providersRef = useRef<any>();
    const loading = useAppSelector<boolean>(selectLoading);
    const detailsLoading = useAppSelector<boolean>(selectLoadingDetails);
    const groups = useAppSelector<RouteBuilderGroupDto[]>(selectRouteBuilderGroups);
    const schedules = useAppSelector<ScheduleProviderGroupDto[]>(selectSchedules);
    const providers = useAppSelector<UserDto[]>(selectUsers);
    const regions = useAppSelector<RegionDto[]>(selectRegions);
    const [selectedRows, setSelectedRows] = useState<RouteBuilderDto[]>([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedCensus, setSelectedCensus] = useState(null);
    const [filteredLocations, setFilteredLocations] = useState<number[]>(getFilteredLocations());
    const [filteredVisitTypes, setFilteredVisitTypes] = useState<number[]>(getFilteredVisitTypes());
    const [hidden, setHidden] = useState<boolean>(true);
    const [form] = Form.useForm();

    const message = !currentRegion ? 'Select a region to continue' : 'No results found';

    const handlePostSchedule = async (evt, providerId = null, selected = null) => {
        setAssignInProgress(true);

        if (providerId === selectedProvider) {
            evt.stopPropagation();
        }

        const region = regions.find((x) => x.id === currentRegion);

        if (!region) {
            toast.error('A region is required in order to assign schedules.');
        }

        const patchedValues = RouteBuilderPostRequestDto.fromJS({
            scheduleDate,
            providerId,
            regionId: currentRegion,
            items: selected || selectedRows,
        });

        const result = await dispatch(postSchedule(patchedValues));

        if (result) {
            if (providerId) {
                setSelectedProvider(providerId);
                setSelectedLocation(selectedCensus);

                toast.success(`The schedule for the selected patient(s) has been assigned.`);
            } else {
                toast.success(`The schedule for the selected patient(s) has been unassigned.`);
            }
        } else {
            toast.error(`The schedule for selected patients failed to assign.`);
        }

        setSelectedRows([]);
        setAssignInProgress(false);
    };

    const handleCollapseChange = async (key) => {
        setSelectedCensus(key);

        const facilityId = parseInt(key);
        const routeBuilderGroup = groups.find((x) => x.admittedToId === facilityId);

        await dispatch(fetchBadgeData(routeBuilderGroup));
    };

    const renderSchedules = () => {
        if (!schedules) {
            return null;
        }

        if (!providers.length) {
            return <Alert showIcon type="info" message="No providers currently assigned to this region." />;
        }

        return (
            <Collapse
                accordion
                expandIconPosition="end"
                activeKey={selectedProvider}
                expandIcon={customExpandIcon}
                onChange={(key) => {
                    setSelectedLocation(null);
                    setSelectedProvider(key);
                }}
            >
                {providers.map((provider) => {
                    const schedule = schedules.find((x) => x.providerId === provider.adSid);

                    return (
                        <ProviderPanel
                            key={provider.id}
                            handlePostSchedule={handlePostSchedule}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                            provider={provider}
                            schedule={schedule}
                            processing={assignInProgress}
                            selectedRows={selectedRows}
                        />
                    );
                })}
            </Collapse>
        );
    };

    const handleScroll = (evt: SyntheticEvent) => {
        let clientHeight = 0;

        if (providersRef.current != null) {
            clientHeight = providersRef.current.clientHeight;
        }

        const { scrollTop } = evt.target as HTMLElement;
        const calculatedUnhidden = clientHeight - 150;

        if (scrollTop >= calculatedUnhidden) {
            setHidden(false);
        }

        if (scrollTop === 0 && !hidden) {
            setHidden(true);
        }
    };

    return (
        <Row gutter={48} className="routebuilder-results-container" onScroll={handleScroll}>
            <Col span={24} className="pr-0">
                <Skeleton active loading={loading}>
                    {groups != null && groups.length > 0 ? (
                        <>
                            <ProvidersBanner
                                providers={providers}
                                schedules={schedules}
                                processing={assignInProgress}
                                selectedRows={selectedRows}
                                hidden={hidden}
                                handlePostSchedule={handlePostSchedule}
                            />

                            <Row gutter={[16, { xs: 16, sm: 16, md: 16, lg: 16, xxl: 0 }]} className="route-builder-container content">
                                <Col span={24} className="mb-2" ref={providersRef}>
                                    <Card type="inner" title="Providers">
                                        {renderSchedules()}
                                    </Card>
                                </Col>

                                <Col span={24}>
                                    <Card
                                        type="inner"
                                        title={
                                            <>
                                                Visits
                                                <VisitsFilters
                                                    setFilteredLocations={setFilteredLocations}
                                                    setFilteredVisitTypes={setFilteredVisitTypes}
                                                    lsLocationsFilteredKey={lsLocationsFilteredKey}
                                                    lsVisitTypesFilteredKey={lsVisitTypesFilteredKey}
                                                    filteredVisitTypes={filteredVisitTypes}
                                                    filteredLocations={filteredLocations}
                                                    groups={groups}
                                                />
                                            </>
                                        }
                                        extra={[detailsLoading && <Spin key="loading" size="small" tip="Loading visits & schedules..." />]}
                                    >
                                        <Form name="schedule-form" autoComplete="off" layout="vertical" form={form} initialValues={{}}>
                                            <Collapse
                                                accordion
                                                activeKey={selectedCensus}
                                                onChange={(key) => handleCollapseChange(key)}
                                                defaultActiveKey={groups[0].admittedToId}
                                                expandIconPosition="end"
                                                expandIcon={customExpandIcon}
                                            >
                                                {groups
                                                    .filter((x) => filteredLocations.length === 0 || filteredLocations.includes(x.admittedToId))
                                                    .map((group) => (
                                                        <PatientTable
                                                            key={group.admittedToId}
                                                            group={group}
                                                            setSelectedRows={setSelectedRows}
                                                            selectedRows={selectedRows}
                                                            filteredVisitTypes={filteredVisitTypes}
                                                        />
                                                    ))}
                                            </Collapse>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Alert type="info" showIcon message={message} />
                    )}
                </Skeleton>
            </Col>
        </Row>
    );
};

export default Results;
