import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Skeleton } from 'antd';

import { Role } from './RoleAuth';
import { selectPermissions, selectPermissionsLoading } from './slice';
import { useAppSelector } from '../../hooks';
import { isInRole } from '../helpers';

import NoAccess from '../components/NoAccess';

export interface AuthorizedRouteProps extends RouteProps {
    roles?: Role[];
    redirectTo?: string;
    restrictedRoles?: Role[];
}

export const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({ roles, redirectTo, restrictedRoles, ...rest }) => {
    const userRoles = useAppSelector<string[]>(selectPermissions);
    const permissionsLoading = useAppSelector<boolean>(selectPermissionsLoading);
    const rolesToCheck = [...roles, Role.SYSADMIN];

    if (permissionsLoading) {
        return <Skeleton loading={permissionsLoading} />;
    }

    if (restrictedRoles && isInRole(userRoles, restrictedRoles)) {
        return <NoAccess />;
    }

    if (isInRole(userRoles, rolesToCheck)) {
        return <Route {...rest} />;
    }

    if (redirectTo != null) {
        return <Redirect to={redirectTo} />;
    }

    return <NoAccess />;
};

export default AuthorizedRoute;
