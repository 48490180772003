import { PayloadAction } from '@reduxjs/toolkit';
import { HospiceCompaniesClient, HospiceCompanyDto } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { AdminState } from './models';
import { adminSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';

export const reducers = {
    setHospiceCompanies: (state: AdminState, action: PayloadAction<HospiceCompanyDto[]>) => {
        state.hospiceCompanies = action.payload;
        state.errorMessage = null;
    },

    setHospiceCompany: (state: AdminState, action: PayloadAction<HospiceCompanyDto>) => {
        state.currentHospiceCompany = action.payload;
        state.errorMessage = null;
    },

    syncHospiceCompany: (state: AdminState, action: PayloadAction<HospiceCompanyDto>) => {
        let newItems = [...state.hospiceCompanies];
        const index = state.hospiceCompanies.findIndex((x) => x.id === action.payload.id);

        if (index !== -1) {
            newItems = newItems.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload } as HospiceCompanyDto;
                }

                return item;
            });
        } else {
            newItems.push(action.payload);
        }

        state.hospiceCompanies = newItems;

        if (state.currentHospiceCompany != null && state.currentHospiceCompany.id === action.payload.id) {
            state.currentHospiceCompany = action.payload;
        }
    },
};

export function fetchHospiceCompanies(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new HospiceCompaniesClient(null, Axios);

        try {
            const response = await client.getAll();

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospiceCompanies(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchHospiceCompany(hospiceIntakeCompanyId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new HospiceCompaniesClient(null, Axios);

        try {
            const response = await client.getById(hospiceIntakeCompanyId);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospiceCompany(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }
    };
}

export function updateHospiceCompany(hospiceIntakeCompany: HospiceCompanyDto): AppThunk<Promise<number | null>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new HospiceCompaniesClient(null, Axios);

        try {
            const response = await client.post(hospiceIntakeCompany);

            if (response.result.succeeded) {
                dispatch(adminSlice.actions.setHospiceCompany(response.result.entity));
            } else {
                handleError(response, () => dispatch(adminSlice.actions.setError(null)));
            }

            return response.result.entity?.id;
        } catch (error) {
            handleError(error, () => dispatch(adminSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function syncHospiceCompany(dto: HospiceCompanyDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(adminSlice.actions.syncHospiceCompany(dto));
    };
}

export const selectHospiceCompanies = (state: RootState) => state.admin.hospiceCompanies;
export const selectHospiceCompany = (state: RootState) => state.admin.currentHospiceCompany;
