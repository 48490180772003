import { UsersClient } from '@medone/medonehp-api-client';
import { BedBoardUsersClient } from '@medone/medonehp-bedboard-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk, AppDispatch } from '../../store';
import { AccountInfo, InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';
import { initialState, AuthState } from './models';
import { loginRequest, silentRequest } from './authConfig';
import { handleError } from '../HandleErrors';
import { Axios } from '../http';
import { BuildInfo } from '../../../Config';
import { msalInstance } from '../../..';
import { isBedBoardIntakeForm } from '../helpers';

/**
 * Represents an authentication slice of global state.
 *
 * @typedef {Object} AuthSlice
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {Object} reducers - The reducers for handling authentication actions.
 * @property {Function} reducers.setError - Reducer function for setting the error message.
 * @property {Function} reducers.setPermissions - Reducer function for setting the permissions.
 * @property {Function} reducers.setPermissionsLoading - Reducer function for setting the permissions loading state.
 * @property {Function} reducers.updateAccount - Reducer function for updating the account information.
 * @property {Function} reducers.updateError - Reducer function for updating the error information.
 */
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setError: (state: AuthState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        setPermissions: (state: AuthState, action: PayloadAction<string[]>) => {
            state.permissionsLoading = false;
            state.permissions = action.payload;
        },

        setPermissionsLoading: (state: AuthState, action: PayloadAction<boolean>) => {
            state.permissionsLoading = action.payload;
        },

        updateAccount: (state: AuthState, action: PayloadAction<any>) => {
            state.account = action.payload;
            state.isAuthenticated = true;

            if (action.payload?.idTokenClaims != null) {
                state.idToken = action.payload.idTokenClaims;
            }
        },

        updateError: (state: AuthState, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.isAuthenticated = false;
        },
    },
});

export const { updateAccount, updateError } = authSlice.actions;

/**
 * Fetches user permissions from the server using the provided client and dispatches an action to update the store.
 *
 * @param {UsersClient | BedBoardUsersClient} client - The client used to make the API request.
 * @param {AppDispatch} dispatch - The dispatcher used to dispatch actions.
 *
 * @return {Promise} - A promise that resolves when the permissions are fetched and the store is updated.
 */
async function fetchPermissions(client: UsersClient | BedBoardUsersClient, dispatch: AppDispatch) {
    const response = await client.getPermissions();
    if (response.result.succeeded) {
        dispatch(authSlice.actions.setPermissions(response.result.entity));
    }
}

/**
 * Fetches user permissions.
 *
 * @return {AppThunk} - A thunk function that performs the fetch operation.
 */
export function fetchUserPermissions(): AppThunk {
    return async (dispatch: AppDispatch) => {
        try {
            let client: UsersClient | BedBoardUsersClient;
            if (BuildInfo.IsBedBoard) {
                client = new BedBoardUsersClient(null, Axios);
            } else {
                client = new UsersClient(null, Axios);
            }
            await fetchPermissions(client, dispatch);
        } catch (error) {
            handleError(error, () => dispatch(authSlice.actions.setError(error.toString())));
        }
    };
}

let forceRefresh = true;

/**
 * Retrieves a cached access token.
 *
 * @returns {AppThunk<Promise<string>>} - A thunk that resolves to a promise containing the access token.
 *
 * @throws {InteractionRequiredAuthError} - If silent call fails and user interaction is required.
 *
 * This method retrieves a cached access token using the MSAL library. It first checks if a token can be acquired silently.
 * If the silent acquisition is successful, the method checks if the token was retrieved from the cache. If not, it dispatches
 * a user permissions fetch action after a timeout of 750 milliseconds. Finally, the method returns the retrieved access token.
 *
 * If the silent acquisition fails and user interaction is required, the method falls back to acquiring the token through a
 * redirect. If the token request fails for any reason, it is assumed that the token has expired and a new login is requested.
 * In these cases, an empty string is returned.
 */
export function getCachedToken(): AppThunk<Promise<string>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { account } = getState().auth;

        // Don't try to get token during public intake form requests
        if (isBedBoardIntakeForm() && !account) {
            return Promise.resolve('');
        }

        const request = {
            ...silentRequest,
            account: account,
            forceRefresh: forceRefresh,
        } as SilentRequest;

        try {
            const silentResponse = await msalInstance.acquireTokenSilent(request);
            if (silentResponse != null) {
                if (!silentResponse.fromCache) {
                    setTimeout(() => {
                        dispatch(fetchUserPermissions());
                    }, 750);
                }

                forceRefresh = false;

                return silentResponse.accessToken;
            }
        } catch (silentError) {
            if (silentError instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                await msalInstance.acquireTokenRedirect(request);
            } else {
                // If the token request fails for any reason, assume it's expired and request a new login
                await msalInstance.loginRedirect({
                    ...loginRequest,
                    redirectStartPage: window.location.href,
                });
            }
        }

        return '';
    };
}

/**
 * Retrieves the authentication state from the RootState.
 *
 * @param {RootState} state - The RootState object containing the application state.
 * @returns {any} - The authentication state.
 */
export const selectAuth = (state: RootState): any => state.auth;
/**
 * Retrieves the selected account from the state.
 *
 * @param {RootState} state - The root state object.
 * @returns {AccountInfo} The selected account.
 */
export const selectAccount = (state: RootState): AccountInfo => state.auth.account;
/**
 * Selects the permissions from the application state.
 *
 * @param {RootState} state - The application state.
 * @returns {Array} - An array of permissions.
 */
export const selectPermissions = (state: RootState): Array<any> => state.auth.permissions;
/**
 * Retrieves the loading state of the permissions from the application's state.
 *
 * @param {RootState} state - The application's root state.
 * @returns {boolean} The loading state of the permissions.
 */
export const selectPermissionsLoading = (state: RootState): boolean => state.auth.permissionsLoading;

export default authSlice.reducer;
