import { Route, Switch } from 'react-router';
import { Alert } from 'antd';

import { useAppSelector } from '../../../../shared/hooks';
import { selectError } from './slice';
import { AnyProviderWithScribe, Role } from '../../../../shared/common/auth/RoleAuth';
import { PostAcutePaths } from '../index.routes';

import AuthorizedRoute from '../../../../shared/common/auth/AuthorizedRoute';
import Home from './components/home/index';
import Print from './components/notes/print';
import QuickNotePrint from '../quick-notes/print';
import PrintRtH from './components/patient-charts/notes-rth-qi-form-print';

export const CensusPaths = {
    Home: {
        Index: '/postacute/census',
    },

    Notes: {
        Print: '/postacute/census/notes/print',
    },

    QuickNotes: {
        Print: '/postacute/census/quicknotes/print',
    },

    ReturnToHospital: {
        Print: '/postacute/census/returntohospital/print',
    },
};

const Routes = () => {
    const errorMessage = useAppSelector<string>(selectError);

    return (
        <>
            <Switch>
                <Route exact path={`${CensusPaths.Notes.Print}/:ids`} component={Print} />

                <Route exact path={`${CensusPaths.QuickNotes.Print}/:ids/:facilityId?`} component={QuickNotePrint} />

                <AuthorizedRoute
                    exact
                    path={CensusPaths.Home.Index}
                    component={Home}
                    roles={[Role.POST_ACUTE_ADMIN, ...AnyProviderWithScribe, Role.CLINICAL_COORDINATOR]}
                    redirectTo={PostAcutePaths.ClinicalCoordinator.Index}
                />

                <Route exact path={`${CensusPaths.ReturnToHospital.Print}/:id/:patientId`} component={PrintRtH} />
            </Switch>

            {errorMessage != null && errorMessage !== '' && <Alert message={null} description={errorMessage} type="error" showIcon />}
        </>
    );
};

export default Routes;
