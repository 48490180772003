import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, PageHeader, Skeleton } from 'antd';

import { fetchPccCensus, selectPccLoading } from '../../slice';
import { CensusPaths } from '../../../census/index.routes';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';

import Results from './results';

type DetailsParams = { facilityId: string; orgUuid: string };

const Index = () => {
    const dispatch = useAppDispatch();
    const params = useParams<DetailsParams>();
    const loading = useAppSelector<boolean>(selectPccLoading);
    const { facilityId, orgUuid } = params;

    useEffect(() => {
        async function getCensus() {
            await dispatch(fetchPccCensus(parseInt(facilityId), orgUuid));
        }

        getCensus();
    }, [dispatch, facilityId, orgUuid]);

    return (
        <>
            <PageHeader
                title="PointClickCare Census"
                extra={[
                    <Link key="back-to-census" to={CensusPaths.Home.Index}>
                        <Button type="primary">Back to Census</Button>
                    </Link>,
                ]}
            />

            <Skeleton active loading={loading}>
                <Results />
            </Skeleton>
        </>
    );
};

export default Index;
