import { ClinicalCoordinatorPaths } from './../../clinical-coordinator/index.routes';
import { RouteBuilderPaths } from './../../route-builder/index.routes';
import { SchedulePaths } from './../../schedule/index.routes';
import { HubConnection } from '@microsoft/signalr';
import {
    UnsignedStatsDto,
    QuickNoteDto,
    RequiredVisitResultDto,
    VisitDto,
    MedicalSummaryLockStatusDto,
    MedicalSummaryDto,
    NoteDto,
    PatientIntakeDto,
    PatientDto,
    CensusDto,
    PatientProviderSupplierDto,
    StagedNoteDto,
    PatientIntakeSpecialtyGroupedDto,
    NoteTypes,
    AwvEligibilityStatus,
    CensusGroupDto,
    ScheduleDto,
} from '@medone/medonehp-api-client';
import moment from 'moment';
import { debounce } from 'lodash';

import { fetchUnsignedNotes, unsignedNotesSlice } from './../../unsigned-notes/slice';
import { store } from '../../../../../shared/store';
import { CensusField } from '../models';
import { fetchUserQuickNotes, postAcuteSlice, setTotalUnsignedNotes } from '../../slice';
import {
    refreshCensus,
    updateUnacknowledgedCount,
    setCensusField,
    setVisitDues,
    censusSlice,
    setPendingMedicalSummaryChanges,
    setCurrentStagedNote,
    fetchBadgeData as fetchCensusBadgeData,
    refreshCensusItem,
} from '../slice';
import { applyPatientIntakeLock, medicalSummaryFieldsLock, updateMedicalSummaryFields } from '../slice.patient-intakes';
import { getScheduleForProvider } from '../../schedule/slice.schedules';
import { CensusPaths } from '../index.routes';
import { PostAcutePaths } from '../../index.routes';
import { fetchRouteBuilder } from '../../route-builder/slice';
import { fetchAnnualWellnessVisits, fetchBadgeData as fetchClinicalCoordinatorBadgeData } from '../../clinical-coordinator/slice';
import { getLatestHgbA1CPatientLab, getLatestTshPatientLab } from '../slice.patient-labs';
import { getProvidersAndSuppliers } from '../slice.patients';
import { fetchSpecialties } from '../slice.specialties';
import { showNewVersionModal } from '../../../../../shared/common/helpers/app-helpers';
import { FacilityQuickNotesCountUpdatedDto, ProviderQuickNotesCountUpdatedDto } from '../../models';
import { SignalRDefaultDebounceTimeout, SignalRDefaultDebounceOptions } from '../../../../../Config';

const delay = 15000;
let pendingTimer = null;
let unlockTimer = null;

const refreshInterval = 1000 * 60;

let refreshSetInterval = null;

export const updateCensusGroupToRefresh = async (data: CensusDto | VisitDto | PatientIntakeDto | PatientIntakeSpecialtyGroupedDto | ScheduleDto) => {
    const { censusGroupsToRefresh, census } = store.getState().census;

    let admittedTo = '';
    let admittedToId = 0;

    if (data instanceof CensusDto) {
        admittedTo = data.admittedTo;
        admittedToId = data.admittedToId;
    }

    if (data instanceof VisitDto) {
        admittedToId = data.facilityId;
    }

    if (data instanceof PatientIntakeDto) {
        admittedToId = data.facilityId;
    }

    if (data instanceof PatientIntakeSpecialtyGroupedDto || data instanceof ScheduleDto) {
        if (data.patientIntakeId != null) {
            census.forEach((censusItem) => {
                if (admittedTo === '' || admittedToId === 0) {
                    const patientIntake = censusItem.items.find((x) => x.patientIntakeId === data.patientIntakeId);
                    if (patientIntake != null) {
                        admittedTo = patientIntake.admittedTo;
                        admittedToId = patientIntake.admittedToId;
                    }
                }
            });
        }
    }

    if (admittedToId > 0) {
        if (admittedTo === '') {
            const { facilities } = store.getState().admin;

            if (facilities != null) {
                const facility = facilities.find((x) => x.id === admittedToId);
                if (facility != null) {
                    admittedTo = facility.name;
                }
            }
        }

        const existingCensusGroup = (censusGroupsToRefresh ?? []).find((x) => x.admittedToId === admittedToId);
        if (existingCensusGroup != null) {
            const censusGroup = CensusGroupDto.fromJS(existingCensusGroup);

            if (data instanceof VisitDto) {
                censusGroup.patientIntakeIds.push(data.patientIntakeId);
            }

            store.dispatch(censusSlice.actions.storeCensusGroup(censusGroup));
        } else {
            const censusGroup = CensusGroupDto.fromJS({ admittedTo, admittedToId, patientIntakeIds: [] });

            if (data instanceof CensusDto) {
                censusGroup.patientIntakeIds.push(data.patientIntakeId);
            }

            if (data instanceof VisitDto) {
                censusGroup.patientIntakeIds.push(data.patientIntakeId);
            }

            if (data instanceof PatientIntakeDto) {
                censusGroup.patientIntakeIds.push(data.id);
            }

            if (data instanceof PatientIntakeSpecialtyGroupedDto) {
                censusGroup.patientIntakeIds.push(data.patientIntakeId);
            }

            store.dispatch(censusSlice.actions.storeCensusGroup(censusGroup));
        }
    }
};

export const clearRefreshInterval = () => {
    if (refreshSetInterval != null) {
        clearInterval(refreshSetInterval);
    }
};

export const initRefreshInterval = () => {
    clearRefreshInterval();

    refreshSetInterval = setInterval(async () => {
        // Only reload if the user is currently on the census page
        if (window.location.pathname === CensusPaths.Home.Index) {
            await store.dispatch(refreshCensus(null, true));
        }

        // Only reload if the user is currently on the unsigned notes page
        if (window.location.pathname === PostAcutePaths.UnsignedNotes.Index) {
            const { unsignedNotesTab } = store.getState().unsignedNotes;

            await store.dispatch(fetchUnsignedNotes(unsignedNotesTab));
        }
    }, refreshInterval);
};

export const initCensusHubConnection = (connection: HubConnection) => {
    connection.on(
        'PortalReleaseSuccess',
        debounce(
            () => {
                showNewVersionModal();
            },
            SignalRDefaultDebounceTimeout,
            SignalRDefaultDebounceOptions
        )
    );

    connection.on('PatientIntakeAdded', async (dto: PatientIntakeDto) => {
        const data = PatientIntakeDto.fromJS(dto);

        await updateCensusGroupToRefresh(data);

        if (window.location.pathname === ClinicalCoordinatorPaths.AnnualWellnessVisit && data.awvEligibilityDate == null) {
            await store.dispatch(fetchAnnualWellnessVisits());
        }
    });

    connection.on('PatientAdded', async (dto: PatientDto) => {
        const data = PatientDto.fromJS(dto);
        const { patient, currentCensus } = store.getState().census;

        // If the current patient is the incoming one, update it with the latest information
        if (data.id === patient?.id) {
            store.dispatch(censusSlice.actions.setPatient(data));
        }

        if (window.location.pathname === CensusPaths.Home.Index) {
            if (data.id === currentCensus?.patientId) {
                const updatedCensus = {
                    ...currentCensus,
                    patientTypeIds: JSON.stringify(data.patientTypeIds ?? []),
                    codeStatus: data.codeStatus,
                } as CensusDto;

                store.dispatch(censusSlice.actions.setCurrentCensus(updatedCensus));
            }
        }

        store.dispatch(censusSlice.actions.updateSearchResultsWithPatient(data));
    });

    connection.on('PatientIntakeUpdated', async (dto: PatientIntakeDto) => {
        const data = PatientIntakeDto.fromJS(dto);
        const { currentPatientIntake } = store.getState().census;

        // If the current intake is the incoming one, update it with the latest information
        if (data.id === currentPatientIntake?.id) {
            store.dispatch(censusSlice.actions.setPatientIntake(data));

            // Ensure that if the incoming hospice value has changed, we need to re-fetch the census to allow the tab to show on their chart
            const refreshCensusDueToHospiceChange = currentPatientIntake.isHospice !== data.isHospice;
            if (refreshCensusDueToHospiceChange) {
                store.dispatch(refreshCensusItem(CensusDto.fromJS({ patientIntakeId: data.id })));
            }
        }

        store.dispatch(censusSlice.actions.setPatientIntakeRoomNumberInCensus(data));
        store.dispatch(censusSlice.actions.updateSearchResultsWithPatientIntake(data));
    });

    connection.on('CensusAdded', async (dto: CensusDto) => {
        await updateCensusGroupToRefresh(dto);
    });

    connection.on('PatientIntakeDischarged', async (dto: CensusDto) => {
        await updateCensusGroupToRefresh(dto);
    });

    // Get's dispatched from QuickNoteFacilitiesCountUpdaterTimer
    connection.on('FacilityQuickNotesCountUpdated', async (dto: FacilityQuickNotesCountUpdatedDto[]) => {
        store.dispatch(updateUnacknowledgedCount(dto));

        if (window.location.pathname === PostAcutePaths.QuickNotes.Index) {
            await store.dispatch(fetchUserQuickNotes());
        }
    });

    // Get's dispatched from QuickNoteUserCountUpdaterTimer
    connection.on('ProviderQuickNotesCountUpdated', async (dto: ProviderQuickNotesCountUpdatedDto) => {
        const { account } = store.getState().auth;

        if (account != null && dto != null && account.localAccountId === dto.adSid) {
            if (dto.drafts != null) {
                store.dispatch(postAcuteSlice.actions.setTotalDraftQuickNotes(dto.drafts));
            }

            if (dto.nonDrafts != null) {
                store.dispatch(postAcuteSlice.actions.setTotalUnacknowledgedQuickNotes(dto.nonDrafts));
            }
        }
    });

    connection.on('NoteAdded', async (dto: NoteDto) => {
        const { currentPatientIntake } = store.getState().census;

        // When a note is signed/updated, update the hopsice information as well
        if (currentPatientIntake != null && currentPatientIntake.id === dto.patientIntakeId) {
            const updatedIntake = { ...currentPatientIntake, isHospice: dto.isHospice } as PatientIntakeDto;

            store.dispatch(censusSlice.actions.setPatientIntake(updatedIntake));

            await store.dispatch(fetchSpecialties(dto.patientIntakeId));
        }
    });

    connection.on('QuickNoteAdded', (dto: QuickNoteDto) => {
        const data = QuickNoteDto.fromJS(dto);
        const showAdded = data.reminderDate.isSameOrBefore(moment());

        // Only show the badge if the date is valid
        if (showAdded) {
            const censusField = {
                patientId: dto.patientId,
                facilityId: dto.facilityId,
                field: 'hasUnacknowledgedQuickNote',
                fieldValue: dto.isNotAcknowledged,
            } as CensusField;

            store.dispatch(setCensusField(censusField));
        }
    });

    connection.on('SendUnsignedStats', (dto: UnsignedStatsDto) => {
        const state = store.getState();
        const { totalUnsignedNotes } = state.postacute;

        // This this update is for the current user, then send it
        if (totalUnsignedNotes != null && totalUnsignedNotes.providerId === dto.providerId) {
            store.dispatch(setTotalUnsignedNotes(dto));
        }
    });

    connection.on('VisitDueUpdated', async (dto: RequiredVisitResultDto[]) => {
        const data = dto.map((x) => RequiredVisitResultDto.fromJS(x));

        store.dispatch(setVisitDues(data));
    });

    connection.on('VisitUpdated', async (dto: VisitDto) => {
        const { localAccountId } = store.getState().auth?.account;
        const data = VisitDto.fromJS(dto);

        if (window.location.pathname === CensusPaths.Home.Index) {
            const { census } = store.getState().census;
            const censusGroup = census?.find((x) => x.admittedToId === dto.facilityId);

            store.dispatch(censusSlice.actions.setVisit(data));

            if (data.noteType === NoteTypes.AnnualWellnessVisit && censusGroup?.items != null) {
                const censusItem = censusGroup.items.find((x) => x.patientIntakeId === data.patientIntakeId);
                if (censusItem != null && censusItem.awvEligibilityStatus !== AwvEligibilityStatus.NotEligible) {
                    await updateCensusGroupToRefresh(data);
                } else {
                    await store.dispatch(fetchCensusBadgeData(censusGroup));
                }
            } else {
                await updateCensusGroupToRefresh(data);

                await store.dispatch(fetchCensusBadgeData(censusGroup));
            }
        }

        if (window.location.pathname === SchedulePaths.Home.Index && localAccountId) {
            await store.dispatch(getScheduleForProvider(localAccountId));
        }

        if (window.location.pathname === PostAcutePaths.UnsignedNotes.Index && localAccountId) {
            const { unsignedNotesTab } = store.getState().unsignedNotes;

            store.dispatch(unsignedNotesSlice.actions.setUnsignedNoteVisit(data));

            await store.dispatch(fetchUnsignedNotes(unsignedNotesTab));
        }

        if (window.location.pathname === RouteBuilderPaths.Home.Index && localAccountId) {
            const { filters } = store.getState().routeBuilder;

            await store.dispatch(fetchRouteBuilder(filters, true));
        }

        if (window.location.pathname.startsWith(ClinicalCoordinatorPaths.MedicalSummary) && localAccountId) {
            const { medicalSummaryIntake } = store.getState().clinicalCoordinator;

            if (medicalSummaryIntake != null) {
                await store.dispatch(fetchClinicalCoordinatorBadgeData(medicalSummaryIntake));
            }
        }

        await store.dispatch(fetchSpecialties(dto.patientIntakeId));
    });

    // Called when patient chart is opening if the room number is updated via pcc sync code
    connection.on('PatientIntakeSyncedWithPccAdt', async (dto: PatientIntakeDto) => {
        const data = PatientIntakeDto.fromJS(dto);
        const { currentCensus } = store.getState().census;

        // If the current intake is the incoming one, update it with the latest information
        if (data.id === currentCensus?.patientIntakeId) {
            store.dispatch(censusSlice.actions.setPatientIntake(data));
        }

        store.dispatch(censusSlice.actions.setPatientIntakeRoomNumberInCensus(data));
        store.dispatch(censusSlice.actions.updateSearchResultsWithPatientIntake(data));
    });

    connection.on('MedicalSummaryFieldsUpdated', (dto: MedicalSummaryDto) => {
        const { currentPatientIntake } = store.getState().census;
        const { account } = store.getState().auth;
        const data = MedicalSummaryDto.fromJS(dto);

        // NOTE: The medical summary queue handles it's own logic for this area

        updateAndSetPendingChanges(data);

        if (data.patientIntakeId === currentPatientIntake?.id && data.lastEditedByUserId === account.localAccountId) {
            clearTimeout(unlockTimer); // Prevent multiple unlock requests

            // We want to make sure this happens at the same time the setPendingMedicalSummaryChanges is called for the other user
            // so the fields unlock at the same time the dialog shows up for pending changes
            unlockTimer = setTimeout(() => {
                store.dispatch(medicalSummaryFieldsLock(data, false));
            }, delay);
        }
    });

    connection.on('MedicalSummaryCompleted', (dto: MedicalSummaryDto) => {
        const data = MedicalSummaryDto.fromJS(dto);

        updateAndSetPendingChanges(data);
    });

    connection.on('MedicalSummaryLockStatus', async (dto: MedicalSummaryLockStatusDto) => {
        const data = MedicalSummaryLockStatusDto.fromJS(dto);

        await store.dispatch(applyPatientIntakeLock(data));
    });

    connection.on(
        'PatientLabAdded',
        debounce(
            async () => {
                await updateLatestHgbA1cLabForNotes();
                await updateLatestTshForNotes();
            },
            SignalRDefaultDebounceTimeout,
            SignalRDefaultDebounceOptions
        )
    );

    connection.on(
        'PatientLabDeleted',
        debounce(
            async () => {
                await updateLatestHgbA1cLabForNotes();
                await updateLatestTshForNotes();
            },
            SignalRDefaultDebounceTimeout,
            SignalRDefaultDebounceOptions
        )
    );

    connection.on('PatientProviderSupplierAdded', async (dto: PatientProviderSupplierDto) => {
        const { currentPatientIntake } = store.getState().census;
        const data = PatientProviderSupplierDto.fromJS(dto);

        if (data.patientId === currentPatientIntake?.patientId) {
            await store.dispatch(getProvidersAndSuppliers(data.patientId));
        }
    });

    connection.on(
        'PatientProviderSupplierDeleted',
        debounce(
            async () => {
                const { providersAndSuppliers, currentPatientIntake } = store.getState().census;

                if (
                    providersAndSuppliers != null &&
                    currentPatientIntake != null &&
                    // Only reload if the current patient is the one we're considering
                    providersAndSuppliers.findIndex((x) => x.patientId === currentPatientIntake.patientId) !== -1
                ) {
                    await store.dispatch(getProvidersAndSuppliers(currentPatientIntake.patientId));
                }
            },
            SignalRDefaultDebounceTimeout,
            SignalRDefaultDebounceOptions
        )
    );

    connection.on('StagedNoteAdded', async (dto: StagedNoteDto) => {
        const data = StagedNoteDto.fromJS(dto);
        const { currentStagedNote } = store.getState().census;

        if (data.id === currentStagedNote?.id) {
            store.dispatch(setCurrentStagedNote(data));
        }
    });

    connection.on('PatientIntakeSpecialtiesUpdated', async (dto: PatientIntakeSpecialtyGroupedDto) => {
        if (dto && dto.patientIntakeId > 0) {
            const data = PatientIntakeSpecialtyGroupedDto.fromJS(dto);

            store.dispatch(censusSlice.actions.setSpecialties([data]));

            await updateCensusGroupToRefresh(data);
        }
    });

    initRefreshInterval();
};

const updateLatestHgbA1cLabForNotes = debounce(async () => {
    const { latestHgbA1cLab } = store.getState().census;
    if (latestHgbA1cLab) {
        store.dispatch(getLatestHgbA1CPatientLab(latestHgbA1cLab.patientId));
    }
}, 250);

const updateLatestTshForNotes = debounce(async () => {
    const { latestTshLab } = store.getState().census;
    if (latestTshLab) {
        store.dispatch(getLatestTshPatientLab(latestTshLab.patientId));
    }
}, 250);

const updateAndSetPendingChanges = (data: MedicalSummaryDto) => {
    const { currentPatientIntake } = store.getState().census;
    const { account } = store.getState().auth;

    if (data.patientIntakeId === currentPatientIntake?.id) {
        // This controls updating the medical summary fields on the patient chart and keeping that in sync during lock/unlock
        // Which allows us to display the diff modal to accept changes
        store.dispatch(updateMedicalSummaryFields(data));

        if (data.lastEditedByUserId !== account.localAccountId) {
            clearTimeout(pendingTimer);

            // See note below in else
            pendingTimer = setTimeout(async () => {
                store.dispatch(setPendingMedicalSummaryChanges(data));
            }, delay); // Delay the dialog for MS changes by 30 seconds
        }
    }
};
