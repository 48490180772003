import { Table, Tag } from 'antd';
import { CensusDto, FacilityScheduleDto, ScheduleDto } from '@medone/medonehp-api-client';
import { AccountInfo } from '@azure/msal-browser';

import { formatDateTime, isMobileView, isTabletView } from '../../../../../shared/common/helpers';
import { useAppSelector } from '../../../../../shared/hooks';
import { scheduleBadgeSelectors } from '../slice.schedules';
import { selectAccount } from '../../../../../shared/common/auth/slice';

import VisitType from '../../census/components/badges/visit-type';
import ActionButtons from '../../census/components/action-buttons';
import Badges from '../../components/badges';

const { Column } = Table;

type Props = {
    facilitySchedule: FacilityScheduleDto;
    isPrint?: boolean;
};

const ScheduleTable = ({ facilitySchedule, isPrint }: Props) => {
    const auth = useAppSelector<AccountInfo>(selectAccount);
    const badges = useAppSelector((state) => scheduleBadgeSelectors.selectAll(state.schedule.badges));

    const expandableOptions = isPrint
        ? {
              defaultExpandAllRows: true,
              expandedRowRender: () => {
                  return <div className="print-notes"></div>;
              },
          }
        : {};

    return (
        <Table
            size="small"
            rowKey="id"
            pagination={false}
            dataSource={facilitySchedule.schedules}
            title={() => (
                <h3>
                    {facilitySchedule.facilityName} <span>{facilitySchedule && !isPrint && <Tag color="#6C8095">{facilitySchedule.schedules.length} Result(s)</Tag>}</span>
                </h3>
            )}
            bordered
            className={`schedule-table facility-pagebreak ${isPrint ? 'print' : ''}`}
            onRow={(record) => {
                const visitIndex = record?.patientIntakeVisits?.visits?.findIndex((x) => x.providerId === auth.localAccountId);

                return {
                    // If the current provider has a visit selected, hide them from the table
                    hidden: visitIndex !== -1,
                };
            }}
            expandable={expandableOptions}
        >
            <Column<ScheduleDto> title="Room #" dataIndex="census" key="roomNumber" render={(data) => data.roomNumber} className="text-nowrap" />

            <Column<ScheduleDto>
                title="Patient Name"
                dataIndex="census"
                key="patientName"
                render={(data: CensusDto) => {
                    const badge = badges.find((x) => x.patientIntakeId === data.patientIntakeId);

                    return (
                        <>
                            <div>{data.name}</div>

                            <Badges censusBadge={badge} patientTypeIds={badge?.patientTypeIds} canReturnFromED={data.canReturnFromED} />
                        </>
                    );
                }}
                className="text-nowrap"
            />

            {!isPrint ? (
                <Column<ScheduleDto> title="DOB (Age)" dataIndex="census" key="dateOfBirth" render={(data) => formatDateTime(data.dateOfBirth, '', 'L', data.age)} />
            ) : (
                <Column<ScheduleDto> title="Visit Type" dataIndex="patientIntakeId" className="text-nowrap" render={(data) => <VisitType patientIntakeId={data} />} />
            )}

            {!isPrint && (
                <Column<ScheduleDto>
                    title=""
                    key="actions"
                    render={(data: ScheduleDto) => (
                        <ActionButtons census={data.census} patientIntakeVisits={data.patientIntakeVisits} isMobile={isMobileView() || isTabletView()} />
                    )}
                />
            )}
        </Table>
    );
};

export default ScheduleTable;
